import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import React from "react";
import { Link as CoreLink, Redirect, useParams } from "react-router-dom";
import { UserInfoContext } from "../../contexts/UserInfoContext";
import amplitudeLogger from "../../utils/amplitude";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { postRequest } from "../../utils/httpHelpers";
import { authPageAvatar, formSubmitButton } from "../../utils/styleHelpers";
import { ReczeeCheckBox, ReczeeInputField } from "../commons/ReczeeInputField";
import { default as TalentHubRegister } from "../talenthub/auth/Register";
import { loginSuccessPostSteps } from "../../utils/authHelpers";

const useStyles = makeStyles((theme) => ({
  avatar: authPageAvatar(theme),
  submit: formSubmitButton(theme),
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  authHeader: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  userTabs: {
    width: "100px",
  },
  tabsContainer: {
    width: "fit-content",
    minHeight: "40px",
    "& .Mui-selected": {
      background: "linear-gradient(336deg, #28c880 1.15%, #1774e4 80%)",
      color: "white",
    },
    "& > .PrivateTabIndicator-root": {
      height: "0px !important",
    },

    border: "1px solid #e0e5ea",
    borderRadius: "11px",
  },
  singleTab: {
    color: "black",
    fontWeight: "bolder",
    borderRadius: "10px",
    minHeight: "40px",
  },
  containerGrid: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  registerInputField: {
    marginTop: theme.spacing(2),
  },
  termsAcceptLabel: {
    fontSize: "12px",
  },
  extraLinksContainer: {
    marginTop: theme.spacing(1.5),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const isValidPhoneNumber = (phoneNumber) => {
  if (phoneNumber.length < 10) return false;
  let digitCount = 0;
  for (var char in phoneNumber) {
    if (char >= "0" && char <= "9") {
      digitCount++;
    }
  }

  return digitCount >= 10; // Handles extension as well. Quite loose but we will change later, if at all.
};

export default function Register(props) {
  const classes = useStyles();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formErrors, setFormErrors] = React.useState({});
  const [userInfo, setUserInfo] = React.useContext(UserInfoContext);
  const [value, setValue] = React.useState(
    props && props.talenthubCandidate ? 1 : 0
  );
  const [privacyPoliceRead, setPrivacyPoliceRead] = React.useState(false);
  const queryParams = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    document.title = "Create your TalentHub profile | Reczee";
  };

  document.title =
    props && props.talenthubCandidate
      ? "Create your TalentHub profile | Reczee"
      : "Create company account | Reczee";

  const handleSubmit = (e) => {
    e.preventDefault();
    let phoneNumber = e.target.elements.phone_number.value;
    if (
      !e.target.elements.email.value ||
      !phoneNumber ||
      !e.target.elements.password.value ||
      !e.target.elements.company_name.value
    ) {
      enqueueSnackbar(
        "Please fill all the fields!",
        reczeeAlertParams("error")
      );
      return;
    }

    if (!isValidPhoneNumber(phoneNumber)) {
      enqueueSnackbar(
        "Please enter a valid phone number!",
        reczeeAlertParams("error")
      );
      return;
    }

    if (!privacyPoliceRead) {
      enqueueSnackbar(
        "Please accept the privacy policy!",
        reczeeAlertParams("error")
      );
      return;
    }
    setIsFormSubmitted(true);
    postRequest(
      "company",
      {},
      {
        user: {
          email: e.target.elements.email.value,
          password: e.target.elements.password.value,
          phone_number: e.target.elements.phone_number.value,
        },
        company: {
          name: e.target.elements.company_name.value,
          // "subdomain": e.target.elements.company_subdomain.value,
          // "email_domain": "bs.com"
        },
        screeno_only: props && props.is_screeno,
      },
      (data) => {
        setIsFormSubmitted(false);
        loginSuccessPostSteps(
          {
            userId: data.user.id,
            token: data.token,
            email: data.user.email,
            roles: data.user.roles,
            name: data.user.name,
            emailVerified: data.user.email_verified,
            companyApproved: data.company.approved,
          },
          setUserInfo,
          props.setReloadApp
        );

        amplitudeLogger.startSession(data.user.id, data.company.id);
        amplitudeLogger.logEvent("REGISTRATION_SUCCESS", {
          email_verified: data.user.email_verified,
          companyApproved: data.company.approved,
          company: e.target.elements.company_name.value,
        });
        enqueueSnackbar(
          "Successfully logged in!",
          reczeeAlertParams("success")
        );
      },
      (errorMessages, statusCode) => {
        console.log(errorMessages);
        amplitudeLogger.logEvent("REGISTRATION_FAILED", {
          status_code: statusCode,
          error_messages: errorMessages,
          company: e.target.elements.company_name.value,
        });
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          console.log("setting formerrors", errorMessages);
          setFormErrors(errorMessages);
        }
        setIsFormSubmitted(false);
      }
    );
  };

  const handlePrivacyPolicyChange = (e) => {
    setPrivacyPoliceRead(e.target.checked);
  };

  if (userInfo && !!userInfo.token) {
    if (queryParams.returnUrl) {
      return <Redirect push to={queryParams.returnUrl} />;
    } else {
      if (props && props.is_screeno) {
        return <Redirect push to="/screeno" />;
      } else {
        // @Todo(Raj): Redirect this to somewhere in the middle
        // We need to make the experience for email verification and company verification
        return <Redirect push to="/dashboard" />;
      }
    }
  }

  return (
    <>
      <Typography component="h1" variant="h4" className={classes.authHeader}>
        Register
      </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          variant="standard"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabsContainer}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <Tab label="For companies" className={classes.singleTab} />
          <Tab label="For candidates" className={classes.singleTab} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.containerGrid}
        >
          <Grid item xs={10}>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <ReczeeInputField
                id="company-name"
                name="company_name"
                helperText={formErrors.name}
                label="Company Name"
                autoFocus={true}
                fullWidth={true}
                errorField={!!formErrors.name}
                className={classes.registerInputField}
              />
              {/* <ReczeeInputField
            id="company-subdomain"
            name="company_subdomain"
            label="Company Subdomain"
            helperText={formErrors.subdomain ? formErrors.subdomain[0] : undefined}
            fullWidth={true}
            errorField={!!formErrors.subdomain}
            className={classes.registerInputField}
            endAdornment={
              <InputAdornment position="end">.reczee.com</InputAdornment>
            }
          /> */}
              <ReczeeInputField
                id="email"
                name="email"
                label="Work email"
                helperText={formErrors.email}
                errorField={!!formErrors.email}
                fullWidth={true}
                autoComplete="email"
                className={classes.registerInputField}
              />
              <ReczeeInputField
                id="phone_number"
                name="phone_number"
                helperText={formErrors.phone_number}
                label="Phone number"
                fullWidth={true}
                errorField={!!formErrors.phone_number}
                className={classes.registerInputField}
              />
              <ReczeeInputField
                id="password"
                name="password"
                label="Password"
                helperText={formErrors.password}
                errorField={!!formErrors.password}
                fullWidth={true}
                type="password"
                className={classes.registerInputField}
                autoComplete="current-password"
              />
              <ReczeeCheckBox
                checked={privacyPoliceRead}
                onChange={handlePrivacyPolicyChange}
                name="privacy_policy"
                label={
                  <span className={classes.termsAcceptLabel}>
                    I have read and accept the{" "}
                    <Link
                      variant="body2"
                      className={classes.termsAcceptLabel}
                      target="_blank"
                      href="https://www.reczee.com/privacy-policy"
                    >
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      variant="body2"
                      target="_blank"
                      className={classes.termsAcceptLabel}
                      href="https://www.reczee.com/terms-and-conditions"
                    >
                      Terms and conditions
                    </Link>
                  </span>
                }
                className={classes.registerInputField}
              />
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Button
                  disabled={isFormSubmitted}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size={"large"}
                >
                  {isFormSubmitted ? "Loading..." : "Register"}
                </Button>
              </Grid>
              <Grid container className={classes.extraLinksContainer}>
                <Grid item xs>
                  <Link to="/login" component={CoreLink}>
                    Already have an account? Login
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.containerGrid}>
          <TalentHubRegister />
        </div>
      </TabPanel>
    </>
  );
}
