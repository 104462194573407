import { makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { Link } from 'react-router-dom';
import amplitudeLogger from '../../../utils/amplitude';
import { SkillBadge } from '../skills/SkillBadge';

const useStyles = makeStyles((theme) => ({
  disabledTickIcon: {
    color: '#ced0d7',
    marginRight: theme.spacing(1)
  },
  enabledTickIcon: {
    color: 'green',
    marginRight: theme.spacing(1)
  },
  requirementListItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '2px',
    fontSize: '14px'
  },
  noneAnchor: {
    textDecoration: 'none',
    color: '#454343'
  },
}));

export function RequirementListContainer(props) {
  const classes = useStyles();
  return <div >
    {props.requirementList.map((requirement, index) => {
      return <div key={index} className={classes.requirementListItem}>
        {requirement.type === "experience" ?
          <>
            <CheckIcon fontSize="small" className={requirement.enabled ? classes.enabledTickIcon : classes.disabledTickIcon} />
            <Link
              to={`/talenthub/dashboard`}
              className={classes.noneAnchor}
              onClick={() => {
                amplitudeLogger.logEvent("REQUIREMENT_ITEM_CLICK", { from: props.page, type: requirement.type, enabled: requirement.enabled });
              }}
            >
              {requirement.data}
            </Link>
          </>
          :
          (requirement.type === "skill" ?
            <>
              <CheckIcon fontSize="small" className={requirement.enabled ? classes.enabledTickIcon : classes.disabledTickIcon} />
              <Link
                to={requirement.data.proficiency === 0 ? "/talenthub/dashboard" : (requirement.data.proficiency === 1 ? `/talenthub/assessments/${requirement.data.slug}/get-started` : `/talenthub/certifications/${requirement.data.slug}/get-started`)}
                className={classes.noneAnchor}
                onClick={() => {
                  amplitudeLogger.logEvent("REQUIREMENT_ITEM_CLICK", { from: props.page, skill: requirement.data.name, proficiency: requirement.data.proficiency, type: requirement.type, enabled: requirement.enabled });
                }}
              >
                <SkillBadge hoverable={true} name={requirement.data.name} proficiency={requirement.data.proficiency} />
              </Link>
            </>
            : "")
        }
      </div>;
    })}
  </div>;
}