import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

// "#3c6e71" "#05668d"
// "#9E0059" "#427aa1"
// "#390099" "#0fa3b1"
// "#FF5400" "#21295c"
// "#FF0054" "#679436"
// "#FFBD00" "#a5be00"

const mapping = {
  "Open Source Enthusiast": {
    label: "Open Source Enthusiast",
    backgroundColor: "#0a9396",
    color: "white",
  },
  "No tags": {
    label: "No tags",
    backgroundColor: "#efefef",
    color: "#7b7b7b",
  },
  "Top Institute": {
    label: "Top Institute",
    backgroundColor: "#1e8cc9",
    color: "white",
  },
  "Top Competitive Programmer": {
    label: "Top Competitive Programmer",
    backgroundColor: "#7678ed",
    color: "white",
  },
  "Hackathon Winner": {
    label: "Hackathon Winner",
    backgroundColor: "#ffb703",
    color: "white",
  },
  "Top Company": {
    label: "Top Company",
    backgroundColor: "#fb8500",
    color: "white",
  },
  "Research Paper Author": {
    label: "Research Paper Author",
    backgroundColor: "#53b1dd",
    color: "white",
  },
};

const getBackgroundColor = (props) => {
  return mapping[props.status]["backgroundColor"];
};

const getTextColor = (props) => {
  return mapping[props.status]["color"];
};

const getLabel = (state) => {
  return mapping[state]["label"];
};

const useStyles = makeStyles((theme) => ({
  stateBadge: {
    backgroundColor: getBackgroundColor,
    margin: "4px",
    fontSize: "12px",
    color: getTextColor,
    "& .MuiChip-deleteIcon": {
      color: "#ffffff94",
    },
  },
}));

export default function ScreenoTagBadge(props) {
  const classes = useStyles(props);
  return (
    <Chip
      size="small"
      label={<b>{getLabel(props.status)}</b>}
      className={classes.stateBadge}
      onDelete={props.onDelete}
    />
  );
}
