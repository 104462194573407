import { Button, CircularProgress, Link } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router';
import { Redirect } from "react-router-dom";
import { UserInfoContext } from '../../contexts/UserInfoContext';
import amplitudeLogger from '../../utils/amplitude';
import { reczeeAlertParams } from '../../utils/commonHelpers';
import { getRequest, postRequest } from '../../utils/httpHelpers';
import { ReczeeCheckBox, ReczeeInputField } from '../commons/ReczeeInputField';

const useStyles = makeStyles((theme) => ({
  // submit: formSubmitButton(theme),
  containerGrid: {
    marginTop: theme.spacing(2),
    textAlign: "center"
  },
  inputField: {
    marginTop: theme.spacing(2)
  },
  termsAcceptLabel: {
    fontSize: '12px',
  }
}))

const inviteStatusMapping = {
  "invalid_token": "Invalid token",
  "invite_not_found": "Invite not found",
  "user_password_required": "User password required",
  "user_already_exists": "User already exists"
};

export default function AcceptInvitePage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { inviteToken } = useParams();
  const [userInfo, setUserInfo] = React.useContext(UserInfoContext);
  const [inviteDetails, setInviteDetails] = React.useState({});
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [privacyPoliceRead, setPrivacyPoliceRead] = React.useState(false);

  document.title = "Accept invite | Reczee";

  React.useEffect(() => {
    getRequest(`invite-status`, { token: inviteToken },
      (data) => {
        setInviteDetails(data);
      },
      (errorMessages, statusCode) => {
      });
  }, [inviteToken]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!e.target.elements.password.value) {
      enqueueSnackbar("Please enter a password", reczeeAlertParams("error"));
      return;
    }
    if (!privacyPoliceRead) {
      enqueueSnackbar("Please read and accept the privacy policy", reczeeAlertParams("error"));
      return;
    }

    setIsFormSubmitted(true);

    postRequest("users/create-from-invite",
      { token: inviteToken },
      {
        password: e.target.elements.password.value,
      },
      (data) => {
        setIsFormSubmitted(false);
        setUserInfo({
          userId: data.user.id,
          token: data.token,
          email: data.user.email,
          roles: data.user.roles,
          name: data.user.name,
          emailVerified: data.user.email_verified,
          companyApproved: data.company.approved
        });

        amplitudeLogger.startSession(data.user.id, data.company.id);
        let amplitudeProperties = { user_roles: data.user.roles, email_verified: data.user.email_verified };
        amplitudeLogger.logEvent("INVITE_SIGNUP_SUCCESS", amplitudeProperties);
        enqueueSnackbar("Successfully logged in!", reczeeAlertParams("success"));
      },
      (errorMessages, statusCode) => {
        setIsFormSubmitted(false);
        amplitudeLogger.logEvent("INVITE_SIGNUP_FAILED", { status_code: statusCode, error_messages: errorMessages });
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        }
      });
  }

  if (userInfo && !!userInfo.token) {
    return (<Redirect push to="/dashboard"></Redirect>);
  }

  const handlePrivacyPolicyChange = (e) => {
    setPrivacyPoliceRead(e.target.checked);
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.containerGrid}
    >
      <Grid item xs={12}>
        <Typography component="h1" variant="h4">
          Accept invite
        </Typography>
        <br />
        {Object.keys(inviteDetails).length === 0 ? <CircularProgress /> :
          (inviteDetails["invite_status"] === "user_password_required" ?
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <ReczeeInputField
                id="email"
                name="email"
                label="Email"
                autoComplete="email"
                value={inviteDetails["email"]}
                disabled={true}
                className={classes.inputField}
                fullWidth={true}
              />
              <ReczeeInputField
                id="designation"
                name="designation"
                label="Designation"
                value={inviteDetails["designation"]}
                disabled={true}
                className={classes.inputField}
                fullWidth={true}
              />
              <ReczeeInputField
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth={true}
                className={classes.inputField}
                autoComplete="current-password"
              />
              <ReczeeCheckBox
                checked={privacyPoliceRead}
                onChange={handlePrivacyPolicyChange}
                name="privacy_policy"
                label={<span className={classes.termsAcceptLabel}>I have read and accept the <Link variant="body2" className={classes.termsAcceptLabel} target="_blank" href="https://www.reczee.com/privacy-policy">Privacy Policy</Link> and <Link variant="body2" target="_blank" className={classes.termsAcceptLabel} href="https://www.reczee.com/terms-and-conditions">Terms and conditions</Link></span>}
                className={classes.inputField}
              />
              <Grid container
                direction="column"
                justify="center"
                alignItems="center">
                <Button
                  disabled={isFormSubmitted}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.inputField}
                  size="medium"
                >
                  {isFormSubmitted ? "Please wait..." : "Signup"}
                </Button>
              </Grid>
            </form>
            : inviteStatusMapping[inviteDetails["invite_status"]])}
      </Grid>
    </Grid>
  );
}
