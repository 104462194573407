import { Chip, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { candidateStateBadgeColors } from "../../constants.js";

const getBackgroundColor = (props) => {
  return candidateStateBadgeColors[props.state]["backgroundColor"];
};

const getTextColor = (props) => {
  return candidateStateBadgeColors[props.state]["color"];
};

const useStyles = makeStyles((theme) => ({
  stateBadge: {
    minWidth: "50px",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "bold",
    backgroundColor: getBackgroundColor,
    color: getTextColor,
    height: (props) => (props.small ? "unset" : ""),
    "& .MuiChip-deleteIcon": {
      color: "#ffffff94",
    },
  },
}));

export default function CandidateApplicationStateBadge(props) {
  const classes = useStyles(props);

  let stateContent = (
    <Chip
      size="small"
      label={props.label}
      onDelete={props.onDelete}
      className={`${classes.stateBadge} ${props.className}`}
    />
  );
  if (props.hoverMessage) {
    stateContent = (
      <Tooltip arrow title={props.hoverMessage}>
        {stateContent}
      </Tooltip>
    );
  }
  return stateContent;
}
