import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Typography,
  Box,
  Paper,
  makeStyles,
  Grid,
  Link,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { getRequest, postRequest } from "../../utils/httpHelpers";
import { getQueryParams, getStaticResourceUrl } from "../../utils/urlHelpers";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { useSnackbar } from "notistack";
import "./self-schedule.css";
import LabelWithIcon from "../commons/LabelWithIcon";
import TimerIcon from "@material-ui/icons/Timer";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import VideocamIcon from "@material-ui/icons/Videocam";
import PersonIcon from "@material-ui/icons/Person";
import LanguageIcon from "@material-ui/icons/Language";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: "#e4f4ff",
    height: "100vh",
  },
  interviewNote: {
    marginTop: theme.spacing(2),
    color: "darkgrey",
  },
  paper: {
    width: "90%",
    maxWidth: "1100px",
    height: "500px",
    padding: theme.spacing(4),
    display: "flex",
    overflow: "hidden",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
  },
  errorMessagePaper: {
    width: "90%",
    maxWidth: "1100px",
    height: "500px",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
  },
  wrapper: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "green",
  },
  userInfoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
    },
  },
  userInfo: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  meetingDetails: {
    marginTop: theme.spacing(1),
    fontSize: "1rem",
    color: "#666",
  },
  calendarBox: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  timeSlotContainer: {
    flex: 1,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    overflow: "scroll",
  },
  timeSlotButtonDiv: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    width: "100%",
    textTransform: "none",
    // padding: theme.spacing(1.5),
    backgroundColor: "#494949",
    color: "white !important",
  },
  interviewDetailsContainer: {
    marginTop: theme.spacing(2),
  },
  selectSlotButton: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    width: "100%",
    textTransform: "none",
  },
  confirmTimeSlotButton: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    width: "100%",
    textTransform: "none",
  },
  timeZoneInfo: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  companyLogo: {
    width: "150px",
  },
  submitButtonGroup: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    // paddingTop: theme.spacing(4),
    // marginTop: theme.spacing(2),
  },
  selfScheduleDetailIcon: {
    color: "#757575",
  },
}));

function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
  if (a_start <= b_start && b_start < a_end) return true; // b starts in a
  if (a_start < b_end && b_end <= a_end) return true; // b ends in a
  if (b_start <= a_start && a_end <= b_end) return true; // a in b
  return false;
}

function getCrossMark() {
  return (
    <div class="wrapper">
      <svg
        class="crossmark animateElement"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          class="crossmark__circle animateElement"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        <path
          class="cross__path cross__path--right animateElement"
          fill="none"
          d="M16,16 l20,20"
        />
        <path
          class="cross__path cross__path--left animateElement"
          fill="none"
          d="M16,36 l20,-20"
        />
      </svg>
    </div>
  );
}
function getCheckMark() {
  return (
    <div class="wrapper">
      {" "}
      <svg
        class="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        {" "}
        <circle
          class="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />{" "}
        <path
          class="checkmark__check"
          fill="none"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    </div>
  );
}

function getFullTimezoneName() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the IANA timezone name
  const options = { timeZone: timezone, timeZoneName: "long" }; // Set options for long timezone name
  const formatter = new Intl.DateTimeFormat([], options);
  const parts = formatter.formatToParts(new Date());

  // Extract the time zone name
  const timeZoneName = parts.find((part) => part.type === "timeZoneName");

  return timeZoneName ? timeZoneName.value : timezone; // Return full name or fallback to timezone
}

export default function SelfSchedule() {
  const classes = useStyles();
  const { candidateApplicationSlug } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [availableSlots, setAvailableSlots] = useState([]);
  const [blockedSlots, setBlockedSlots] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [schedulingToken, setSchedulingToken] = useState(null);
  const [candidateName, setCandidateName] = useState("");
  const [panelId, setPanelId] = useState(null);
  const [shadowPanelId, setShadowPanelId] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = React.useState("");
  const [duration, setDuration] = useState(null);
  const [startTimeRange, setStartTimeRange] = useState(null);
  const [endTimeRange, setEndTimeRange] = useState(null);
  const [panelTimezoneOffset, setPanelTimezoneOffset] = useState(0);
  const [candidateTimezoneOffset, setCandidateTimezoneOffset] = useState(
    new Date().getTimezoneOffset()
  );
  const [roundName, setRoundName] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const [slotsLoading, setSlotsLoading] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [companyLogoUrl, setCompanyLogoUrl] = useState("");
  const [issuedAt, setIssuedAt] = useState(new Date().getTime());

  useEffect(() => {
    const queryParams = getQueryParams();
    const token = queryParams.scheduling_token;
    setSchedulingToken(token);
    getRequest(
      "candidate-application/get-self-schedule-details",
      {
        scheduling_token: token,
      },
      (response) => {
        setCandidateName(response["candidate_name"]);
        setPanelId(response["panel_id"]);
        setShadowPanelId(response["shadow_panel_id"]);
        setDuration(response["duration"]);
        setRoundName(response["round_name"]);
        setCompanyName(response["company_name"]);
        setLocation(response["location"]);
        document.title = `Self Schedule - ${response["round_name"]} - ${response["candidate_name"]} | Reczee ATS`;
        setStartTimeRange(response["start_time_range"]);
        setCompanyLogoUrl(response["company_logo_url"]);
        setIssuedAt(response["issued_at"] * 1000);
        setEndTimeRange(response["end_time_range"]);
        setPanelTimezoneOffset(response["panel_timezone_offset"]);
        setLoading(false);
      },
      (errorMessages) => {
        setLoading(false);
        // enqueueSnackbar("Unable to fetch details!", reczeeAlertParams("error"));
        if (typeof errorMessages === "string") {
          setErrorMessage(errorMessages);
        } else {
          setErrorMessage("Something went wrong!");
        }
      }
    );
  }, []);

  // Time slot generator
  const generateSlots = useCallback(() => {
    if (!panelId || !duration || loading || !startTimeRange || !endTimeRange)
      return [];

    // Parse panel's working hours
    const [panelStartHours, panelStartMinutes] = startTimeRange
      .split(":")
      .map(Number);
    const [panelEndHours, panelEndMinutes] = endTimeRange
      .split(":")
      .map(Number);

    // Panel's working hours on the selected date in panel's local time
    const panelLocalStartTime = new Date(selectedDate);
    panelLocalStartTime.setHours(panelStartHours, panelStartMinutes, 0, 0);

    const panelLocalEndTime = new Date(selectedDate);
    panelLocalEndTime.setHours(panelEndHours, panelEndMinutes, 0, 0);

    // Calculate time difference between panel and candidate in milliseconds
    const timeDifference =
      (panelTimezoneOffset - candidateTimezoneOffset) * 60000;

    // Convert panel's working hours to candidate's local time
    const candidateStartTime = new Date(
      panelLocalStartTime.getTime() + timeDifference
    );
    const candidateEndTime = new Date(
      panelLocalEndTime.getTime() + timeDifference
    );

    // Candidate's selected date start time (midnight)
    const midNight = new Date(selectedDate);
    midNight.setHours(0, 0, 0, 0);

    // Slot duration in milliseconds
    const interval = duration * 60000;

    // Get current time and minimum start time (current time + 6 hours) in candidate's local time
    const currentTime = new Date(); // Candidate's current time
    const isToday = selectedDate.toDateString() === currentTime.toDateString();
    const minStartTime = new Date(currentTime.getTime() + 6 * 60 * 60 * 1000); // 6 hours from now

    const slots = [...Array(50)].map((_, i) => {
      // Calculate slot start and end times
      const thisStartTime = new Date(midNight.getTime() + interval * i);
      const thisEndTime = new Date(midNight.getTime() + interval * (i + 1));

      // Adjusted panel's working hours in candidate's local time
      const thisPanelStartTime = new Date(
        midNight.getTime() +
          candidateStartTime.getHours() * 60 * 60 * 1000 +
          candidateStartTime.getMinutes() * 60 * 1000
      );
      const thisPanelEndTime = new Date(
        midNight.getTime() +
          candidateEndTime.getHours() * 60 * 60 * 1000 +
          candidateEndTime.getMinutes() * 60 * 1000
      );

      // Next day's midnight
      const nextMidNight = new Date(midNight.getTime() + 24 * 60 * 60 * 1000);

      // Apply 6-hour delay for the current day
      if (isToday && thisStartTime < minStartTime) {
        return null;
      }

      // Convert thisStartTime (candidate's local time) to panel's local time
      const panelSlotStartTime = new Date(
        thisStartTime.getTime() - timeDifference
      );

      // Convert thisEndTime (candidate's local time) to panel's local time
      const panelSlotEndTime = new Date(thisEndTime.getTime() - timeDifference);

      const panelDayOfWeekStart = panelSlotStartTime.getDay(); // 0 (Sunday) to 6 (Saturday)
      const panelDayOfWeekEnd = panelSlotEndTime.getDay(); // 0 (Sunday) to 6 (Saturday)
      if (
        panelDayOfWeekStart === 0 ||
        panelDayOfWeekStart === 6 ||
        panelDayOfWeekEnd === 0 ||
        panelDayOfWeekEnd === 6
      ) {
        // Weekend in panel's timezone, skip this slot
        return null;
      }

      // Check if the slot is within the panel's working hours
      let slotIsValid = false;

      if (thisPanelStartTime < thisPanelEndTime) {
        // Panel availability does not cross midnight
        if (
          thisPanelStartTime <= thisStartTime &&
          thisEndTime <= thisPanelEndTime
        ) {
          slotIsValid = true;
        }
      } else {
        // Panel availability crosses midnight
        if (
          (thisPanelStartTime <= thisStartTime &&
            thisStartTime <= nextMidNight) ||
          (midNight <= thisStartTime && thisEndTime <= thisPanelEndTime)
        ) {
          slotIsValid = true;
        }
      }

      if (!slotIsValid) {
        return null;
      }

      // Skip blocked slots
      if (isOverlapping(thisStartTime, thisEndTime)) {
        return null;
      }

      // Return valid slot
      return {
        start: thisStartTime,
        end: thisEndTime,
      };
    });

    // Filter out null slots
    return slots.filter((slot) => slot != null);
  }, [selectedDate, loading, blockedSlots, candidateTimezoneOffset]);

  const isOverlapping = (startTime, endTime) => {
    if (!blockedSlots || Object.keys(blockedSlots).length === 0) return false;

    const checkPanelBlockedSlots = (panelId) => {
      if (blockedSlots[panelId]) {
        for (const thisSlot of blockedSlots[panelId]) {
          if (dateRangeOverlaps(thisSlot[0], thisSlot[1], startTime, endTime)) {
            return true;
          }
        }
      }
      return false;
    };

    // Check if either panel or shadowPanel has an overlap
    return (
      checkPanelBlockedSlots(panelId) || checkPanelBlockedSlots(shadowPanelId)
    );
  };

  // Fetch blocked slots from the backend for the selected panel and day
  const getBlockedSlots = useCallback(() => {
    if (!panelId || !duration || !startTimeRange || !endTimeRange) return;

    // Candidate's selected date start and end times in candidate's local time
    const candidateDateStart = new Date(selectedDate);
    candidateDateStart.setHours(0, 0, 0, 0);
    const candidateDateEnd = new Date(
      candidateDateStart.getTime() + 24 * 60 * 60 * 1000
    );

    // Epoch times in seconds
    const startEpoch = Math.floor(candidateDateStart.getTime() / 1000);
    const endEpoch = Math.floor(candidateDateEnd.getTime() / 1000);

    // Fetch blocked slots
    setSlotsLoading(true);

    const fetchBlockedSlots = (id) => {
      getRequest(
        "oauth/get-blocked-slots-self-schedule",
        {
          scheduling_token: schedulingToken,
          start_epoch: startEpoch,
          end_epoch: endEpoch,
          panel_id: id,
        },
        (data) => {
          let tempState = { ...blockedSlots };

          // Adjust blocked slots from UTC to candidate's local time
          tempState[id] = data.map((row) => {
            return [new Date(row[0]), new Date(row[1])];
          });

          setBlockedSlots(tempState);
          setSlotsLoading(false);
        },
        (errorMessages) => {
          setSlotsLoading(false);
          enqueueSnackbar(
            `Unable to fetch blocked slots for panel ${id}!`,
            reczeeAlertParams("error")
          );
        }
      );
    };

    // Fetch for main panel
    fetchBlockedSlots(panelId);

    // Fetch for shadow panel if applicable
    if (shadowPanelId) {
      fetchBlockedSlots(shadowPanelId);
    }
  }, [selectedDate, loading, enqueueSnackbar]);

  useEffect(() => {
    if (panelId && duration && !loading) {
      getBlockedSlots();
    }
  }, [selectedDate, getBlockedSlots, loading]);

  useEffect(() => {
    if (panelId && duration && !loading) {
      setAvailableSlots(generateSlots());
    }
  }, [selectedDate, generateSlots, loading]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedSlot) {
      enqueueSnackbar("Please select a time slot", reczeeAlertParams("error"));
      return;
    }

    let requestBody = {
      call_type: "interview",
      start_time: selectedSlot.start,
      end_time: selectedSlot.end,
      scheduling_token: schedulingToken,
    };

    setSubmitInProgress(true);

    postRequest(
      `candidate-application/confirm-self-schedule/${candidateApplicationSlug}`,
      {},
      requestBody,
      () => {
        enqueueSnackbar(
          "Successfully scheduled!",
          reczeeAlertParams("success")
        );
        setErrorMessage("success");
        setSubmitInProgress(false);
      },
      (errorMessages) => {
        enqueueSnackbar(
          typeof errorMessages === "string"
            ? errorMessages
            : "Something went wrong!",
          reczeeAlertParams("error")
        );
        setSubmitInProgress(false);
      }
    );
  };

  return (
    <div className={classes.container}>
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : errorMessage ? (
        <Grid container className={classes.errorMessagePaper} component={Paper}>
          <Grid item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {errorMessage === "success" ||
              errorMessage === "Interview already scheduled" ? (
                <>
                  {getCheckMark()}
                  <Typography variant="h6">
                    {errorMessage === "success"
                      ? "Successfully scheduled the interview. All the best!"
                      : errorMessage}
                  </Typography>
                </>
              ) : (
                <>
                  {getCrossMark()}
                  <Typography variant="h6">{errorMessage}</Typography>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container className={classes.paper} component={Paper}>
          <Grid item xs={12} md={4} className={classes.userInfoContainer}>
            {companyLogoUrl ? (
              <img
                src={companyLogoUrl}
                alt="Reczee Logo"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = getStaticResourceUrl("/reczeeblue.svg");
                }}
                className={classes.companyLogo}
              />
            ) : (
              ""
            )}
            <Box className={classes.userInfo}>
              {/* <Typography variant="subtitle2">
                Self-schedule interview
              </Typography> */}
              <Typography variant="h5">{roundName}</Typography>
              <span>with {companyName}</span>
              <div className={classes.interviewDetailsContainer}>
                <LabelWithIcon
                  startIcon={
                    <PersonIcon
                      fontSize="small"
                      className={classes.selfScheduleDetailIcon}
                    />
                  }
                  label={candidateName}
                />
                <LabelWithIcon
                  startIcon={
                    <TimerIcon
                      fontSize="small"
                      className={classes.selfScheduleDetailIcon}
                    />
                  }
                  label={`${duration}-minute`}
                />

                {location ? (
                  <LabelWithIcon
                    startIcon={
                      <LocationOnIcon
                        fontSize="small"
                        className={classes.selfScheduleDetailIcon}
                      />
                    }
                    label={location}
                  />
                ) : (
                  <LabelWithIcon
                    startIcon={
                      <VideocamIcon
                        fontSize="small"
                        className={classes.selfScheduleDetailIcon}
                      />
                    }
                    label={"Video interview"}
                  />
                )}
                <LabelWithIcon
                  startIcon={
                    <LanguageIcon
                      fontSize="small"
                      className={classes.selfScheduleDetailIcon}
                    />
                  }
                  label={getFullTimezoneName()}
                />
              </div>
              <div className={classes.interviewNote}>
                A calendar invite would be sent over your registered email after
                confirmation.
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className={classes.calendarBox}>
            <div>
              <Typography variant="h6" align="center">
                Select a date
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar
                  variant="static"
                  format="dd/MM/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  maxDate={new Date(issuedAt + 14 * 24 * 60 * 60 * 1000)}
                  autoOk
                  disablePast
                />
              </MuiPickersUtilsProvider>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              Powered by
              <Link
                style={{ display: "flex" }}
                href={
                  "https://www.reczee.com/ats?utm_source=reczee&utm_medium=scheduling_link&utm_campaign=footer"
                }
                target="_blank"
              >
                <img
                  height="30"
                  alt="Reczee ATS"
                  style={{ marginLeft: "4px" }}
                  src={getStaticResourceUrl("/reczee-ats-dark-logo.svg")}
                />
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} md={4} className={classes.timeSlotContainer}>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ color: "#818181", marginBottom: "16px" }}
            >
              {format(selectedDate, "EEEE, MMMM d")}
            </Typography>
            {slotsLoading ? (
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {availableSlots.length === 0 ? (
                  <Typography align="center">
                    No available slots for this date.
                  </Typography>
                ) : (
                  availableSlots.map((slot, index) =>
                    selectedSlot &&
                    selectedSlot.start.getTime() === slot.start.getTime() ? (
                      <div key={index} className={classes.submitButtonGroup}>
                        <Button disabled className={classes.timeSlotButtonDiv}>
                          {format(slot.start, "HH:mm")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.confirmTimeSlotButton}
                          disabled={submitInProgress}
                          onClick={handleSubmit}
                        >
                          Confirm
                        </Button>
                      </div>
                    ) : (
                      <Button
                        key={index}
                        variant={
                          selectedSlot === slot ? "contained" : "outlined"
                        }
                        className={classes.selectSlotButton}
                        color="primary"
                        onClick={() => handleSlotSelection(slot)}
                      >
                        {format(slot.start, "HH:mm")} -{" "}
                        {format(slot.end, "HH:mm")}
                      </Button>
                    )
                  )
                )}{" "}
              </>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
}
