import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CareersPageCarousel from "./CareersPageCarousel";
import { CAREERS_PAGE_CDN_BASE_URL } from "../../constants";

const getHeroSectionBackground = (color) => {
  return `radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,${color} 68% 70%,#0000 72%) 13px 13px/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,${color} 68% 70%,#0000 72%) 13px 13px/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 100% 25%,#0000 66%,${color} 68% 70%,#0000 72%) 0 0/calc(2*13px) calc(2*13px), radial-gradient(35.36% 35.36% at 0    75%,#0000 66%,${color} 68% 70%,#0000 72%) 0 0/calc(2*13px) calc(2*13px), repeating-conic-gradient(#ffffff 0 25%,#0000 0 50%) 0 0/calc(2*13px) calc(2*13px), radial-gradient(#0000 66%,${color} 68% 70%,#0000 72%) 0 calc(13px/2)/13px 13px #ffffff`;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
      textAlign: "center",
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    heroSection: {
      justifyContent: "center !important",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "0 20px",
        boxSizing: "border-box",
      },
    },
    careersPageSection: {
      minHeight: "500px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "center",
    },
    viewAllOpeningsButton: {
      padding: "8px 28px",
      marginTop: theme.spacing(1),
      fontSize: "16px",
      fontWeight: "bolder",
    },
    mainHeading: {
      marginTop: theme.spacing(10),
      fontWeight: "bolder",
      fontSize: "48px",
    },
    benefitsSection: {
      backgroundColor: theme.palette.secondary.main + "04",
    },
    lifeAtSectionFarmako: {
      padding: theme.spacing(2),
      display: "flex",
      paddingTop: theme.spacing(4),
      justifyContent: "start",
      flexDirection: "column",
      backgroundColor: "white",
      position: "relative",
      zIndex: 1,
    },
    aboutSectionMain: {
      backgroundColor: "#F9F9F9",
      padding: "48px",
      paddingTop: 0,
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        padding: "24px",
      },
    },
    aboutSectionHeading: {
      fontSize: "30px",
      paddingTop: "48px",
      paddingBottom: "15px",
      fontWeight: "700",
      textAlign: "left",
    },
  };
});

function LifeAtFarmako() {
  const classes = useStyles();
  return (
    <div className={classes.lifeAtSectionFarmako}>
      <Typography
        component="h3"
        variant="h4"
        style={{ paddingTop: "16px", paddingBottom: "8px", fontWeight: "700" }}
      >
        Life at Farmako
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ color: "#666666", marginBottom: "20px" }}
      >
        Embrace challenges and celebrate successes in an environment that values
        your contributions.
      </Typography>
      <CareersPageCarousel
        images={[
          `${CAREERS_PAGE_CDN_BASE_URL}/farmako/life1.jpg`,
          `${CAREERS_PAGE_CDN_BASE_URL}/farmako/life2.jpg`,
          `${CAREERS_PAGE_CDN_BASE_URL}/farmako/life3.jpg`,
          `${CAREERS_PAGE_CDN_BASE_URL}/farmako/life4.jpg`,
          `${CAREERS_PAGE_CDN_BASE_URL}/farmako/life5.jpg`,
        ]}
      />
    </div>
  );
}

export default function Farmako(props) {
  const classes = useStyles();

  const scrollToOpenPositions = () => {
    const element = document.getElementById("job-openings-list");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div
        className={`${classes.heroSection} ${classes.careersPageSection} `}
        style={{
          background: getHeroSectionBackground(
            props.companyDetails.customStyle.themeSecondaryColor
          ),
        }}
      >
        <Typography component="h1" variant="h4" className={classes.mainHeading}>
          Join our team
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.viewAllOpeningsButton}
          size={"medium"}
          onClick={scrollToOpenPositions}
        >
          View open jobs
        </Button>
      </div>
      <div className={classes.aboutSectionMain}>
        <div className={classes.aboutSection}>
          <Typography
            component="h3"
            variant="h4"
            className={classes.aboutSectionHeading}
          >
            About Farmako
          </Typography>
          <ul style={{ margin: "0px", fontSize: "18px" }}>
            <li>
              We are doing 30-minute medicine delivery in India. We set up
              super-efficient pharmacy dark stores and built state-of-the-art
              internal tools designed for quick medicine delivery. We are
              currently available 24/7 in New Delhi, Gurgaon, and Moradabad and
              delivered over 300K orders, including thousands of life saving
              medicine deliveries.
            </li>
            <li>
              We are backed by Y Combinator and some of the best VCs like- HOF
              Capital, Bayhouse Capital, Soma Capital, Backend Capital,
              Goodwater Capital, Unpopular Ventures and 25 angel investors.
            </li>
          </ul>
        </div>
        <div className={classes.ourVisionSection}>
          <Typography
            component="h3"
            variant="h4"
            className={classes.aboutSectionHeading}
          >
            Our Vision
          </Typography>
          <ul style={{ margin: "0px", fontSize: "18px" }}>
            <li>
              We want to make healthcare accessible to everyone in India. We are
              starting with quick medicine delivery, but our vision is much
              bigger than this. We believe we can make healthcare truly
              personalized, quick, accessible, cheap, and high-quality for
              everyone with great technology. It won’t get solved in a day or a
              few months. It requires super-focused and talented people. We
              believe that only the people who believe in this idea and want to
              dedicate the next 5-10 years of their lives to this vision can do
              this together.
            </li>
          </ul>
        </div>
        <div className={classes.peopleAtSection}>
          <Typography
            component="h3"
            variant="h4"
            className={classes.aboutSectionHeading}
          >
            People at Farmako
          </Typography>
          <ul style={{ margin: "0px", fontSize: "18px" }}>
            <li>
              We have three founders- who were friends from college. The idea of
              Farmako started at IIT Roorkee with only three people on the team,
              who just had a vague idea and no clear path to move forward. But
              we always had this vision that great technological breakthroughs
              can solve the big problems we see in healthcare today.
            </li>
            <li>
              As we moved forward, we kept adding the people with the same
              energy, mindset, vision, care, and dedication for Farmako. People
              who don’t care about any rules, ego, external world, image, or
              anything but who want to make something people want.
            </li>
          </ul>
        </div>
      </div>
      <LifeAtFarmako companyDetails={props.companyDetails} />
    </>
  );
}
