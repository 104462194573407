import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "../commons/Breadcrumbs";
import { getRequest } from "../../utils/httpHelpers";
import { useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import ScreenoCandidateListing from "./ScreenoCandidateListing";
import { UserInfoContext } from "../../contexts/UserInfoContext";

/*
  List of candidates, greenhouse/lever job link, filtering capabilities
*/
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "left",
  },
}));

export default function ScreenoJobPage() {
  const classes = useStyles();
  const { screenoJobId } = useParams();
  const [screenoJob, setScreenoJob] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [candidates, setCandidates] = React.useState([]);
  const [userInfo] = React.useContext(UserInfoContext);
  const [searchQuery, setSearchQuery] = React.useState("");
  const isScreenoApprovedUser =
    userInfo.emailVerified && userInfo.companyApproved;

  React.useEffect(() => {
    setIsLoading(true);
    getRequest(
      `screeno/jobs/${screenoJobId}`,
      {},
      (data) => {
        setIsLoading(false);

        setScreenoJob(data["job"]);
        setCandidates(data["job"]["candidates"]);
        document.title = `${data["job"].title} - Job | Reczee`;
      },
      (errorMessages, statusCode) => {
        setIsLoading(false);
      }
    );
  }, []);

  if (!screenoJob) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.breadcrumbsDiv}>
        <Grid item>
          <Breadcrumbs
            links={[
              {
                label: "Home",
                url: "/screeno",
              },
              {
                label: "Jobs",
                url: "/screeno/jobs",
              },
              {
                label: screenoJob.title,
              },
            ]}
          />
          <Typography variant="h5" component="h5">
            {screenoJob.title}
          </Typography>
        </Grid>
      </Grid>
      <ScreenoCandidateListing
        candidates={candidates}
        setCandidates={setCandidates}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        screenoJobId={screenoJobId}
        isScreenoApprovedUser={isScreenoApprovedUser}
      />
    </div>
  );
}
