export const validateScreeningQuestions = (questions, answers) => {
  if (!questions || questions.length === 0) return true;
  const valdation = questions.every((question) => {
    switch (question.field_type) {
      case "yes_no":
        answers[question.id] =
          answers[question.id] === null ? false : answers[question.id];
        break;
      case "multiple_choice":
        const answer = answers[question.id];
        if (question.required && (!answer || answer.length === 0)) {
          return false;
        }
        if (answer && answer.length === 0) {
          answers[question.id] = null;
        }
        break;
      case "single_choice":
        if (question.required && !answers[question.id]) {
          return false;
        }
        break;
      case "date":
        if (question.required && !answers[question.id]) {
          return false;
        }
        break;
      case "number":
        if (question.required && !answers[question.id]) {
          return false;
        }
        break;
      default:
        if (
          question.required &&
          (!answers[question.id] || answers[question.id].trim() === "")
        ) {
          return false;
        }
        break;
    }
    return true;
  });
  return valdation;
};
