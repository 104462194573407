import { CircularProgress, Badge } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Breadcrumbs from "../commons/Breadcrumbs";
import { getRequest } from "../../utils/httpHelpers";
import Card from "@material-ui/core/Card";
import ScreenoTagBadge from "./ScreenoTagBadge";
import { Link } from "@material-ui/core";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import { capitalizeWord } from "../../utils/commonHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "left",
  },
  tagCountsContainer: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  jobTitleColumn: {
    display: "flex",
    justifyContent: "space-between",
  },
  applicationStatsNew: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  applicationStatsInProgress: {
    cursor: "pointer",
    color: "green",
  },
  applicationStatsReject: {
    cursor: "pointer",
    color: "red",
  },
  reczeeInputFormFields: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  breadcrumbsDiv: { justifyContent: "space-between", alignItems: "center" },
  tableContent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  descriptionText: {
    fontWeight: "normal",
    marginTop: "20px",
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    justifyContent: "center",
  },
  formSubmitButton: {
    height: "40px",
  },
  badgeCount: {
    marginRight: "10px",
    "& .MuiBadge-badge": {
      backgroundColor: "#dbdbdb",
      fontWeight: 600,
      right: "5px",
    },
  },
  formContainer: {
    marginTop: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",
  },
  addRequisitionContainer: {
    textAlign: "right",
    width: "90%",
    marginTop: theme.spacing(2),
  },
}));

export default function ScreenoJobs() {
  const classes = useStyles();
  const [jobs, setJobs] = React.useState([]);
  const [isLoadingJobs, setIsLoadingJobs] = React.useState(true);

  document.title = "Jobs | Reczee Screeno";

  React.useEffect(() => {
    getRequest(
      "screeno/jobs",
      { view: "table_view" },
      (response) => {
        setJobs(response["jobs"]);
        setIsLoadingJobs(false);
      },
      (errorMessages, statusCode) => {
        setIsLoadingJobs(false);
      }
    );
  }, []);

  let gridContent = "";
  if (jobs.length > 0) {
    gridContent = (
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="center">Tags</TableCell>
              <TableCell align="center">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <div className={classes.jobTitleColumn}>
                      <Link href={`/screeno/jobs/${row.id}`}>{row.title}</Link>
                      {row.ats_provider && (
                        <img
                          height="24"
                          alt={capitalizeWord(row.ats_provider)}
                          title={capitalizeWord(row.ats_provider)}
                          src={getStaticResourceUrl(
                            row.ats_provider === "lever"
                              ? "/screeno/lever-small-logo.png"
                              : "/screeno/greenhouse-small-logo.png"
                          )}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tagCountsContainer}
                  >
                    {Object.keys(row.tag_counts)
                      .sort()
                      .reverse()
                      .map((tag, index) => {
                        return (
                          <Badge
                            badgeContent={row.tag_counts[tag]}
                            color="default"
                            className={classes.badgeCount}
                          >
                            <ScreenoTagBadge key={index} status={tag} />
                          </Badge>
                        );
                      })}
                  </TableCell>
                  <TableCell align="center">{row.total_candidates}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    gridContent = "";
  }

  return (
    <div className={classes.root}>
      <Grid container className={classes.breadcrumbsDiv}>
        <Grid item>
          <Breadcrumbs
            links={[
              {
                label: "Home",
                url: "/screeno",
              },
              {
                label: "Jobs",
                url: "/screeno/jobs",
              },
            ]}
          />
          <Typography variant="h5" component="h5">
            Jobs
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "12px",
        }}
      >
        {isLoadingJobs ? (
          <CircularProgress />
        ) : (
          <Grid item xs={11} component={Card} className={classes.tableContent}>
            {gridContent}
          </Grid>
        )}
      </Grid>
    </div>
  );
}
