import React from 'react';

export default function InternalServerError() {
  // @Todo(Raj): Nice UI
  return (
    <div>
      <p>Something went wrong, we are working on fixing this as soon as possible.</p>
      <p>Please contact us if the issue persists.</p>
    </div>
  );
};