import { RECZEE_REF } from "../constants";
import { postRequest } from "./httpHelpers";

export const markReferral = (reczeeRef) => {
  postRequest("talenthub/candidate/mark-referral",
    {},
    {
      ref: reczeeRef
    },
    (data) => {
      localStorage.removeItem(RECZEE_REF);
    },
    (errorMessages, statusCode) => {
      console.log("Error submitting ref", errorMessages, statusCode);
    }
  );
};