import React from "react";
import {
  ReczeeAutoComplete,
  ReczeeFileDropInputField,
} from "../commons/ReczeeInputField";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { reczeeAlertParams, basicPluralize } from "../../utils/commonHelpers";
import { useSnackbar } from "notistack";
import { BULK_RESUME_FILES_LIMIT } from "../../constants";
import { Button } from "@material-ui/core";
import { multiPartPostRequest } from "../../utils/httpHelpers";
import amplitudeLogger, { SCREENO_DASHBOARD_PAGE } from "../../utils/amplitude";
import { UserInfoContext } from "../../contexts/UserInfoContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { getStaticResourceUrl } from "../../utils/urlHelpers";

const freeSoloFilter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  dropZoneContainer: {
    width: "80%",
    backgroundColor: "white",
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  secureMessage: {
    marginTop: "6px",
    fontSize: "11px",
    textAlign: "center",
  },
  jobsField: {
    width: "70%",
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

function ScreenoUploadResumeContainer({
  setIsLoading,
  setCandidates,
  setPendingCredits,
  postResumeUploadSteps,
  setShouldIntervalBeCancelled,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [userInfo] = React.useContext(UserInfoContext);
  const [uploadedFileContent, setUploadedFileContent] =
    React.useState("0 files selected");
  const [screenoJob, setScreenoJob] = React.useState(null);
  const [notificationInfo, setNotificationInfo] =
    React.useContext(NotificationContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const resumeFileOnChange = (files) => {
    if (files.length > 0) {
      setUploadedFiles(files);
      setUploadedFileContent(
        `${basicPluralize(files.length, "file")} selected`
      );
    }
  };

  const handleRejectedFiles = () => {
    enqueueSnackbar("File type not allowed!", reczeeAlertParams("error"));
  };

  const handleResumeUpload = async (files) => {
    amplitudeLogger.logEvent("SCREENO_FILE_UPLOAD", {
      accepted_files: files.length,
      page: SCREENO_DASHBOARD_PAGE,
    });
    if (files.length <= BULK_RESUME_FILES_LIMIT) {
      resumeFileOnChange(files);
    } else {
      enqueueSnackbar(
        `Please upload upto ${BULK_RESUME_FILES_LIMIT} files at once`,
        reczeeAlertParams("error")
      );
    }
  };

  const handleSubmit = () => {
    amplitudeLogger.logEvent("SCREENO_FORM_SUBMIT", {
      accepted_files: uploadedFiles.length,
      email_verified: userInfo.emailVerified,
      company_approved: userInfo.companyApproved,
      page: SCREENO_DASHBOARD_PAGE,
      screeno_job_title: screenoJob?.title,
      tab: "manual",
    });

    if (!userInfo.emailVerified || !userInfo.companyApproved) {
      enqueueSnackbar(`Please verify your email!`, reczeeAlertParams("error"));
      return;
    }
    if (uploadedFiles.length === 0) {
      enqueueSnackbar(`Please upload resumes!`, reczeeAlertParams("error"));
      return;
    }

    if (screenoJob === null || screenoJob.title === null) {
      enqueueSnackbar("Please add a Job title!", reczeeAlertParams("error"));
      return;
    }

    const formData = new FormData();
    formData.append("screeno_job_title", screenoJob.title);
    setIsSubmitting(true);
    for (var i = 0; i < uploadedFiles.length; i++) {
      formData.append("resume_files[]", uploadedFiles[i]);
    }
    setIsLoading(true);

    multiPartPostRequest(
      "screeno/upload-resumes",
      {},
      formData,
      (data) => {
        setShouldIntervalBeCancelled(false);
        setCandidates(data.candidates);
        setNotificationInfo((prev) => {
          return { ...prev, pending_credits: data.pending_credits };
        });
        setPendingCredits(data.pending_credits);
        setIsLoading(false);
        setIsSubmitting(false);
        postResumeUploadSteps();
        sessionStorage.removeItem("is_new_screeno_account");
        setUploadedFiles([]);
        setUploadedFileContent("0 files selected");
        enqueueSnackbar("Successfully uploaded!", reczeeAlertParams("success"));
      },
      (errorMessages, statusCode) => {
        setIsSubmitting(false);
        setIsLoading(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );
  };

  const handleScreenoJobChange = (_, newValue) => {
    if (typeof newValue === "string") {
      setScreenoJob({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setScreenoJob({
        title: newValue.inputValue,
      });
    } else {
      setScreenoJob(newValue);
    }
  };

  return (
    <>
      <ReczeeFileDropInputField
        required={true}
        multiple={true}
        fileInputId="resume-upload"
        buttonText="Upload Resumes"
        name="upload_resumes"
        buttonSize="small"
        className={classes.dropZoneContainer}
        onDropRejected={handleRejectedFiles}
        onChange={handleResumeUpload}
        uploadedFileContent={uploadedFileContent}
      />
      <div className={classes.secureMessage}>
        🔒 Your data is secure. Works best for tech candidates based out of 🇮🇳
      </div>
      <ReczeeAutoComplete
        single={true}
        onChange={handleScreenoJobChange}
        value={screenoJob}
        freeSolo={true}
        selectOnFocus={true}
        clearOnBlur={true}
        filterOptions={(options, params) => {
          const filtered = freeSoloFilter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`, // @Todo(Raj): This thing is not working for some reason
            });
          }

          return filtered;
        }}
        label="Job"
        variant="standard"
        preSelectOnlyOne={true}
        className={classes.jobsField}
        dataFetchPath="screeno/jobs"
        reRenderDependency={[]}
        dataFetchParams={{ view: "basic" }}
        responseMapper={(response) => response.jobs}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }

          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }

          // Regular option
          return option.title;
        }}
      />
      <Button
        startIcon={
          <img
            width="20"
            alt="star"
            src={getStaticResourceUrl(
              isSubmitting ? "/ai-stars-disabled.svg" : "/ai-stars.svg"
            )}
          />
        }
        variant="contained"
        color="primary"
        size="medium"
        className={classes.submitButton}
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? "PLEASE WAIT..." : "SUBMIT"}
      </Button>
    </>
  );
}
export default React.memo(ScreenoUploadResumeContainer);
