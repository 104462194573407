import React from "react";
import {
  isCandidateLoggedIn,
  isEmailVerified,
  isLoggedIn,
  isScreenoPage,
  isTalentHubCandidateLoggedIn,
} from "../utils/authHelpers";
import { getRequest } from "../utils/httpHelpers";
import { UserInfoContext } from "./UserInfoContext";

export const NotificationContext = React.createContext();
export const NotificationProvider = (props) => {
  const { setReloadApp } = props;
  const [notificationInfo, setNotificationInfo] = React.useState(null);
  const [userInfo] = React.useContext(UserInfoContext);

  React.useEffect(() => {
    if (
      isLoggedIn() &&
      isEmailVerified() &&
      !isTalentHubCandidateLoggedIn() &&
      !isCandidateLoggedIn() &&
      !notificationInfo
    ) {
      if (isScreenoPage()) {
        getRequest(
          "screeno/get-available-credits",
          {},
          (data) => {
            if (
              !notificationInfo ||
              notificationInfo.pending_credits !== data["pending_credits"]
            ) {
              setNotificationInfo({
                ...notificationInfo,
                pending_credits: data["pending_credits"],
              });
              setReloadApp(1);
            }
          },
          (errorMessages) => {}
        );
      } else {
        getRequest(
          "notifications/get-unread-count",
          {},
          (data) => {
            if (
              !notificationInfo ||
              notificationInfo.unread_count !== data["unread_count"]
            ) {
              setNotificationInfo({
                ...notificationInfo,
                unread_count: data["unread_count"],
                feature_details: data["feature_details"],
              });
              setReloadApp(1);
            }
          },
          (errorMessages) => {}
        );
      }
    }

    if (isTalentHubCandidateLoggedIn()) {
      getRequest(
        "talenthub/credits/get-total-credits",
        {},
        (data) => {
          if (
            !notificationInfo ||
            notificationInfo.total_credits !== data["total_credits"]
          ) {
            setNotificationInfo({
              ...notificationInfo,
              total_credits: data["total_credits"],
            });
            setReloadApp(1);
          }
        },
        (errorMessages) => {}
      );
    }

    // eslint-disable-next-line
  }, [notificationInfo, userInfo, setNotificationInfo]);

  return (
    <NotificationContext.Provider
      value={[notificationInfo, setNotificationInfo]}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};
