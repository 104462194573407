import { Link, makeStyles } from "@material-ui/core";
import React from "react";
import { getStaticResourceUrl } from "../../utils/urlHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.main + "10",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "100px",
  },
  socialIconsImage: {
    width: "20px",
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

export function ReczeeJobsFooter(props) {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <div style={{ fontSize: "14px", color: "darkslategrey" }}>Powered by</div>
      <Link
        target="_blank"
        href={`https://www.reczee.com?utm_source=website&utm_medium=careers_page&utm_campaign=${props.companyDetails.slug}`}
      >
        <img
          src={getStaticResourceUrl("/reczee_navbar_black.svg")}
          alt={"Reczee"}
          width="130"
          className={classes.companyLogoImage}
        />
      </Link>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          marginTop: "8px",
        }}
      >
        {props.companyDetails.social_links.linkedin &&
        props.companyDetails.social_links.linkedin ? (
          <Link
            target="_blank"
            href={props.companyDetails.social_links.linkedin}
          >
            <img
              src={getStaticResourceUrl("/social-icons/dark/linkedin.svg")}
              className={classes.socialIconsImage}
              alt="LinkedIn"
            />
          </Link>
        ) : (
          ""
        )}
        {props.companyDetails.social_links.linkedin &&
        props.companyDetails.social_links.twitter ? (
          <Link
            target="_blank"
            href={props.companyDetails.social_links.twitter}
          >
            <img
              src={getStaticResourceUrl("/social-icons/dark/twitter.svg")}
              className={classes.socialIconsImage}
              alt="Twitter"
            />
          </Link>
        ) : (
          ""
        )}
        {props.companyDetails.social_links.linkedin &&
        props.companyDetails.social_links.youtube ? (
          <Link
            target="_blank"
            href={props.companyDetails.social_links.youtube}
          >
            <img
              src={getStaticResourceUrl("/social-icons/dark/youtube.svg")}
              className={classes.socialIconsImage}
              alt="Youtube"
            />
          </Link>
        ) : (
          ""
        )}
        {props.companyDetails.social_links.linkedin &&
        props.companyDetails.social_links.instagram ? (
          <Link
            target="_blank"
            href={props.companyDetails.social_links.instagram}
          >
            <img
              src={getStaticResourceUrl("/social-icons/dark/instagram.svg")}
              className={classes.socialIconsImage}
              alt="Instagram"
            />
          </Link>
        ) : (
          ""
        )}
        {props.companyDetails.social_links.linkedin &&
        props.companyDetails.social_links.facebook ? (
          <Link
            target="_blank"
            href={props.companyDetails.social_links.facebook}
          >
            <img
              src={getStaticResourceUrl("/social-icons/dark/facebook.svg")}
              className={classes.socialIconsImage}
              alt="Facebook"
            />
          </Link>
        ) : (
          ""
        )}
      </div>
    </footer>
  );
}
