import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { getStaticResourceUrl } from '../../utils/urlHelpers';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    textAlign: 'center'
  },
  image: {
    marginTop: theme.spacing(4)
  }
}));

export function ProfileNotFound() {
  const classes = useStyles();
  return <div className={classes.root}>
    <Typography component="h1" variant="h4">
      Profile not found
    </Typography>
    <img src={getStaticResourceUrl("/profile-not-found.svg")} alt="Not found" className={classes.image} height="500" />
  </div>;
}