import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { getOauthLocalStorageKey } from '../../utils/authHelpers';
import { getQueryParams } from '../../utils/urlHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(3)
  }
}));

export default function OauthRedirectCallback() {
  const classes = useStyles();
  const queryParams = getQueryParams();
  React.useEffect(() => {
    if (queryParams.status && queryParams.transaction_id) {
      localStorage.setItem(getOauthLocalStorageKey(queryParams.transaction_id), queryParams.status);
    }
  }, [queryParams]);
  return (
    <div className={classes.root}>
      <CircularProgress/>
    </div>
  );
}