import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { postRequest, getRequest } from "../../utils/httpHelpers";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import Paper from "@material-ui/core/Paper";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Skeleton } from "@material-ui/lab";
import RenderRazorpay from "../payments/RenderRazorpay";
import { BackgroundHeader } from "../talenthub/BackgroundHeader";
import { shuffleArray } from "../../utils/commonHelpers";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import amplitudeLogger from "../../utils/amplitude";
import { NotificationContext } from "../../contexts/NotificationContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  planFeatures: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  planPrice: {
    fontSize: "32px",
  },
  planFeature: {
    display: "flex",
    flexDirection: "row",
    fontSize: "18px",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subHeading: {
    padding: "16px",
    paddingLeft: "24px",
    paddingRight: "24px",
    backgroundColor: "white",
    borderRadius: "20px",
  },
  allCenterDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  pricingSelectButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    // minWidth: "180px",
    // fontSize: "12px",
    width: "100%",
  },
  myContainer: {
    position: "relative",
    top: "-40px",
  },
  mainHeader_1: {
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      gap: 0,
    },
  },
  tickIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  strikethroughPrice: {
    fontSize: "18px",
  },
  modalSection: {
    // marginTop: theme.spacing(3),
    textAlign: "center",
    fontWeight: 600,
    fontSize: "20px",
    display: "flex",
  },
  modalDivider: {
    width: "300px",
  },
  linkedInbutton: {
    // backgroundColor: "#2466c2",
    // color: "white",
    marginTop: "4px",
    "&:hover": {
      opacity: 0.9,
      // backgroundColor: "#2466c2",
      textDecoration: "none",
      // color: "white",
    },
  },
  planCard: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "95%",
    borderRadius: "10px",
    minHeight: "230px",
    margin: theme.spacing(1),
  },
  paperContainer: {
    borderRadius: "20px",
    padding: theme.spacing(3),
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(-2),
  },
  paymentPlansContainer: {
    marginTop: theme.spacing(2),
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function ScreenoCreditsPage() {
  const classes = useStyles();
  const [availableCredits, setAvailableCredits] = React.useState();
  const [pendingCredits, setPendingCredits] = React.useState();
  const [notificationInfo, setNotificationInfo] =
    React.useContext(NotificationContext);
  const [usedCredits, setUsedCredits] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadRazorpayModal, setLoadRazorpayModal] = React.useState(false);
  const [paymentOrder, setPaymentOrder] = React.useState(null);
  const [razorpayKeyId, setRazorpayKeyId] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [fetchingRazorpay, setFetchingRazorpay] = React.useState(false);
  const [reczeePaymentOrderId, setReczeePaymentOrderId] = React.useState("");
  const [customerDetails, setCustomerDetails] = React.useState({});
  const isIndia =
    Intl.DateTimeFormat().resolvedOptions().timeZone === "Asia/Calcutta";
  const linkedInShareText = shuffleArray([
    "Recently tried this product - @Reczee Screeno! 🎉\n\nI am blown away by how accurately it can highlight the top tech candidates in my funnel within seconds.\n\nThe workflow is as simple as uploading resumes and just waiting a few seconds to get these candidates tagged into Top Institute, Open Source Enthusiast, Top Company and more!\n\nNo more reviewing resumes one at a time 💯\n\nTry it out now - https://www.reczee.com/screeno \n\n #ReczeeScreeno #Reczee #ShortlistInSeconds #ResumeFiltering #Recruitment #TechHiring",
    "Recently came across this product - @Reczee Screeno 🎉\n\nIt automatically tags candidates into Top Institute, Open Source Enthusiast, Top Company, etc without even opening the resumes!\n\nThe best part is, it is as simple as uploading resumes and just waiting a few seconds to get these candidates tagged instead of spending hours spent in reviewing resumes one at a time 💯\n\nTry it out now - https://www.reczee.com/screeno \n\n #ReczeeScreeno #Reczee #ShortlistInSeconds #ResumeFiltering #Recruitment #TechHiring",
    "Found this amazing product - @Reczee Screeno 🎉\n\nIt automatically tags candidates into Top Institute, Open Source Enthusiast, Top Company, etc without even opening the resumes!\n\nThe best part is, it is as simple as uploading resumes and just waiting a few seconds to get these candidates tagged instead of spending hours spent in reviewing resumes one at a time 💯\n\nTry it out now - https://www.reczee.com/screeno \n\n #ReczeeScreeno #Reczee #ShortlistInSeconds #ResumeFiltering #Recruitment #TechHiring",
  ])[0];

  const [creditRefreshCount, setCreditRefreshCount] = React.useState(0);

  document.title = "Credits | Reczee Screeno";

  React.useEffect(() => {
    setIsLoading(true);
    getRequest(
      "screeno/get-available-credits",
      {},
      (data) => {
        setIsLoading(false);
        setAvailableCredits(data.available_credits);
        setPendingCredits(data.pending_credits);
        setUsedCredits(data.used_credits);
        setNotificationInfo((prev) => {
          return { ...prev, pending_credits: data.pending_credits };
        });
      },
      (errorMessages) => {
        setIsLoading(false);
      }
    );
  }, [creditRefreshCount]);

  const createOrderAndDisplay = (inrAmount) => {
    setLoadRazorpayModal(false);
    setFetchingRazorpay(true);
    postRequest(
      "screeno/create-order",
      {},
      {
        inr_amount: inrAmount,
      },
      (data) => {
        setRazorpayKeyId(data.razorpay_key_id);
        setReczeePaymentOrderId(data.reczee_payment_order_id);
        setPaymentOrder(data.order);
        setLoadRazorpayModal(true);
        setFetchingRazorpay(false);
        setCustomerDetails(data.customer_details);
      },
      (errorMessages, statusCode) => {
        setFetchingRazorpay(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );
  };

  const requestCallback = (kind) => () => {
    setFetchingRazorpay(true);
    postRequest(
      "screeno/request-callback",
      { kind: kind },
      {},
      (data) => {
        setTimeout(() => {
          setFetchingRazorpay(false);
          enqueueSnackbar(
            "We will reach out shortly!",
            reczeeAlertParams("success")
          );
        }, 1000);
      },
      (errorMessages) => {
        setFetchingRazorpay(false);
      }
    );
  };

  // Handles failure scenarios as well
  const paymentHandler = (
    reczee_payment_order_id,
    status,
    success_response,
    error_response
  ) => {
    console.debug("paymentHandler", status, success_response, error_response);
    postRequest(
      "screeno/update-order-status",
      {
        reczee_payment_order_id,
      },
      {
        status,
        success_response,
        error_response,
      },
      (data) => {
        if (status === "success") {
          setIsLoading(true);
          setTimeout(() => {
            setCreditRefreshCount((prev) => prev + 1);
          }, 3000);
        }
      },
      (errorMessages) => {
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );
  };

  return (
    <div className={classes.root}>
      <BackgroundHeader
        clr={"#219653"}
        title={"Screeno Credits"}
        subtitle={
          "Every Screeno credit is used against a resume processed. A credit is not used in case the resume is not parseable."
        }
        img={getStaticResourceUrl("/redeem.svg")}
      />

      <Grid container item xs={10} md={8} className={classes.myContainer}>
        <Grid
          container
          className={classNames(classes.subHeading, classes.mainHeader_1)}
        >
          <Grid item style={{ display: "flex", flexDirection: "column" }}>
            {isLoading ? (
              <Skeleton
                animation="pulse"
                width={200}
                height={50}
                variant="text"
              />
            ) : (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    alt="credit"
                    src={getStaticResourceUrl("/new-gold-credit.svg")}
                    style={{
                      width: "35px",
                      height: "35px",
                      marginRight: "8px",
                    }}
                  />
                  <Typography component="span" variant="h3">
                    {pendingCredits}
                  </Typography>{" "}
                  <Typography
                    component="span"
                    variant="h5"
                    style={{ marginLeft: "6px" }}
                  >
                    credits
                  </Typography>
                </div>
                <Typography>
                  Used {usedCredits} out of {availableCredits}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <span>
              Share and earn <b>50 credits</b>
            </span>
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              className={classes.linkedInbutton}
              component={Link}
              onClick={() => {
                amplitudeLogger.logEvent("SCREENO_SHARE_ON_LINKEDIN");
              }}
              target={"_blank"}
              href={`https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(
                linkedInShareText
              )}`}
              startIcon={<LinkedInIcon style={{ color: "#2466c2" }} />}
            >
              Share on LinkedIn
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={10}
        md={8}
        className={classes.paperContainer}
        component={Paper}
      >
        <Typography variant="h5" className={classes.modalSection}>
          Buy credits{" "}
          <img
            alt=""
            src={getStaticResourceUrl("/cart-icon.svg")}
            style={{
              width: "25px",
              height: "25px",
              marginLeft: "4px",
            }}
          />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={classes.allCenterDiv}>
            <Paper className={classes.planCard} elevation={3}>
              {isIndia ? (
                <s className={classes.strikethroughPrice}>₹2500</s>
              ) : (
                ""
              )}
              <Typography variant="h5" className={classes.planPrice}>
                <b>{isIndia ? "₹2000" : "$69"}</b>
              </Typography>
              <Typography>100 resumes</Typography>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                className={classes.pricingSelectButton}
                disabled={fetchingRazorpay}
                onClick={() => {
                  amplitudeLogger.logEvent("SCREENO_PLAN_SELECT", {
                    amount: 2000,
                    resumes: 100,
                    is_india: isIndia,
                  });
                  if (isIndia) {
                    createOrderAndDisplay(2000);
                  } else {
                    requestCallback(
                      "pricing_page_100" + (isIndia ? "" : "_us")
                    )();
                  }
                }}
              >
                {fetchingRazorpay ? "PLEASE WAIT..." : "SELECT"}
              </Button>
              <div className={classes.planFeatures}>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Screeno tags
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Boolean
                  search
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Bulk download
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Unlimited
                  jobs
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} className={classes.allCenterDiv}>
            <Paper className={classes.planCard} elevation={3}>
              <>
                {isIndia ? (
                  <s className={classes.strikethroughPrice}>₹5000</s>
                ) : (
                  ""
                )}
                <Typography variant="h5" className={classes.planPrice}>
                  <b>{isIndia ? "₹3500" : "$129"}</b>
                </Typography>
                <Typography>200 resumes</Typography>
              </>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="medium"
                className={classes.pricingSelectButton}
                disabled={fetchingRazorpay}
                onClick={() => {
                  amplitudeLogger.logEvent("SCREENO_PLAN_SELECT", {
                    amount: 3500,
                    resumes: 200,
                    is_india: isIndia,
                  });
                  if (isIndia) {
                    createOrderAndDisplay(3500);
                  } else {
                    requestCallback(
                      "pricing_page_200" + (isIndia ? "" : "_us")
                    )();
                  }
                }}
              >
                {fetchingRazorpay ? "PLEASE WAIT..." : "SELECT"}
              </Button>
              <div className={classes.planFeatures}>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> All Screeno
                  tags
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Boolean
                  search
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Bulk download
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Unlimited
                  jobs
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} className={classes.allCenterDiv}>
            <Paper className={classes.planCard} elevation={3}>
              {isIndia ? (
                <s
                  className={classes.strikethroughPrice}
                  style={{ color: "white" }}
                >
                  x
                </s>
              ) : (
                ""
              )}
              <Typography variant="h5" className={classes.planPrice}>
                <b>Custom</b>
              </Typography>
              <Typography>For higher volumes</Typography>
              <Button
                type="submit"
                variant="contained"
                className={classes.pricingSelectButton}
                color="primary"
                size="medium"
                disabled={fetchingRazorpay}
                onClick={() => {
                  amplitudeLogger.logEvent("SCREENO_PLAN_SELECT", {
                    amount: "custom",
                  });
                  requestCallback(
                    "pricing_page_custom" + (isIndia ? "" : "_us")
                  )();
                }}
              >
                {fetchingRazorpay ? "PLEASE WAIT..." : "REQUEST CALLBACK"}
              </Button>
              <div className={classes.planFeatures}>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Advanced
                  filters
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> ATS
                  Integrations
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Custom tag
                  definitions
                </div>
                <div className={classes.planFeature}>
                  <CheckCircleIcon className={classes.tickIcon} /> Bulk actions
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {loadRazorpayModal && (
        <RenderRazorpay
          amount={paymentOrder.amount}
          reczeePaymentOrderId={reczeePaymentOrderId}
          orderId={paymentOrder.id}
          keyId={razorpayKeyId}
          customerDetails={customerDetails}
          paymentHandler={paymentHandler}
        />
      )}
    </div>
  );
}
