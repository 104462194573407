import React from "react";
import {
  TextField,
  Typography,
  Checkbox,
  RadioGroup,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  OutlinedInput,
  Radio,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: 0,
    width: "100%",
    // minWidth: theme.spacing(30),
  },
  inputLabel: {
    color: "#666666",
    marginBottom: 0,
    fontSize: "14px",
  },
  filterSelect: {
    borderRadius: "12px",
    outlineColor: "#3f51b5",
    outlineWidth: "1px",
    fontSize: "14px",
  },
}));

export function CareersPageMultiSelect(props) {
  const classes = useStyles();

  return (
    <div className={classes.formControl}>
      <Typography variant="subtitle2" className={classes.inputLabel}>
        {props.title}
      </Typography>
      <Autocomplete
        size={props.size}
        multiple
        className={classes.filterSelect}
        onChange={props.onChange}
        id={props.id}
        options={props.options || []}
        getOptionLabel={props.getOptionLabel || ((option) => option)}
        filterSelectedOptions={props.filterSelectedOptions}
        getOptionSelected={
          props.getOptionSelected
            ? props.getOptionSelected
            : (option, value) => value && option.id === value.id
        }
        limitTags={props.limitTags}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </div>
  );
}

export function CareersPageInputField(props) {
  const classes = useStyles();
  let inputVariant = props.variant || "outlined";
  let inputFieldParams = {
    multiline: props.multiline,
    placeholder: props.placeholder,
    defaultValue: props.defaultValue,
    inputProps: props.inputProps || {},
    rows: props.rows,
    id: props.id,
    onChange: props.onChange ? props.onChange : undefined,
    disabled: props.disabled,
    name: props.name,
    label: props.label,
    autoFocus: props.autoFocus,
    type: props.type ? props.type : "text",
    "aria-describedby": props.id + "-helper-text",
    endAdornment: props.endAdornment,
    variant: inputVariant,
    value: props.value,
    autoComplete: props.autoComplete ? props.autoComplete : undefined,
  };

  return (
    <FormControl
      size={props.size}
      className={
        props.className
          ? `${props.className} ${classes.formControl}`
          : classes.formControl
      }
      error={props.errorField}
      required={props.required}
      variant={inputVariant}
      fullWidth={props.fullWidth}
      placeholder={props.placeholder}
      margin="dense"
    >
      {props.title && (
        <Typography
          variant="subtitle1"
          style={{ fontWeight: "normal" }}
          color={props.disabled ? "textSecondary" : "initial"}
          className={classes.inputLabel}
        >
          {props.title}
          {props.required && (
            <Typography
              component="span"
              color={props.disabled ? "textSecondary" : "error"}
            >
              *
            </Typography>
          )}
        </Typography>
      )}
      {props.label && (
        <InputLabel disabled={props.disabled} htmlFor="component-outlined">
          {props.label}
        </InputLabel>
      )}
      {React.createElement(
        props.variant === "standard" ? Input : OutlinedInput,
        inputFieldParams
      )}
      {props.helperText ? (
        <FormHelperText
          disabled={props.disabled}
          id={props.id + "-helper-text"}
        >
          {props.helperText}
        </FormHelperText>
      ) : (
        ""
      )}
    </FormControl>
  );
}

export function CareersPageRadioSeletionInput(props) {
  const classes = useStyles();
  return (
    <FormControl
      component="fieldset"
      className={
        props.className
          ? `${props.className} ${classes.formControl}`
          : classes.formControl
      }
    >
      <Typography
        variant="subtitle1"
        style={{ fontWeight: "normal" }}
        color={props.disabled ? "textSecondary" : "initial"}
        className={classes.inputLabel}
      >
        {props.title}
        {props.required && (
          <Typography
            component="span"
            color={props.disabled ? "textSecondary" : "error"}
          >
            *
          </Typography>
        )}
      </Typography>
      <RadioGroup
        aria-label={props.title}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      >
        {props.options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            control={<Radio color="primary" />}
            label={option}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export function CareersPageCheckBox(props) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
          name={props.name}
          defaultChecked={props.defaultChecked}
          color="primary"
        />
      }
      className={props.className}
      label={
        <>
          {props.label}
          {props.required && (
            <Typography
              component="span"
              color={props.disabled ? "textSecondary" : "error"}
            >
              *
            </Typography>
          )}
        </>
      }
    />
  );
}

export function CareersPageCheckBoxSelectionInput(props) {
  const classes = useStyles();
  return (
    <FormControl
      component="fieldset"
      className={
        props.className
          ? `${props.className} ${classes.formControl}`
          : classes.formControl
      }
    >
      <Typography
        variant="subtitle1"
        style={{ fontWeight: "normal" }}
        color={props.disabled ? "textSecondary" : "initial"}
        className={classes.inputLabel}
      >
        {props.title}
        {props.required && (
          <Typography
            component="span"
            color={props.disabled ? "textSecondary" : "error"}
          >
            *
          </Typography>
        )}
      </Typography>
      <FormGroup>
        {props.options.map((option, index) => (
          <FormControlLabel
            key={index}
            control={<Checkbox color="primary" />}
            checked={props.value ? props.value.includes(option) : false}
            onChange={props.onChange}
            value={option}
            label={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
