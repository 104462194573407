import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import JobsApp from "./JobsApp";
import { isJobsDomain } from "./utils/commonHelpers";
import { ProSidebarProvider } from "react-pro-sidebar";

if (window.location.host === "app.reczee.com" || window.location.host === "jobs.reczee.com") {
  Sentry.init({
    dsn: "https://c8bcb50afbd1402f9c1dba5abefaf37a@o1235675.ingest.sentry.io/6385680",
    integrations: [new BrowserTracing()],

    // @Todo(Raj): Better way to configure production/staging. Explore .env
    environment: (window.location.host === "app.reczee.com" || window.location.host === "jobs.reczee.com") ? "production" : "localhost",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

function getMainComponent() {
  if (isJobsDomain()) {
    return <JobsApp />;
  } else {
    return (
      <ProSidebarProvider>
        <App />
      </ProSidebarProvider>
    )
  }
}

ReactDOM.hydrate(<React.StrictMode>{getMainComponent()}</React.StrictMode>, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
