export const socialIconDetails = [
  {
    value: "linkedin",
    title: "LinkedIn",
    extension: "svg",
    placeholder: "https://www.linkedin.com/in/yourusername"
  },
  {
    value: "facebook",
    title: "Facebook",
    extension: "svg",
    placeholder: "https://www.facebook.com/yourusername"
  },
  {
    value: "twitter",
    title: "Twitter",
    extension: "svg",
    placeholder: "https://twitter.com/yourusername"
  },
  {
    value: "instagram",
    title: "Instagram",
    extension: "svg",
    placeholder: "https://instagram.com/yourusername"
  },
  {
    value: "youtube",
    title: "YouTube",
    extension: "svg",
    placeholder: "https://www.youtube.com/channel/channelID"
  },
  {
    value: "github",
    title: "Github",
    extension: "svg",
    placeholder: "https://github.com/yourusername"
  },
  {
    value: "website",
    title: "Website",
    extension: "svg",
    placeholder: "https://yourportfolio.com/"
  },
  {
    value: "dribbble",
    title: "Dribbble",
    extension: "svg",
    placeholder: "https://dribbble.com/yourusername"
  },
  {
    value: "behance",
    title: "Behance",
    extension: "svg",
    placeholder: "https://www.behance.net/yourusername"
  },
  {
    value: "stackoverflow",
    title: "Stack Overflow",
    extension: "svg",
    placeholder: "https://stackoverflow.com/users/profileid/yourusername"
  },
  {
    value: "calendly",
    title: "Calendly",
    extension: "svg",
    placeholder: "https://www.calendly.com/yourusername"
  },
  {
    value: "telegram",
    title: "Telegram",
    extension: "svg",
    placeholder: "https://t.me/yourusername"
  },
  {
    value: "angellist",
    title: "AngelList",
    extension: "svg",
    placeholder: "https://angel.co/u/yourusername"
  },
  {
    value: "quora",
    title: "Quora",
    extension: "png",
    placeholder: "https://www.quora.com/profile/yourusername"
  },
  {
    value: "leetcode",
    title: "Leetcode",
    extension: "png",
    placeholder: "https://leetcode.com/yourusername/"
  },
  {
    value: "stopstalk",
    title: "StopStalk",
    extension: "png",
    placeholder: "https://www.stopstalk.com/user/profile/yourusername"
  },
  {
    value: "geeksforgeeks",
    title: "GeeksForGeeks",
    extension: "svg",
    placeholder: "https://auth.geeksforgeeks.org/user/yourusername"
  },
  {
    value: "codechef",
    title: "Codechef",
    extension: "png",
    placeholder: "https://www.codechef.com/users/yourusername"
  },
  {
    value: "codeforces",
    title: "Codeforces",
    extension: "png",
    placeholder: "https://codeforces.com/profile/yourusername"
  },
  {
    value: "kaggle",
    title: "Kaggle",
    extension: "svg",
    placeholder: "https://kaggle.com/yourusername"
  },
  {
    value: "medium",
    title: "Medium",
    extension: "svg",
    placeholder: "https://medium.com/@yourusername"
  },
  {
    value: "substack",
    title: "Substack",
    extension: "svg",
    placeholder: "https://yourusername.substack.com"
  },
  {
    value: "blogger",
    title: "Blogger",
    extension: "svg",
    placeholder: "https://www.blogger.com/profile/yourusername"
  },
  {
    value: "wordpress",
    title: "Wordpress",
    extension: "svg",
    placeholder: "https://yourusername.wordpress.com/"
  }
];

export const TAB_SWITCHES = "ts";
export const RIGHT_CLICK = "rc";