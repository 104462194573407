import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import { CAREERS_PAGE_CDN_BASE_URL } from "../../constants";
import CareersPageCarousel from "./CareersPageCarousel";

const useStyles = makeStyles((theme) => ({
  careersPageSection: {
    minHeight: "500px",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    // backgroundColor: '#f2f2f245'
    backgroundColor: "white",
    position: "relative",
    zIndex: 1,
  },
  coreValueItemContainer: {
    padding: theme.spacing(3),
  },
  benefitsItemContainer: {
    padding: "10px 40px !important",
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  itemDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

function LifeAtHyperface(props) {
  const classes = useStyles();
  return (
    <div className={classes.careersPageSection}>
      <Typography component="h3" variant="h4" style={{ padding: "16px" }}>
        Life @{props.companyDetails.name}
      </Typography>
      <Grid container spacing={2} style={{ marginTop: "10px" }}>
        <Grid item xs={12} md={4}>
          <img
            style={{ width: "100%" }}
            src={getStaticResourceUrl("/companies/hyperface/life1.jpeg")}
            alt="Life 1"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            style={{ width: "100%" }}
            src={getStaticResourceUrl("/companies/hyperface/life2.jpeg")}
            alt="Life 2"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <img
            style={{ width: "100%", aspectRatio: "16/9" }}
            src={getStaticResourceUrl("/companies/hyperface/life3.jpeg")}
            alt="Life 3"
          />
        </Grid>
      </Grid>
    </div>
  );
}

function LifeAtSapient(props) {
  const classes = useStyles();
  return (
    <div className={classes.careersPageSection}>
      <Typography component="h3" variant="h4" style={{ padding: "16px" }}>
        Life at {props.companyDetails.name}
      </Typography>

      <CareersPageCarousel
        images={[
          `${CAREERS_PAGE_CDN_BASE_URL}/sapient/life-1.jpg`,
          `${CAREERS_PAGE_CDN_BASE_URL}/sapient/life-2.jpg`,
          `${CAREERS_PAGE_CDN_BASE_URL}/sapient/life-3.jpg`,
          `${CAREERS_PAGE_CDN_BASE_URL}/sapient/life-4.jpg`,
          `${CAREERS_PAGE_CDN_BASE_URL}/sapient/life-5.jpg`,
        ]}
      />
    </div>
  );
}

export default function LifeAtCompany(props) {
  if (!props || !props.companyDetails) {
    return "";
  }

  switch (props.companyDetails.slug) {
    case "company75":
    case "hyperface":
    case "stopstalk":
      return <LifeAtHyperface {...props} />;
    case "sapient":
      return <LifeAtSapient {...props} />;
    default:
      return "";
  }
}
