import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { reczeeAlertParams, capitalizeWord } from "../../utils/commonHelpers";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { getRequest } from "../../utils/httpHelpers";
import { Redirect } from "react-router-dom";
import Link from "@material-ui/core/Link";
import amplitudeLogger, { SCREENO_DASHBOARD_PAGE } from "../../utils/amplitude";
import { Skeleton } from "@material-ui/lab";
import CreditsContainer from "./CreditsContainer";
import { UserInfoContext } from "../../contexts/UserInfoContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import ScreenoHowItWorksModal from "./ScreenoHowItWorksModal";
import Tab from "@material-ui/core/Tab";
import { Paper, Box } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import { isTalentHubCandidateLoggedIn } from "../../utils/authHelpers";
import ScreenoCandidateListing from "./ScreenoCandidateListing";
import ScreenoIntegrationsFetchForm from "./ScreenoIntegrationsFetchForm";
import ScreenoUploadResumeContainer from "./ScreenoUploadResumeContainer";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import DescriptionIcon from "@material-ui/icons/Description";

const useStyles = makeStyles((theme) => ({
  tabPanelContainer: {
    width: "100%",
  },
  tabLabelContainer: {
    display: "flex",
    alignItems: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  allCenterDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  displayColumn: {
    display: "flex",
    flexDirection: "column",
  },
  internalContainer: {
    width: "100%",
    display: "flex",
    minHeight: "200px",
    padding: theme.spacing(2),
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  droppingResumeDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "45%",
    marginTop: theme.spacing(-2),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function tabProperties(className, index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
    className: className,
  };
}

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabContainer}
      {...other}
    >
      {value === index && (
        <Box
          key={`div-${index}`}
          className={classes.internalContainer}
          component={Paper}
          elevation={2}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ScreenoDashboard() {
  const classes = useStyles();
  const [tabValue, setTabValue] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [candidates, setCandidates] = React.useState([]);
  const [userInfo] = React.useContext(UserInfoContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [refreshInterval, setRefreshInterval] = React.useState(null);
  const [howItWorksModalOpen, setHowItWorksModalOpen] = React.useState(false);
  const [notificationInfo, setNotificationInfo] =
    React.useContext(NotificationContext);
  const [shouldIntervalBeCancelled, setShouldIntervalBeCancelled] =
    React.useState(false);
  const [pendingCredits, setPendingCredits] = React.useState(null);
  const isCreditsLoading = pendingCredits === null;
  const history = useHistory();
  const isScreenoApprovedUser =
    userInfo.emailVerified && userInfo.companyApproved;
  const [externalAtsProvider, setExternalAtsProvider] = React.useState(null);

  document.title = "Dashboard | Reczee Screeno";

  const fetchAllAgain = React.useCallback(() => {
    if (userInfo.emailVerified && userInfo.companyApproved) {
      setIsLoading(true);
      getRequest(
        "screeno/list-resumes",
        {},
        (data) => {
          setIsLoading(false);
          setCandidates(data.candidates);
          setNotificationInfo((prev) => {
            return { ...prev, pending_credits: data.pending_credits };
          });
          setPendingCredits(data.pending_credits);
          if (
            data.is_new_account &&
            !sessionStorage.getItem("is_new_screeno_account")
          ) {
            sessionStorage.setItem("is_new_screeno_account", "true");
            setHowItWorksModalOpen(true);
          }
        },
        (errorMessages) => {
          setIsLoading(false);
          if (typeof errorMessages === "string") {
            enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
          } else {
            enqueueSnackbar(
              "Something went wrong!",
              reczeeAlertParams("error")
            );
          }
        }
      );
    }
  }, []);

  React.useEffect(() => {
    fetchAllAgain();
    if (!isTalentHubCandidateLoggedIn()) {
      getRequest(
        "screeno/integrations/status",
        {},
        (data) => {
          setExternalAtsProvider(data["external_ats_provider"]);
        },
        (errorMessages) => {
          if (typeof errorMessages === "string") {
            enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
          } else {
            enqueueSnackbar(
              "Something went wrong!",
              reczeeAlertParams("error")
            );
          }
        }
      );
    }

    amplitudeLogger.logEvent("SCREENO_DASHBOARD_LOADED");
  }, []);

  React.useEffect(() => {
    if (refreshInterval && shouldIntervalBeCancelled) {
      clearInterval(refreshInterval);
      setRefreshInterval(null);
    }
  }, [refreshInterval, shouldIntervalBeCancelled]);

  React.useEffect(() => {
    if (
      refreshInterval &&
      candidates &&
      candidates.filter((candidate) => candidate.status === "processing")
        .length === 0
    ) {
      setShouldIntervalBeCancelled(true);
    } else if (
      !refreshInterval &&
      candidates &&
      candidates.filter((candidate) => candidate.status === "processing")
        .length > 0
    ) {
      setShouldIntervalBeCancelled(false);
      setRefreshInterval(
        setInterval(() => {
          fetchAllAgain();
        }, 5000)
      );
    }
  }, [candidates, refreshInterval]);

  if (isTalentHubCandidateLoggedIn()) {
    return <Redirect push to={`/talenthub/dashboard`} />;
  }

  const postResumeUploadSteps = () => {
    setSearchQuery("");
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className={classes.allCenterDiv}>
          <div
            style={{
              marginRight: "16px",
              cursor: "pointer",
            }}
            onClick={() => {
              amplitudeLogger.logEvent("SCREENO_CREDITS_CLICK", {
                page: SCREENO_DASHBOARD_PAGE,
              });
              history.push("/screeno/credits");
            }}
          >
            {isScreenoApprovedUser &&
              (!isCreditsLoading ? (
                <CreditsContainer pendingCredits={pendingCredits} />
              ) : (
                <Skeleton
                  animation="pulse"
                  width={200}
                  height={40}
                  variant="text"
                />
              ))}
          </div>
          <Link
            style={{ cursor: "pointer", marginTop: "4px" }}
            onClick={() => {
              amplitudeLogger.logEvent("SCREENO_HOW_IT_WORKS_CLICKED");
              setHowItWorksModalOpen(true);
            }}
          >
            How it works?
          </Link>
        </div>
        <ScreenoHowItWorksModal
          howItWorksModalOpen={howItWorksModalOpen}
          handleHowItWorksModalClose={() => {
            setHowItWorksModalOpen(false);
          }}
        />
      </div>
      <div className={classes.droppingResumeDiv}>
        <Tabs
          component={Paper}
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          className={classes.tabs}
        >
          <Tab
            label={
              <div className={classes.tabLabelContainer}>
                <DescriptionIcon
                  style={{ marginRight: "8px" }}
                  fontSize="small"
                />
                Manual
              </div>
            }
            onClick={() => {
              amplitudeLogger.logEvent("SCREENO_UPLOAD_FORM_TAB", {
                page: SCREENO_DASHBOARD_PAGE,
                tab: "manual",
              });
            }}
            {...tabProperties(classes.tabItem, 0)}
          />
          <Tab
            label={
              <div className={classes.tabLabelContainer}>
                {!externalAtsProvider || externalAtsProvider === "none" ? (
                  <>
                    <SwapHorizIcon style={{ marginRight: "8px" }} />
                    Integrations
                  </>
                ) : (
                  <>
                    <img
                      alt={capitalizeWord(externalAtsProvider)}
                      height={externalAtsProvider === "lever" ? "24" : "20"}
                      title={capitalizeWord(externalAtsProvider)}
                      style={{ marginRight: "8px" }}
                      src={getStaticResourceUrl(
                        externalAtsProvider === "lever"
                          ? "/screeno/lever-small-logo.png"
                          : "/screeno/greenhouse-small-logo.png"
                      )}
                    />
                    {capitalizeWord(externalAtsProvider)}
                  </>
                )}
              </div>
            }
            onClick={() => {
              amplitudeLogger.logEvent("SCREENO_UPLOAD_FORM_TAB", {
                page: SCREENO_DASHBOARD_PAGE,
                tab: "integrations",
              });
            }}
            {...tabProperties(classes.tabItem, 1)}
          />
        </Tabs>
        <TabPanel
          value={tabValue}
          index={0}
          className={classes.tabPanelContainer}
        >
          <ScreenoUploadResumeContainer
            setCandidates={setCandidates}
            postResumeUploadSteps={postResumeUploadSteps}
            setIsLoading={setIsLoading}
            setPendingCredits={setPendingCredits}
            setShouldIntervalBeCancelled={setShouldIntervalBeCancelled}
          />
        </TabPanel>
        <TabPanel
          value={tabValue}
          className={classes.tabPanelContainer}
          index={1}
        >
          <ScreenoIntegrationsFetchForm
            externalAtsProvider={externalAtsProvider}
          />
        </TabPanel>
      </div>

      <ScreenoCandidateListing
        candidates={candidates}
        setCandidates={setCandidates}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isScreenoApprovedUser={isScreenoApprovedUser}
      />
    </div>
  );
}
