import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4, 3, 3, 4),
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    padding: "4px",
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, description, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.title}>
        <Typography variant="h5" component="h5">
          {children}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
      <Typography
        component="span"
        display="block"
        variant="subtitle2"
        color="textSecondary"
        style={{ marginRight: "8px" }}
      >
        {description}
      </Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: 0,
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

export function ReczeeDialog({ maxWidth = "md", ...props }) {
  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={true}
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitle onClose={props.onClose} description={props.description}>
        {props.title}
      </DialogTitle>
      <DialogContent>{props.content}</DialogContent>
    </Dialog>
  );
}
