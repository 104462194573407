import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Route } from "react-router-dom";
import ReczeeNavbar from "../commons/ReczeeNavbar";
import ReczeeSidebar from "../commons/ReczeeSidebar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  main: {
    width: "100%",
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const NavbarRoute = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <div className={classes.root}>
            <ReczeeSidebar />
            <div className={classes.main}>
              <CssBaseline />
              <ReczeeNavbar />
              <Container
                maxWidth="xl"
                component="main"
                className={classes.paper}
              >
                <Component {...props} />
              </Container>
            </div>
          </div>
        );
      }}
    />
  );
};

export default NavbarRoute;
