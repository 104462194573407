import queryString from "query-string";
import config from "../config.json";

export const getStaticResourceUrl = (path) => {
  return `/react-static${path}`;
};

export const getAbsoluteUrl = (domain, path) => {
  if (domain === "app") {
    domain =
      process.env.NODE_ENV === "production" ? "app.reczee.com" : "localhost";
  } else if (domain === "www") {
    domain =
      process.env.NODE_ENV === "production" ? "www.reczee.com" : "localhost";
  } else {
    domain = window.location.host;
  }
  return `${window.location.protocol}//${domain}${path}`;
};

export const generateApiUrl = (path, params = {}) => {
  // const baseUrl = new URL(`${config.apiUrl}/api/${config.apiVersion}/${path}`);
  const baseUrl = new URL(
    `${window.location.protocol}//${window.location.host}/api/${config.apiVersion}/${path}`
  );

  for (var key in params) {
    baseUrl.searchParams.append(key, params[key]);
  }
  return baseUrl.href;
};

export const getQueryParams = () => {
  return queryString.parse(window.location.search);
};

export const CurrentRelativeUrl = () => {
  return window.location.pathname + window.location.search;
};

export const createQueryString = (params = {}) => {
  return new URLSearchParams(params).toString();
};
