import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";

export default function LabelWithIcon({ startIcon, label, button, onClick }) {
  return button ? (
    <Button
      size="small"
      variant="text"
      color="primary"
      onClick={onClick}
      startIcon={startIcon}
    >
      {label}
    </Button>
  ) : (
    <Grid container spacing={1}>
      <Grid item>{startIcon}</Grid>
      <Grid item>
        <Typography
          variant="body1"
          color="textSecondary"
          noWrap
          style={{ maxWidth: "300px" }}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
}
