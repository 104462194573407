export const FeatureFlag = {
  is_enabled: (notificationInfo, kind, featureName) => {
    return (
      notificationInfo &&
      notificationInfo.feature_details &&
      notificationInfo.feature_details[kind] &&
      notificationInfo.feature_details[kind][featureName]
    );
  },
};

export const CTC_NP_REQUIRED_COMPANIES = [327];
export const CTC_REQUIRED_COMPANIES = [569];
