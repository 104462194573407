import { Badge, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const PROFICIENCY_LEVELS = [
  {
    label: "",
  },
  {
    label: "",
    backgroundColor: "#9DBBD5",
    right: "24px",
  },
  {
    label: "",
    backgroundColor: "#75acdd",
    right: "32px",
  },
  {
    label: "",
    backgroundColor: "#3d84c6",
    right: "26px",
  },
  {
    label: "",
    backgroundColor: "#1b558b",
    right: "32px",
  },
];

const useStyles = makeStyles((theme) => ({
  basicSkillChip: {
    marginRight: "16px !important",
  },
  partiallyPassedSkillChip: {
    backgroundImage: "linear-gradient(to right, #c8e6c9, #e0e0e0, #e0e0e0)",
    border: "1px solid #e6e6e6",
    borderRadius: "6px",
    height: "26px",
    paddingRight: "4px",
    paddingLeft: "4px",
  },
  skillChip: {
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
    borderRadius: "6px",
    height: "26px",
    paddingRight: "4px",
    paddingLeft: "4px",
    "& .MuiChip-label": {
      paddingTop: "4px",
      paddingBottom: "4px",
      fontSize: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "6px",
      paddingLeft: "6px",
      marginRight: theme.spacing(2),
      "& .MuiChip-label": {
        fontSize: "10px",
        paddingRight: "3px",
        paddingLeft: "3px",
        paddingTop: "3px",
        paddingBottom: "3px",
      },
    },
  },
  skillBadge: {
    marginTop: "2px",
    marginBottom: "2px",
    "& .MuiBadge-badge": {
      borderRadius: "2px",
      fontSize: "8px",
      height: "14px",
      right: (props) => {
        return props.proficiency === -1
          ? "24px"
          : PROFICIENCY_LEVELS[props.proficiency || 0].right;
      },
      top: "7px",
      color: "white",
      backgroundColor: (props) => {
        return props.proficiency === -1
          ? "#ffffff00"
          : PROFICIENCY_LEVELS[props.proficiency || 0].backgroundColor;
      },
    },
    [theme.breakpoints.down("sm")]: {
      // marginTop: theme.spacing(1),
      "& .MuiBadge-badge": {
        top: "7px",
        right: "18px",
        height: "11px",
        fontSize: "6px",
      },
      // top: '-4px',
      // right: '24px',
      // fontSize: '5px',
      // right: '20px',
      // top: '6px',
      // fontSize: '6px'
    },
  },
  beginnerSkillBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#75acdd",
    },
  },
  intermediateSkillBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#3d84c6",
    },
  },
  expertSkillBadge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#1b558b",
    },
  },
  assessmentPassedSkillChip: {
    // backgroundColor: '#A5D6A7',
    backgroundColor: "#c8e6c9",
    color: "#164b01",
  },
  hoverable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export function SkillBadge(props) {
  const classes = useStyles(props);
  let badgeClasses = classes.skillChip;
  if (props.hoverable) {
    badgeClasses += " " + classes.hoverable;
  }
  if (props.proficiency !== undefined && props.proficiency > 0) {
    badgeClasses += " " + classes.assessmentPassedSkillChip;
  } else {
    badgeClasses += " " + classes.basicSkillChip;
  }

  if (props.partiallyPassed) {
    badgeClasses += " " + classes.partiallyPassedSkillChip;
    return <Chip label={props.name} className={badgeClasses} />;
  }

  const proficiencyData = PROFICIENCY_LEVELS[props.proficiency || 0];
  if (proficiencyData.label) {
    return (
      <Badge
        badgeContent={proficiencyData.label}
        className={classes.skillBadge}
      >
        <Chip label={props.name} className={badgeClasses} />
      </Badge>
    );
  } else {
    return <Chip label={props.name} className={badgeClasses} />;
  }
}
