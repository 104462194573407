import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/SwipeableDrawer";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { ReczeeAutoComplete } from "../../commons/ReczeeInputField";

const useStyles = makeStyles((theme) => ({
  list: {
    minWidth: "350px",
    marginTop: theme.spacing(2),
  },
  fullList: {
    width: "auto",
  },
  listItem: {
    padding: "30px",
    justifyContent: "center",
  },
}));

export default function FilterBar(props) {
  const classes = useStyles();

  const toggleDrawer = (open) => (event) => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    props.setFiltersOpen(open);
  };

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={props.filtersOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <div className={classes.list} role="presentation">
            <Typography variant="h6" gutterBottom align="center">
              Filters
            </Typography>
            <List>
              <ListItem className={classes.listItem}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Internship / Full Time
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.type}
                    label="intern_or_fulltime"
                    onChange={props.handleJobTypeChange}
                  >
                    <MenuItem value="All" selected={true}>
                      All
                    </MenuItem>
                    <MenuItem value="Internship">Internship</MenuItem>
                    <MenuItem value="Full-time">Full-time</MenuItem>
                  </Select>
                </FormControl>
              </ListItem>
              <Divider />
              <ListItem className={classes.listItem}>
                <div style={{ width: "90%" }}>
                  <Typography
                    gutterBottom
                    style={{ color: "rgba(0, 0, 0, 0.54)" }}
                  >
                    Experience (years)
                  </Typography>
                  <Slider
                    getAriaValueText={(value) => value}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay={true}
                    step={1}
                    marks={[0, 1, 2, 3, 4, 5, 6].map((num) => {
                      return { label: num > 5 ? "5+" : num, value: num };
                    })}
                    min={0}
                    max={6}
                    onChange={props.handleExpChange}
                    value={props.experience}
                  />
                </div>
              </ListItem>
              <Divider />
              <ListItem className={classes.listItem}>
                <ReczeeAutoComplete
                  className={classes.inputField}
                  groupBy={(skill) => {
                    return skill.category;
                  }}
                  fullWidth={"200px"}
                  sortBy={(a, b) => -b.category.localeCompare(a.category)}
                  onChange={props.handleSkillsChange}
                  value={props.skills || []}
                  label="Skills"
                  limitTags={15}
                  dataFetchPath="talenthub/skills"
                  responseMapper={(response) => response.skills}
                  getOptionLabel={(option) => option.name}
                />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
