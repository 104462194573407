import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Card,
  CardMedia,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const WIDTH_OF_ONE_CARD = 450;
const WIDTH_OF_ONE_CARD_MOBILE = 330;

const HEIGHT_OF_ONE_CARD = 300;
const HEIGHT_OF_ONE_CARD_MOBILE = 220;

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    position: "relative",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: "100%",
    // maxWidth: "1200px",
    margin: "32px auto 0",
    overflow: "hidden",
  },
  carousel: {
    display: "flex",
    transition: "transform 0.5s ease",
  },
  carouselCard: {
    minWidth: `${WIDTH_OF_ONE_CARD}px`,
    [theme.breakpoints.down("sm")]: {
      minWidth: `${WIDTH_OF_ONE_CARD_MOBILE}px`,
    },
    margin: "0 20px",
    borderRadius: "8px",
  },
  carouselCardMedia: {
    height: `${HEIGHT_OF_ONE_CARD}px`,
    [theme.breakpoints.down("sm")]: {
      height: `${HEIGHT_OF_ONE_CARD_MOBILE}px`,
    },
  },
  carouselControls: {
    display: "flex",
    justifyContent: "end",
    marginTop: theme.spacing(2),
    width: "100%",
  },
  iconButton: {
    margin: theme.spacing(1),
    borderRadius: "50%",
    border: "1px solid #EAECF0",
  },
}));

function CareersPageCarousel(props) {
  const classes = useStyles();
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const [visibleCarouselCards, setVisibleCarouselCards] = useState(4);
  const carouselCards = props.images;
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchMoveX, setTouchMoveX] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const handleResizeScreen = () => {
      if (window.innerWidth < 600) {
        setVisibleCarouselCards(1);
      } else if (window.innerWidth < 1500) {
        setVisibleCarouselCards(2);
      } else {
        setVisibleCarouselCards(3);
      }
    };

    window.addEventListener("resize", handleResizeScreen);
    handleResizeScreen();

    return () => window.removeEventListener("resize", handleResizeScreen);
  }, []);

  const handleNextCarousel = () => {
    setCurrentCarouselIndex((prevIndex) =>
      Math.min(prevIndex + 1, carouselCards.length - visibleCarouselCards)
    );
  };

  const handlePrevCarousel = () => {
    setCurrentCarouselIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchMoveX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    const touchDifference = touchStartX - touchMoveX;

    if (touchDifference > 50) {
      handleNextCarousel();
    } else if (touchDifference < -50) {
      handlePrevCarousel();
    }

    setTouchStartX(0);
    setTouchMoveX(0);
  };

  return (
    <>
      <Box className={classes.carouselContainer}>
        <Box
          className={classes.carousel}
          style={{
            transform: `translateX(-${
              currentCarouselIndex *
              ((isMobile ? WIDTH_OF_ONE_CARD_MOBILE : WIDTH_OF_ONE_CARD) + 40)
            }px)`,
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {carouselCards.map((item, index) => (
            <Card key={index} className={classes.carouselCard}>
              <CardMedia className={classes.carouselCardMedia} image={item} />
            </Card>
          ))}
        </Box>
      </Box>
      <Box className={classes.carouselControls}>
        <IconButton
          className={classes.iconButton}
          onClick={handlePrevCarousel}
          disabled={currentCarouselIndex === 0}
        >
          <ArrowBackIcon />
        </IconButton>
        <IconButton
          className={classes.iconButton}
          onClick={handleNextCarousel}
          disabled={
            currentCarouselIndex >= carouselCards.length - visibleCarouselCards
          }
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    </>
  );
}

export default CareersPageCarousel;
