import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getStaticResourceUrl } from "../../utils/urlHelpers";

const useStyles = makeStyles((theme) => ({
  creditAmountContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  creditIcon: {
    width: "30px",
    height: "30px",
    marginRight: "8px",
  },
}));

export default function CreditsContainer(props) {
  const classes = useStyles();

  return (
    <div className={classes.creditAmountContainer}>
      <img
        alt="credit"
        src={getStaticResourceUrl("/new-gold-credit.svg")}
        className={classes.creditIcon}
      />
      <Typography component="span" variant="h5">
        {props.pendingCredits} credits
      </Typography>
    </div>
  );
}
