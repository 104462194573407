import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  mainSubHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    // fontWeight: 'bold',
    fontSize: '20px'
  },
  subheadingContainer: {
    width: '80%',
    [theme.breakpoints.up('md')]: {
      width: '60%',
    }
  }
}));

export default function CareersPageSubheading(props) {
  const classes = useStyles();
  if (!props || !props.companyDetails) {
    return "";
  }

  let text = "";
  switch (props.companyDetails.slug) {
    case "company75":
      text = "We make recruitment simple for everyone";
      break;
    case "hyperface":
      text = "Hiring the best problem solvers and thinkers to build Hyperface!";
      break;
    // case "sapient":
    //   text = "Come join us to relieve developers from the tedious task of writing tests.";
    //   break;
    default:
      text = "";
  }

  return <div className={classes.subheadingContainer}>
    <Typography component="h2" variant="h6" className={classes.mainSubHeading}>{text}</Typography>
  </div>;
}