
export const authPageAvatar = (theme) => {
  return {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center'
  };
};

export const formSubmitButton = (theme) => {
  return {
    margin: theme.spacing(2, 0, 1),
    padding: '8px 24px'
  };
};