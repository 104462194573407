import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { SkillBadge } from "./SkillBadge";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableContainer: {
    width: "80%",
  },
  exampleSkillsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
  skillBadgeRow: {
    marginTop: theme.spacing(2),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, description, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <>{description}</>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function SkillsHowItWorksModal(props) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={props.handleHowItWorksModalClose}
        open={props.howItWorksModalOpen}
      >
        <DialogTitle
          onClose={props.handleHowItWorksModalClose}
          className={classes.dialogTitleCenter}
        >
          How it works?
        </DialogTitle>
        <DialogContent className={classes.exampleSkillsContainer}>
          <TableContainer className={classes.tableContainer}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Badge</TableCell>
                  <TableCell align="left">What it means?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <SkillBadge name={"Skill"} proficiency={0} />
                  </TableCell>
                  <TableCell align="left">
                    Candidate has added the skill in their profile
                    (not-validated).
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <SkillBadge name={"Skill"} proficiency={1} />
                  </TableCell>
                  <TableCell align="left">
                    Candidate has taken an online assessment and passed
                    successfully.
                  </TableCell>
                </TableRow>
                {/* <TableRow>
                <TableCell><SkillBadge name={"Skill"} proficiency={2} /></TableCell>
                <TableCell align="left">Reczee Experts conducted a video interview and rated the candidate as beginner.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><SkillBadge name={"Skill"} proficiency={3} /></TableCell>
                <TableCell align="left">Reczee Experts conducted a video interview and rated the candidate as intermediate.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><SkillBadge name={"Skill"} proficiency={4} /></TableCell>
                <TableCell align="left">Reczee Experts conducted a video interview and rated the candidate as expert.</TableCell>
              </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div className={classes.skillBadgeRow}>
          <SkillBadge name={"Skill"} proficiency={0} />
        </div>
        <div className={classes.skillBadgeRow}>
          <SkillBadge name={"Skill"} proficiency={1} />
        </div>
        <div className={classes.skillBadgeRow}>
          <SkillBadge name={"Skill"} proficiency={2} />
        </div>
        <div className={classes.skillBadgeRow}>
          <SkillBadge name={"Skill"} proficiency={3} />
        </div>
        <div className={classes.skillBadgeRow}>
          <SkillBadge name={"Skill"} proficiency={4} />
        </div> */}
        </DialogContent>
      </Dialog>
    </>
  );
}
