import { makeStyles } from "@material-ui/core/styles";
import {
  reczeeAlertParams,
  getFormattedGradLabel,
} from "../../utils/commonHelpers";
import { Description } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { Button, Typography, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import FilterListIcon from "@material-ui/icons/FilterList";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import { socialIconDetails } from "../talenthub/candidate/TalenthubCandidateConstants";
import { padZero, capitalizeWord } from "../../utils/commonHelpers";
import { downloadFilePostRequest, getRequest } from "../../utils/httpHelpers";
import amplitudeLogger from "../../utils/amplitude";
import {
  ReczeeInputField,
  ReczeeAutoComplete,
} from "../commons/ReczeeInputField";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Drawer from "@material-ui/core/Drawer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { MailOutlined, PhoneOutlined } from "@material-ui/icons";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import { Skeleton } from "@material-ui/lab";
import ScreenoTagBadge from "./ScreenoTagBadge";
import ViewScreenoResumeModal from "./ViewScreenoResumeModal";

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    borderRadius: "10px",
    overflow: "scroll",
    marginTop: theme.spacing(2),
    width: "100%",
    minHeight: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  tagHeaderCell: {
    cursor: "pointer",
  },
  filterRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  blurDiv: {
    opacity: 0.2,
    background: "white",
    pointerEvents: "none",
  },
  clearFilterButton: {
    cursor: "pointer",
    marginRight: "4px",
    color: theme.palette.primary.main,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  submitFilter: {
    marginTop: theme.spacing(2),
  },
  filterSidebarContainer: {
    width: "450px",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
    padding: theme.spacing(2),
    textAlign: "center",
  },
  filterInputField: {
    textAlign: "left",
    marginTop: theme.spacing(3),
    "& .MuiChip-root": {
      height: "24px",
    },
    "& .MuiChip-deleteIcon": {
      width: "18px",
    },
  },
  sortIcon: {
    marginLeft: "4px",
    marginTop: "4px",
  },
  contactDetailsHeaderCell: {
    minWidth: "200px",
  },
  contactLabel: {
    marginLeft: "6px",
    color: "dimgrey",
  },
  emailPhoneContainer: {
    marginTop: "4px",
  },
  flagIcon: {
    color: "lightgray",
    cursor: "pointer",
  },
  socialIcon: {
    height: "16px",
    marginRight: "2px",
    marginLeft: "2px",
  },
  contactDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  socialIconContainer: {
    minWidth: "100px",
  },
  filterList: {
    width: "fit-content",
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  minYearInputField: {
    minWidth: "unset !important",
  },
  maxYearInputField: {
    marginLeft: theme.spacing(2),
    minWidth: "unset !important",
  },
  filterButton: {
    height: "100%",
    minWidth: "100px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  filterIcon: {
    minWidth: "33px",
    paddingRight: "10px",
  },
  resumeFileNameText: {
    fontSize: "12px",
    marginTop: "4px",
    color: "#a7a7a7",
    maxWidth: "180px",
    whiteSpace: "normal",
    marginLeft: "auto",
    marginRight: "auto",
  },
  tagsCell: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  parseErrorContainer: {
    color: "#a7a7a7",
  },
  statusBadge: {
    marginTop: "4px",
  },
  topContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  sampleChip: {
    width: "62px",
    marginLeft: "6px",
    height: "20px",
    borderRadius: "3px",
    fontSize: "12px",
  },
  screenoCandidateName: {
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

function LabelWithIcon({ startIcon, label, button, onClick }) {
  const classes = useStyles();
  return (
    <Grid container spacing={0}>
      <Grid item>{startIcon}</Grid>
      <Grid item className={classes.contactLabel}>
        {label}
      </Grid>
    </Grid>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const MIN_GRAD_YEAR = 2000;
const MAX_GRAD_YEAR_DELTA_FROM_TODAY = 10;
const DEFAULT_FILTERS = {
  graduation_year: [null, null],
  post_graduation_year: [null, null],
};

const headCells = [
  {
    id: "contact_details",
    align: "left",
    disablePadding: false,
    label: "Contact Details",
  },
  { id: "links", align: "center", disablePadding: false, label: "Links" },
  {
    id: "tags",
    align: "left",
    disablePadding: false,
    label: "Tags",
    sortable: true,
  },
  {
    id: "resume",
    align: "center",
    disablePadding: false,
    label: "",
  },
];

function descendingComparator(a, b, orderBy) {
  let left = a[orderBy];
  let right = b[orderBy];

  if (orderBy === "score") {
    if (left === right) {
      left = a["error"] ? -1 : a["tags"]?.length;
      right = b["error"] ? -1 : b["tags"]?.length;
    }
  }

  if (orderBy === "tags") {
    left = a["error"] ? -1 : a["tags"]?.length;
    right = b["error"] ? -1 : b["tags"]?.length;
  }

  if (orderBy === "state") {
    left = a[orderBy]?.state;
    right = b[orderBy]?.state;
  }

  if (right < left) {
    return -1;
  }
  if (right > left) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const getScreenoFinalScore = (matchScore) => {
  matchScore = matchScore === null ? -1 : parseFloat(matchScore);
  if (matchScore === -1) {
    return -1;
  } else if (matchScore <= 6) {
    return 0;
  } else if (matchScore <= 8) {
    return 1;
  } else {
    return 2;
  }
};

function getSmallLogoPath(atsProvider) {
  if (atsProvider === "greenhouse") {
    return "/screeno/greenhouse-small-logo.png";
  } else if (atsProvider === "lever") {
    return "/screeno/lever-small-logo.png";
  } else if (atsProvider === "reczee_ats") {
    return "/screeno/reczee-small-logo.svg";
  }
}

const getRowsFromCandidates = (candidates) => {
  return candidates.map((row) => {
    return {
      name: row.contact_details?.name,
      phone_number: row.contact_details?.phone_number,
      email: row.contact_details?.email,
      filename: row.filename,
      resume_url: row.resume_url,
      graduation_year: row.graduation_year,
      status: row.status,
      post_graduation_year: row.post_graduation_year,
      tags: row.tags,
      error: row.error,
      sample: row.sample,
      links: row.links,
      ats_provider: row.ats_provider,
      id: row.id,
    };
  });
};

function FilterSidebar({
  filterSidebarOpen,
  setFilterSidebarOpen,
  filters,
  setFilters,
}) {
  const classes = useStyles();
  const [formFilters, setFormFilters] = React.useState(filters || {});

  React.useEffect(() => {
    setFormFilters(filters);
  }, [filters]);

  const submitCandidateFilter = (e) => {
    e.preventDefault();
    setFilters(formFilters);
    setFilterSidebarOpen(false);
  };

  return (
    <Drawer
      anchor={"right"}
      open={filterSidebarOpen}
      style={{ width: "200px" }}
      onClose={() => {
        setFilterSidebarOpen(false);
      }}
    >
      <div className={classes.filterSidebarContainer} role="presentation">
        <Typography variant="h6" gutterBottom align="center">
          Filters
        </Typography>
        <form onSubmit={submitCandidateFilter}>
          <ReczeeAutoComplete
            className={classes.filterInputField}
            size="small"
            values={[
              { label: "Top Company", status: "Top Company" },
              { label: "Top Institute", status: "Top Institute" },
              {
                label: "Open Source Enthusiast",
                status: "Open Source Enthusiast",
              },
              {
                label: "Top Competitive Programmer",
                status: "Top Competitive Programmer",
              },
              { label: "Hackathon Winner", status: "Hackathon Winner" },
              {
                label: "Research Paper Author",
                status: "Research Paper Author",
              },
              { label: "No tags", status: "No tags" },
            ]}
            value={formFilters["tags"]}
            onChange={(_, values) => {
              setFormFilters({ ...formFilters, tags: values });
            }}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => {
                return (
                  <div key={index} style={{ margin: "3px" }}>
                    <ScreenoTagBadge {...option} {...getTagProps({ index })} />
                  </div>
                );
              });
            }}
            renderOption={(option) => (
              <ScreenoTagBadge style={{ margin: "4px" }} {...option} />
            )}
            title={"Screeno tags"}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) =>
              value && value.status === option.status
            }
          />
          <div className={classes.filterInputField}>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              Graduation year
            </Typography>
            <div style={{ display: "flex" }}>
              <ReczeeInputField
                size="small"
                type="number"
                fullWidth
                className={classes.minYearInputField}
                value={formFilters["graduation_year"][0]}
                onChange={(e) => {
                  setFormFilters({
                    ...formFilters,
                    graduation_year: [
                      e.target.value,
                      formFilters.graduation_year[1],
                    ],
                  });
                }}
                placeholder="Minimum"
                inputProps={{
                  step: "1",
                  min: MIN_GRAD_YEAR,
                  max:
                    new Date().getFullYear() + MAX_GRAD_YEAR_DELTA_FROM_TODAY,
                }}
              />
              <ReczeeInputField
                size="small"
                type="number"
                fullWidth
                className={classes.maxYearInputField}
                value={formFilters["graduation_year"][1]}
                onChange={(e) => {
                  setFormFilters({
                    ...formFilters,
                    graduation_year: [
                      formFilters.graduation_year[0],
                      e.target.value,
                    ],
                  });
                }}
                placeholder="Maximum"
                inputProps={{
                  step: "1",
                  min: formFilters.graduation_year[0] || MIN_GRAD_YEAR,
                  max:
                    new Date().getFullYear() + MAX_GRAD_YEAR_DELTA_FROM_TODAY,
                }}
              />
            </div>
          </div>
          <div className={classes.filterInputField}>
            <Typography variant="subtitle1" style={{ fontWeight: "normal" }}>
              Post graduation year
            </Typography>
            <div style={{ display: "flex" }}>
              <ReczeeInputField
                size="small"
                type="number"
                fullWidth
                className={classes.minYearInputField}
                value={formFilters["post_graduation_year"][0]}
                onChange={(e) => {
                  setFormFilters({
                    ...formFilters,
                    post_graduation_year: [
                      e.target.value,
                      formFilters.post_graduation_year[1],
                    ],
                  });
                }}
                placeholder="Minimum"
                inputProps={{
                  step: "1",
                  min: MIN_GRAD_YEAR,
                  max:
                    new Date().getFullYear() + MAX_GRAD_YEAR_DELTA_FROM_TODAY,
                }}
              />
              <ReczeeInputField
                size="small"
                type="number"
                fullWidth
                className={classes.maxYearInputField}
                value={formFilters["post_graduation_year"][1]}
                onChange={(e) => {
                  setFormFilters({
                    ...formFilters,
                    post_graduation_year: [
                      formFilters.post_graduation_year[0],
                      e.target.value,
                    ],
                  });
                }}
                placeholder="Maximum"
                inputProps={{
                  step: "1",
                  min: formFilters.post_graduation_year[0] || MIN_GRAD_YEAR,
                  max:
                    new Date().getFullYear() + MAX_GRAD_YEAR_DELTA_FROM_TODAY,
                }}
              />
            </div>
          </div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            // disabled={isFetching}
            className={classes.submitFilter}
          >
            Filter
          </Button>
        </form>
      </div>
    </Drawer>
  );
}

const getFilteredCandidates = (filters, candidates) => {
  let newCandidates = [];

  candidates.forEach((candidate) => {
    let currResult = true;
    if (filters["score"] && filters["score"].length > 0) {
      if (
        filters["score"].filter(
          (thisScore) =>
            getScreenoFinalScore(candidate.score) === thisScore.score
        ).length === 0
      ) {
        currResult &= false;
      }
    }

    if (filters["tags"] && filters["tags"].length > 0) {
      if (
        !filters["tags"].every((tag) => candidate.tags.indexOf(tag.status) > -1)
      ) {
        currResult &= false;
      }
    }

    if (filters["graduation_year"][0] || filters["graduation_year"][1]) {
      let minGraduationYear = filters["graduation_year"][0] || MIN_GRAD_YEAR;
      let maxGraduationYear =
        filters["graduation_year"][1] ||
        new Date().getFullYear() + MAX_GRAD_YEAR_DELTA_FROM_TODAY;
      if (
        candidate.graduation_year &&
        minGraduationYear <= candidate.graduation_year &&
        candidate.graduation_year <= maxGraduationYear
      ) {
        currResult &= true;
      } else {
        currResult &= false;
      }
    }

    if (
      filters["post_graduation_year"][0] ||
      filters["post_graduation_year"][1]
    ) {
      let minGraduationYear =
        filters["post_graduation_year"][0] || MIN_GRAD_YEAR;
      let maxGraduationYear =
        filters["post_graduation_year"][1] ||
        new Date().getFullYear() + MAX_GRAD_YEAR_DELTA_FROM_TODAY;
      if (
        candidate.post_graduation_year &&
        minGraduationYear <= candidate.post_graduation_year &&
        candidate.post_graduation_year <= maxGraduationYear
      ) {
        currResult &= true;
      } else {
        currResult &= false;
      }
    }

    if (currResult) {
      newCandidates.push(candidate);
    }
  });
  return newCandidates;
};

export default function ScreenoCandidateListing({
  candidates,
  setCandidates,
  isLoading,
  setIsLoading,
  screenoJobId,
  searchQuery,
  setSearchQuery,
  isScreenoApprovedUser,
}) {
  const classes = useStyles();
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(null);
  const [viewResumeModalOpen, setViewResumeModalOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [filters, setFilters] = React.useState(DEFAULT_FILTERS);
  const [filterSidebarOpen, setFilterSidebarOpen] = React.useState(false);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const rows = getRowsFromCandidates(candidates);
  const filteredCandidates = getFilteredCandidates(filters, rows);
  let currentSortedCandidates = stableSort(
    filteredCandidates,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleViewResumeModalOpen = (index) => () => {
    setSelectedRowIndex(index);
    setViewResumeModalOpen(true);
  };

  const handleViewResumeModalClose = () => {
    setSelectedRowIndex(null);
    setViewResumeModalOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (e, inputValue) => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);

    amplitudeLogger.logEvent("SCREENO_BOOLEAN_SEARCH", {
      query: inputValue,
    });

    getRequest(
      "screeno/smart-search",
      { q: inputValue, screeno_job_id: screenoJobId },
      (data) => {
        setCandidates(data.candidates);
        setIsLoading(false);
      },
      (errorMessages, statusCode) => {
        setIsLoading(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong", reczeeAlertParams("error"));
        }
      }
    );
  };

  // const reportDiscrepancyHandler = (
  //   index,
  //   screenoResumeId,
  //   discrepancyReported
  // ) => {
  //   postRequest(
  //     "screeno/report-discrepancy",
  //     {},
  //     {
  //       screeno_resume_id: screenoResumeId,
  //       discrepancy_reported: discrepancyReported,
  //     },
  //     (data) => {
  //       setCandidates(
  //         candidates.map((candidate) => {
  //           if (candidate.id === screenoResumeId) {
  //             candidate.discrepancy_reported = discrepancyReported;
  //           }
  //           return candidate;
  //         })
  //       );
  //     },
  //     (errorMessages) => {
  //       if (typeof errorMessages === "string") {
  //         enqueueSnackbar(errorMessages, reczeeAlertParams("warning"));
  //       } else {
  //         enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
  //       }
  //     }
  //   );
  // };

  const downloadFile = React.useCallback(() => {
    amplitudeLogger.logEvent("SCREENO_DOWNLOAD_XLSX", {
      count: candidates.length,
    });
    downloadFilePostRequest(
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "screeno/download-xlsx",
      {},
      { screeno_resume_ids: candidates.map((candidate) => candidate.id) },
      (response) => {
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        const currentTime = new Date();
        link.href = href;
        link.setAttribute(
          "download",
          `reczee-screeno-${padZero(currentTime.getMonth() + 1)}${padZero(
            currentTime.getDate()
          )}-${padZero(currentTime.getHours())}${padZero(
            currentTime.getMinutes()
          )}${padZero(currentTime.getSeconds())}.xlsx`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      },
      (errorMessages) => {
        enqueueSnackbar("Not enough data!", reczeeAlertParams("error"));
      }
    );
  }, [candidates]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {isScreenoApprovedUser ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "8px",
          }}
        >
          <div className={classes.topContainer}>
            <TextField
              value={searchQuery}
              label="Boolean Search"
              placeholder="react AND (bengaluru OR mumbai)"
              helperText={
                <>
                  Use <b>AND</b>, <b>OR</b> and <b>()</b> to refine results
                </>
              }
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSearch(e, searchQuery);
                }
              }}
              InputProps={{
                endAdornment: searchQuery && (
                  <ClearIcon
                    style={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => {
                      setSearchQuery("");
                      handleSearch(null, "");
                    }}
                  />
                ),
              }}
              onChange={(event) => {
                setSearchQuery(event.target.value);
              }}
              style={{ marginRight: "12px" }}
              inputProps={{
                style: { width: "340px" },
              }}
            />
            <div style={{ display: "inline-flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {Object.entries(filters).some(([key, value]) => {
                  if (
                    key === "graduation_year" ||
                    key === "post_graduation_year"
                  ) {
                    return !(value[0] === null && value[1] === null);
                  } else if (typeof value === "object") {
                    return value && value.flat().length !== 0;
                  } else if (typeof value === "boolean") {
                    return value;
                  } else {
                    throw new Error("Invalid type");
                  }
                }) ? (
                  <span
                    className={classes.clearFilterButton}
                    onClick={() => {
                      setFilters(DEFAULT_FILTERS);
                    }}
                  >
                    Clear filters
                  </span>
                ) : (
                  ""
                )}
                <List className={classes.filterList} aria-label="Job filters">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="filter-menu"
                    aria-label="Filter"
                    className={classes.filterButton}
                    onClick={() => {
                      setFilterSidebarOpen(true);
                    }}
                  >
                    <ListItemIcon className={classes.filterIcon}>
                      <FilterListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Filter" />
                  </ListItem>
                </List>
                <IconButton
                  size="small"
                  className={classes.downloadButton}
                  onClick={downloadFile}
                  disabled={!(candidates && candidates.length > 0)}
                >
                  <GetAppIcon className={classes.downloadIcon} />
                </IconButton>
                <FilterSidebar
                  filterSidebarOpen={filterSidebarOpen}
                  setFilterSidebarOpen={setFilterSidebarOpen}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            </div>
          </div>
          <div className={classes.tableContainer}>
            {currentSortedCandidates && currentSortedCandidates.length > 0 ? (
              <>
                <TableContainer component={Paper}>
                  <Table>
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {currentSortedCandidates.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {row.status === "processing" ? (
                              <Skeleton
                                animation="pulse"
                                width={200}
                                variant="text"
                              />
                            ) : (
                              <div className={classes.contactDetails}>
                                <div>
                                  <b className={classes.screenoCandidateName}>
                                    {row.name}{" "}
                                    {row.sample ? (
                                      <Chip
                                        className={classes.sampleChip}
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label="Sample"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </b>
                                  <div className={classes.emailPhoneContainer}>
                                    <LabelWithIcon
                                      startIcon={
                                        <MailOutlined fontSize="small" />
                                      }
                                      label={row ? row.email || "-" : "-"}
                                    />
                                    <LabelWithIcon
                                      startIcon={
                                        <PhoneOutlined fontSize="small" />
                                      }
                                      label={
                                        row ? row.phone_number || "-" : "-"
                                      }
                                    />
                                    <LabelWithIcon
                                      startIcon={
                                        <img
                                          src={getStaticResourceUrl(
                                            "/grad-cap.svg"
                                          )}
                                          alt="Grad"
                                        />
                                      }
                                      label={getFormattedGradLabel(
                                        row.graduation_year,
                                        row.post_graduation_year
                                      )}
                                    />
                                  </div>
                                </div>
                                <div>
                                  {row.ats_provider && (
                                    <img
                                      alt={capitalizeWord(row.ats_provider)}
                                      height="24"
                                      title={capitalizeWord(row.ats_provider)}
                                      src={getStaticResourceUrl(
                                        getSmallLogoPath(row.ats_provider)
                                      )}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.socialIconContainer}
                          >
                            {row.links
                              ? socialIconDetails.map((iconDetails, index) => {
                                  let lowerTitle = iconDetails.value;
                                  if (row.links[lowerTitle]) {
                                    return (
                                      <a
                                        key={index}
                                        rel="noreferrer"
                                        href={row.links[lowerTitle]}
                                        target="_blank"
                                      >
                                        <img
                                          className={classes.socialIcon}
                                          src={getStaticResourceUrl(
                                            `/social-icons/${lowerTitle}.${iconDetails.extension}`
                                          )}
                                          alt={lowerTitle}
                                        />
                                      </a>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                              : ""}
                          </TableCell>
                          <TableCell>
                            {row.error ? (
                              row.error
                            ) : row.tags && row.tags.length > 0 ? (
                              row.tags.map((tag, index) => {
                                return (
                                  <ScreenoTagBadge key={index} status={tag} />
                                );
                              })
                            ) : row.status === "processing" ? (
                              <Skeleton
                                animation="pulse"
                                width={200}
                                variant="text"
                              />
                            ) : (
                              <ScreenoTagBadge status={"No tags"} />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              type="submit"
                              variant="outlined"
                              color="primary"
                              size={"small"}
                              onClick={handleViewResumeModalOpen(index)}
                              startIcon={<Description />}
                            >
                              View Resume
                            </Button>
                            <div className={classes.resumeFileNameText}>
                              {row.filename}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[50, 100]}
                    component="div"
                    count={filteredCandidates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </div>
                <ViewScreenoResumeModal
                  selectedRow={
                    selectedRowIndex === null
                      ? null
                      : currentSortedCandidates[selectedRowIndex]
                  }
                  viewResumeModalOpen={viewResumeModalOpen}
                  onLeftKeyPress={(from) => {
                    amplitudeLogger.logEvent("SCREENO_NAVIGATE_APPLICATIONS", {
                      direction: "left",
                      from: from,
                    });
                    if (selectedRowIndex > 0) {
                      setSelectedRowIndex((index) => index - 1);
                    } else {
                      enqueueSnackbar(
                        "No more candidates!",
                        reczeeAlertParams("warning")
                      );
                    }
                  }}
                  onRightKeyPress={(from) => {
                    amplitudeLogger.logEvent("SCREENO_NAVIGATE_APPLICATIONS", {
                      direction: "right",
                      from: from,
                    });
                    if (selectedRowIndex < currentSortedCandidates.length - 1) {
                      setSelectedRowIndex((index) => index + 1);
                    } else {
                      enqueueSnackbar(
                        "No more candidates!",
                        reczeeAlertParams("warning")
                      );
                    }
                  }}
                  handleViewResumeModalClose={handleViewResumeModalClose}
                />
              </>
            ) : isLoading ? (
              <CircularProgress />
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            minHeight: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          Click on the verification link sent over your email to get started.
        </div>
      )}
    </div>
  );
}
