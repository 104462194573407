import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Redirect } from "react-router-dom";
import { UserInfoContext } from '../../contexts/UserInfoContext';
import amplitudeLogger from '../../utils/amplitude';
import { reczeeAlertParams } from '../../utils/commonHelpers';
import { postRequest } from '../../utils/httpHelpers';
import { authPageAvatar, formSubmitButton } from '../../utils/styleHelpers';
import { getQueryParams } from '../../utils/urlHelpers';
import { ReczeeInputField } from '../commons/ReczeeInputField';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  avatar: authPageAvatar(theme),
  submit: formSubmitButton(theme),
  containerGrid: {
    marginTop: theme.spacing(4),
    textAlign: 'center'
  },
  inputField: {
    marginTop: theme.spacing(2)
  },
  authHeader: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
}));

export default function CandidateSignIn() {
  const [userInfo, setUserInfo] = React.useContext(UserInfoContext);
  let queryParams = getQueryParams();
  let returnUrl = queryParams.returnUrl;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  if (!returnUrl) {
    return <div>
      <Typography variant="h6" component="h1">
        Invalid url - please click the link from the email!
      </Typography>
    </div>;
  }

  const handleSubmit = e => {
    e.preventDefault();
    let candidateApplicationSlug = returnUrl.split("/").slice(-1)[0];
    postRequest("candidate-application/login",
      {},
      {
        slug: candidateApplicationSlug,
        email: e.target.elements.candidate_email.value,
        passcode: e.target.elements.passcode.value
      },
      (data) => {
        setUserInfo({
          userId: data.candidate.id,
          token: data.candidate_token,
          name: data.candidate.name,
          email: data.candidate.email,
          roles: ["candidate"],
          slug: candidateApplicationSlug
        });
        amplitudeLogger.startSession(candidateApplicationSlug, data.company.id);
        amplitudeLogger.logEvent("LOGIN_SUCCESS", { application_slug: candidateApplicationSlug });
      },
      (errorMessages, statusCode) => {
        amplitudeLogger.logEvent("LOGIN_FAILED", { application_slug: candidateApplicationSlug, status_code: statusCode, error_messages: errorMessages });
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        }
      }
    );
  };

  if (userInfo && !!userInfo.token) {
    if (queryParams.returnUrl) {
      return (<Redirect push to={queryParams.returnUrl} />);
    }
  }

  return (
    <>
      <Typography component="h1" variant="h4" className={classes.authHeader}>
        Candidate Login
      </Typography>
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
        alignContent="center"
        className={classes.containerGrid}
      >
        <Grid item xs={10}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <ReczeeInputField
              name="candidate_email"
              label="Candidate Email"
              className={classes.inputField}
              type="text"
              fullWidth={true}
              autoFocus={true}
              required={true}
            />
            <ReczeeInputField
              id="passcode"
              name="passcode"
              label="Passcode"
              type="password"
              className={classes.inputField}
              fullWidth={true}
              required={true}
            />
            <Grid container
              direction="column"
              justify="center"
              alignItems="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                size={"large"}
              >
                Login
              </Button>
            </Grid>
            <Link href={"/login?returnUrl=" + queryParams.returnUrl}>Login as Recruiter</Link>
          </form>
        </Grid>
      </Grid>
    </>
  )
}