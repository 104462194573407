import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import ScreenoTagBadge from "./ScreenoTagBadge";
import { getStaticResourceUrl } from "../../utils/urlHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  teaserVideo: {
    maxWidth: "inherit",
    width: "500px",
    clipPath: "inset(1px 1px)",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  width80Container: {
    width: "80%",
    marginBottom: theme.spacing(3),
  },
  sectionStart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  dialogTitleCenter: {
    textAlign: "center",
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
    alignItems: "center",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: "visible",
  },
}))(MuiDialogContent);

function ScreenoHowItWorksModal(props) {
  const classes = useStyles();

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={props.handleHowItWorksModalClose}
        open={props.howItWorksModalOpen}
      >
        <DialogTitle
          onClose={props.handleHowItWorksModalClose}
          className={classes.dialogTitleCenter}
        >
          <img
            height="40"
            alt="Reczee Screeno"
            style={{ margin: "auto" }}
            src={getStaticResourceUrl("/reczee-screeno-logo.svg")}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContainer}>
          <video
            className={classes.teaserVideo}
            src={getStaticResourceUrl("/screeno-teaser.mp4")}
            autoPlay="autoplay"
            muted
            loop
          ></video>
          <Typography variant="h6" className={classes.sectionStart}>
            How it works?
          </Typography>
          <div className={classes.width80Container}>
            It is as simple as uploading resumes and finding the top candidates
            within seconds! You always wanted this ❤️
            <br />
            Works best for tech candidates (Software Engineers, QAs, DevOps)
            based out of India 🇮🇳.
          </div>
          <Typography variant="h6" className={classes.sectionStart}>
            Tags
          </Typography>
          <TableContainer className={classes.width80Container}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ paddingLeft: "24px" }}>Tag</TableCell>
                  <TableCell align="left">What it means?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <ScreenoTagBadge status={"Open Source Enthusiast"} />
                  </TableCell>
                  <TableCell>
                    Contributed to some good Open Source repositories on GitHub.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ScreenoTagBadge status={"Top Institute"} />
                  </TableCell>
                  <TableCell>
                    Candidate has studied in a top tier institute of India.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ScreenoTagBadge status={"Top Company"} />
                  </TableCell>
                  <TableCell>
                    Candidate has experience working with a leading
                    organization.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ScreenoTagBadge status={"Top Competitive Programmer"} />
                  </TableCell>
                  <TableCell>
                    One of the top DSA problem solvers based on the linked
                    coding profiles.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ScreenoTagBadge status={"Research Paper Author"} />
                  </TableCell>
                  <TableCell>
                    Candidate has published a research paper in academic
                    journals.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ScreenoTagBadge status={"Hackathon Winner"} />
                  </TableCell>
                  <TableCell>
                    Candidate has participated and won a Hackathon.
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <ScreenoTagBadge status={"No tags"} />
                  </TableCell>
                  <TableCell>
                    Candidate was not found to have any of the above tags.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default React.memo(ScreenoHowItWorksModal);
