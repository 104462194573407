import { Button, CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Divider from "@material-ui/core/Divider";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined";
import { useSnackbar } from "notistack";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { agoTimestamp } from "../../utils/commonHelpers";
import { getRequest } from "../../utils/httpHelpers";
import { CareersPageMultiSelect } from "../careers/CareersPageInputField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  jobInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    color: "#666666",
    gap: "5px",
    fontSize: "0.95rem",
  },
  containerGrid: {
    alignItems: "start",
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: "600px",
  },
  jobTypeChip: {
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "5px",
  },
  filterGrid: {
    textAlign: "left",
  },
  filterLabel: {
    color: "#666666",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
  },
  formControl: {
    marginLeft: 0,
    width: "100%",
  },
  filterSlider: {
    marginLeft: theme.spacing(1),
    width: "95%",
  },
  divider: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  departmentJobs: {
    marginBottom: theme.spacing(8),
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(3),
    },
  },
  jobCard: {
    marginBottom: theme.spacing(2),
    borderRadius: "10px",
  },
  jobTitle: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "6px",
      alignItems: "flex-start",
    },
  },
  cardContent: {
    padding: "12px !important",
    paddingLeft: "16px !important",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px !important",
    },
  },
  cardActionsGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "left",
    },
  },
  cardActions: {
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      width: "100%",
      // paddingTop: "0px !important",
    },
  },
  jobPostedOn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  jobOpeningsHeadings: {
    marginTop: "40px",
    marginBottom: "40px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  applyNowButton: {
    padding: "6px 14px",
  },
  chipOutlined: {
    borderColor: theme.palette.primary.main + "66",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main + "0d",
  },
}));

export default function JobOpeningsList(props) {
  const classes = useStyles();
  const { companySlug } = useParams();
  const [requisitions, setRequisitions] = React.useState([]);
  const [isloadingRequisitions, setIsLoadingRequisitions] =
    React.useState(true);
  const [errorValue, setErrorValue] = React.useState(null);
  const [filterLocation, setFilterLocation] = React.useState([]);
  const [filterDepartment, setFilterDepartment] = React.useState([]);
  const [filterJobType, setFilterJobType] = React.useState([]);
  const [experience, setExperience] = React.useState([0, 10]);
  const [filterLocationOptions, setFilterLocationOptions] = React.useState([]);
  const [filterDepartmentOptions, setFilterDepartmentOptions] = React.useState(
    []
  );
  const [filterJobTypeOptions, setFilterJobTypeOptions] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    getRequest(
      `requisitions/get-open-requisitions`,
      { company_slug: companySlug },
      (data) => {
        setRequisitions(data["requisitions"]);
        setIsLoadingRequisitions(false);

        let tempLocations = [];
        let tempDepartments = [];
        let tempJobTypes = [];

        data["requisitions"].forEach((job) => {
          job.locations.forEach((location) => {
            if (!tempLocations.some((loc) => loc.id === location.id)) {
              tempLocations.push(location);
            }
          });
          if (!tempDepartments.some((dept) => dept.id === job.department.id)) {
            tempDepartments.push(job.department);
          }
          if (!tempJobTypes.includes(job.job_type_display)) {
            tempJobTypes.push(job.job_type_display);
          }
        });

        setFilterLocationOptions(tempLocations);
        setFilterDepartmentOptions(tempDepartments);
        setFilterJobTypeOptions(tempJobTypes);
      },
      (errorMessages, statusCode) => {
        if (typeof errorMessages === "string") {
          setErrorValue(errorMessages);
        } else {
          setErrorValue("Something went wrong!");
        }
        setIsLoadingRequisitions(false);
      }
    );
  }, [companySlug, enqueueSnackbar]);

  if (isloadingRequisitions) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  if (errorValue) {
    return (
      <div className={classes.root}>
        <Typography component="h3" variant="h4">
          {errorValue}
        </Typography>
      </div>
    );
  }

  const filteredRequisitions = requisitions.filter((requisition) => {
    return (
      (filterLocation.length === 0 ||
        filterLocation.some((location) =>
          requisition.locations.some(
            (thisLocation) =>
              location === `${thisLocation.city},${thisLocation.country}`
          )
        )) &&
      (filterDepartment.length === 0 ||
        filterDepartment.includes(requisition.department.title)) &&
      (filterJobType.length === 0 ||
        filterJobType.includes(requisition.job_type_display)) &&
      requisition.experience_range &&
      (experience[0] === 0 ||
        requisition.experience_range[0] >= experience[0]) &&
      (experience[1] === 10 || requisition.experience_range[1] <= experience[1])
    );
  });

  const groupedRequisitions = filteredRequisitions.reduce(
    (acc, requisition) => {
      const deptTitle = requisition.department?.title || "";
      if (!acc[deptTitle]) {
        acc[deptTitle] = [];
      }
      acc[deptTitle].push(requisition);
      return acc;
    },
    {}
  );

  const filteredDepartments = Object.entries(groupedRequisitions);

  const handleChangeFilter = (values, filterName) => {
    switch (filterName) {
      case "location":
        setFilterLocation(
          values.map((value) => value.city + "," + value.country)
        );
        break;
      case "department":
        setFilterDepartment(values.map((value) => value.title));
        break;
      case "jobType":
        setFilterJobType(values);
        break;
      default:
        break;
    }
  };

  const handleChangeExperience = (event, newValue) => {
    setExperience(newValue);
  };

  const yearsMarks = [];
  for (let i = 0; i <= 10; i++) {
    yearsMarks.push({
      value: i,
      label: i === 10 ? "10+" : i,
    });
  }

  function valuetext(value) {
    return `${value}${value === 10 ? "+" : ""}`;
  }

  function valueLabelFormat(value) {
    return value;
  }

  return (
    <div className={classes.root}>
      {requisitions.length === 0 ? (
        "No open positions at the moment!"
      ) : (
        <>
          <div className={classes.jobOpeningsHeadings}>
            <Chip
              label="We are hiring"
              variant="outlined"
              color="primary"
              style={{
                fontSize: "14px",
              }}
              classes={{ outlined: classes.chipOutlined }}
            />
            <Typography
              component="h3"
              variant="h4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                paddingTop: "16px",
                paddingBottom: "14px",
              }}
            >
              Be a part of our success story
            </Typography>
            <Typography variant="subtitle1" style={{ fontSize: "20px" }}>
              Push the boundaries of innovation with us in your next role
            </Typography>
          </div>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.containerGrid}
          >
            <Grid
              item
              xs={10}
              sm={10}
              md={2}
              lg={2}
              className={classes.filterGrid}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: "600",
                  marginBottom: "15px",
                  color: "#333333",
                  fontSize: "17px",
                }}
              >
                Filter by
              </Typography>
              <Grid container justify="left" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <CareersPageMultiSelect
                    id="tags-outlined"
                    options={filterLocationOptions}
                    filterSelectedOptions={true}
                    onChange={(_, values) =>
                      handleChangeFilter(values, "location")
                    }
                    title="Location"
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id
                    }
                    getOptionLabel={(option) =>
                      `${option.city}, ${option.country}` || ""
                    }
                    limitTags={2}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <CareersPageMultiSelect
                    id="tags-outlined"
                    options={filterDepartmentOptions}
                    filterSelectedOptions={true}
                    onChange={(_, values) =>
                      handleChangeFilter(values, "department")
                    }
                    title="Department"
                    getOptionSelected={(option, value) =>
                      option?.id === value?.id
                    }
                    getOptionLabel={(option) => option?.title || ""}
                    limitTags={2}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <CareersPageMultiSelect
                    id="tags-outlined"
                    options={filterJobTypeOptions}
                    filterSelectedOptions={true}
                    onChange={(_, values) =>
                      handleChangeFilter(values, "jobType")
                    }
                    title="Job Type"
                    getOptionSelected={(option, value) => option === value}
                    getOptionLabel={(option) => option || ""}
                    limitTags={2}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={12}>
                  <div className={classes.formControl}>
                    <Typography
                      variant="subtitle2"
                      className={classes.filterLabel}
                    >
                      Experience
                    </Typography>
                    <Slider
                      value={experience}
                      valueLabelFormat={valueLabelFormat}
                      getAriaValueText={valuetext}
                      aria-labelledby="range-slider"
                      step={null}
                      max={10}
                      marks={yearsMarks}
                      valueLabelDisplay="auto"
                      onChange={handleChangeExperience}
                      className={classes.filterSlider}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <Grid item xs={10} sm={10} md={7} lg={7}>
              {filteredDepartments.length === 0 ? (
                <Typography variant="subtitle1" style={{ marginTop: "20px" }}>
                  No job openings found.
                </Typography>
              ) : (
                filteredDepartments.map(([deptTitle, jobs]) => (
                  <div className={classes.departmentJobs}>
                    <Typography
                      variant="h5"
                      style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        marginBottom: "20px",
                      }}
                    >
                      {deptTitle}
                    </Typography>
                    {jobs.map((requisition) => {
                      return (
                        <Card
                          key={requisition.id}
                          variant="outlined"
                          className={classes.jobCard}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} md={9} lg={10}>
                              <CardContent
                                align="left"
                                classes={{ root: classes.cardContent }}
                              >
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    className={classes.jobTitle}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {requisition.designation}{" "}
                                    </Typography>
                                    <Chip
                                      label={requisition.job_type_display}
                                      variant="outlined"
                                      size="small"
                                      className={classes.jobTypeChip}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography
                                      variant="subtitle2"
                                      style={{
                                        fontSize: "16px",
                                        display: "flex",
                                        justifyContent: "left",
                                        alignItems: "center",
                                        gap: "15px",
                                      }}
                                    >
                                      <Typography
                                        variant="subtitle2"
                                        className={classes.jobInfo}
                                      >
                                        <LocationOnOutlinedIcon
                                          fontSize="small"
                                          style={{
                                            color: "#666666",
                                          }}
                                        />
                                        {requisition.locations &&
                                        requisition.locations.length > 0
                                          ? requisition.locations
                                              .map((location) => location.city)
                                              .join(", ")
                                          : "-"}
                                      </Typography>
                                      {requisition.experience_range ? (
                                        <Typography
                                          variant="subtitle2"
                                          className={classes.jobInfo}
                                        >
                                          <BusinessCenterOutlinedIcon
                                            fontSize="small"
                                            style={{
                                              color: "#666666",
                                            }}
                                          />
                                          {requisition.experience_range?.[0]}-
                                          {requisition.experience_range?.[1]}{" "}
                                          years
                                        </Typography>
                                      ) : (
                                        ""
                                      )}
                                      <Typography
                                        variant="subtitle2"
                                        className={`${classes.jobInfo} ${classes.jobPostedOn}`}
                                      >
                                        <QueryBuilderOutlinedIcon
                                          fontSize="small"
                                          style={{
                                            color: "#666666",
                                          }}
                                        />
                                        {agoTimestamp(requisition.posted_on)}
                                      </Typography>
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={3}
                              lg={2}
                              className={classes.cardActionsGrid}
                            >
                              <CardActions
                                classes={{ root: classes.cardActions }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  component={Link}
                                  target="_blank"
                                  className={classes.applyNowButton}
                                  to={`/${companySlug}/${requisition.slug}/apply`}
                                >
                                  Apply Now
                                </Button>
                              </CardActions>
                            </Grid>
                          </Grid>
                        </Card>
                      );
                    })}
                  </div>
                ))
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
