import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backgroundHeader: {
    height: "230px",
    width: "100%",
    marginTop: "-48px",
    color: "white",
    display: "flex",
    justifyContent: "center",
    padding: "0px 20px 15px 20px",
  },
  backgroundImg: {
    position: "relative",
    bottom: "-21px",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
}));

export const BackgroundHeader = ({ clr, img, title, subtitle }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.backgroundHeader}
      style={{ backgroundColor: `${clr}` }}
    >
      <Grid container item xs={12} sm={8} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">{subtitle}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.backgroundImg}
          style={{ marginLeft: "auto" }}
        >
          <img src={img} alt="IMG" />
        </Grid>
      </Grid>
    </div>
  );
};
