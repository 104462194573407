import { Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import React from "react";
import { Link as CoreLink, Redirect } from "react-router-dom";
import { NotificationContext } from "../../contexts/NotificationContext";
import { UserInfoContext } from "../../contexts/UserInfoContext";
import amplitudeLogger from "../../utils/amplitude";
import { loginSuccessPostSteps } from "../../utils/authHelpers";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import { postRequest } from "../../utils/httpHelpers";
import { authPageAvatar, formSubmitButton } from "../../utils/styleHelpers";
import { getQueryParams } from "../../utils/urlHelpers";
import { ReczeeCheckBox, ReczeeInputField } from "../commons/ReczeeInputField";
import Register from "../talenthub/auth/Register";

const useStyles = makeStyles((theme) => ({
  avatar: authPageAvatar(theme),
  submit: formSubmitButton(theme),
  form: {
    width: "100%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    textAlign: "center",
  },
  containerGrid: {
    marginTop: theme.spacing(4),
    textAlign: "center",
    width: "100%",
  },
  userTabs: {
    width: "100px",
  },
  tabsContainer: {
    width: "fit-content",
    minHeight: "40px",
    "& .Mui-selected": {
      background: "linear-gradient(336deg, #28c880 1.15%, #1774e4 80%)",
      color: "white",
    },
    "& > .PrivateTabIndicator-root": {
      height: "0px !important",
    },
    border: "1px solid #e0e5ea",
    borderRadius: "11px",
  },
  singleTab: {
    color: "black",
    fontWeight: "bolder",
    borderRadius: "10px",
    minHeight: "40px",
  },
  rememberMeField: {
    marginTop: theme.spacing(1),
  },
  loginInputField: {
    marginTop: theme.spacing(2),
  },
  termsAcceptLabel: {
    fontSize: "12px",
  },
  extraLinksContainer: {
    marginTop: theme.spacing(1.5),
  },
  authHeader: {
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default function Login(props) {
  const queryParams = getQueryParams();
  const classes = useStyles();
  const [userInfo, setUserInfo] = React.useContext(UserInfoContext);
  const setNotificationInfo = React.useContext(NotificationContext)[1];
  const [rememberMe, setRememberMe] = React.useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [value, setValue] = React.useState(
    props && props.talenthubCandidate ? 1 : 0
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { enqueueSnackbar } = useSnackbar();

  document.title = `${
    props && props.talenthubCandidate ? "Candidate login" : "Company login"
  } | Reczee`;

  const handleSubmit = (e) => {
    e.preventDefault();

    let requestParams = {};

    if (queryParams.admin) {
      requestParams["admin"] = queryParams.admin;
      requestParams["company_id"] = queryParams.company_id;
    }

    setIsFormSubmitted(true);
    postRequest(
      "login",
      requestParams,
      {
        email: e.target.elements.email.value,
        password: e.target.elements.password.value,
        remember_me: e.target.elements.remember_me.checked,
      },
      (data) => {
        setIsFormSubmitted(false);
        loginSuccessPostSteps(
          {
            userId: data.user.id,
            token: data.token,
            email: data.user.email,
            roles: data.user.roles,
            name: data.user.name,
            emailVerified: data.user.email_verified,
            companyApproved: data.company.approved,
          },
          setUserInfo,
          props.setReloadApp
        );
        setNotificationInfo(null);

        amplitudeLogger.startSession(data.user.id, data.company.id);
        let amplitudeProperties = {
          user_roles: data.user.roles,
          email_verified: data.user.email_verified,
          remember_me: e.target.elements.remember_me.checked,
        };
        if (queryParams.admin) {
          amplitudeProperties["admin"] = queryParams.admin;
        }
        amplitudeLogger.logEvent("LOGIN_SUCCESS", amplitudeProperties);
        enqueueSnackbar(
          "Successfully logged in!",
          reczeeAlertParams("success")
        );
      },
      (errorMessages, statusCode) => {
        setIsFormSubmitted(false);
        amplitudeLogger.logEvent("LOGIN_FAILED", {
          status_code: statusCode,
          error_messages: errorMessages,
        });
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        }
      }
    );
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  if (userInfo && !!userInfo.token) {
    if (queryParams.returnUrl) {
      return <Redirect push to={queryParams.returnUrl} />;
    } else {
      return <Redirect push to="/dashboard"></Redirect>;
    }
  }

  return (
    <>
      <Typography component="h1" variant="h4" className={classes.authHeader}>
        Login
      </Typography>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          variant="standard"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabsContainer}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <Tab label="For companies" className={classes.singleTab} />
          <Tab label="For candidates" className={classes.singleTab} />
        </Tabs>
      </div>
      <TabPanel value={value} index={0}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.containerGrid}
        >
          <Grid item xs={10}>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <ReczeeInputField
                id="email"
                name="email"
                label="Email"
                autoComplete="email"
                className={classes.loginInputField}
                fullWidth={true}
              />
              <ReczeeInputField
                id="password"
                name="password"
                label="Password"
                type="password"
                fullWidth={true}
                className={classes.loginInputField}
                autoComplete="current-password"
              />
              <ReczeeCheckBox
                checked={rememberMe}
                onChange={handleRememberMeChange}
                name="remember_me"
                label="Remember me"
                className={classes.rememberMeField}
              />
              <div className={classes.termsAcceptLabel}>
                <i>
                  By logging in you agree to our{" "}
                  <Link
                    className={classes.termsAcceptLabel}
                    variant="body2"
                    target="_blank"
                    href="https://www.reczee.com/privacy-policy"
                  >
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link
                    className={classes.termsAcceptLabel}
                    variant="body2"
                    target="_blank"
                    href="https://www.reczee.com/terms-and-conditions"
                  >
                    Terms and conditions
                  </Link>
                </i>
              </div>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Button
                  disabled={isFormSubmitted}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size={"large"}
                >
                  {isFormSubmitted ? "Loading..." : "Login"}
                </Button>
              </Grid>
              <Grid container className={classes.extraLinksContainer}>
                <Grid item xs>
                  <Link to="/forgot-password" component={CoreLink}>
                    Forgot password
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link to="/register" component={CoreLink}>
                    Create an account
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={classes.containerGrid}>
          <Register login={true} setReloadApp={props.setReloadApp} />
        </div>
      </TabPanel>
    </>
  );
}
