import axios from "axios";
import {
  clearAuthLocalStorage,
  getCompanyId,
  getFromLocalStorage,
} from "./authHelpers";
import { generateApiUrl } from "./urlHelpers";

const handleRequestError = (err, fail) => {
  if (!fail) return;
  if (!err.response) return;
  if (err.response.status === 401) {
    // @Todo(Raj): Some frontend guy please help me!
    // How to call setUserInfo({}) from this method (this logs out on next refresh/new component load)
    clearAuthLocalStorage();
  }
  if (err.response.data && err.response.data.error_messages) {
    fail(err.response.data.error_messages, err.response.status);
  } else {
    fail("Something went wrong", err.response.status);
  }
};

const commonQueryParams = () => {
  let companyId = getCompanyId();
  return companyId ? { company_id: companyId } : {};
};

export function downloadFilePostRequest(
  acceptHeader,
  path,
  params,
  data,
  success,
  fail
) {
  axios({
    method: "POST",
    url: generateApiUrl(path),
    headers: {
      "Content-Type": "application/json",
      Accept: acceptHeader,
      Authorization: "Bearer " + getFromLocalStorage("token"),
    },
    responseType: "blob",
    params: { ...commonQueryParams(), ...params },
    data: data,
  })
    .then((response) => {
      if (success) {
        success(response);
      }
    })
    .catch((err) => {
      console.log("http error", err);
      handleRequestError(err, fail);
    });
}

export function postRequest(path, params, data, success, fail) {
  axios({
    method: "POST",
    url: generateApiUrl(path),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getFromLocalStorage("token"),
    },
    params: { ...commonQueryParams(), ...params },
    data: data,
  })
    .then((response) => {
      if (success) {
        if (response.data) success(response.data.data);
        else success();
      }
    })
    .catch((err) => {
      console.log("http error", err);
      handleRequestError(err, fail);
    });
}

export function multiPartPostRequest(path, params, data, success, fail) {
  axios({
    method: "POST",
    url: generateApiUrl(path),
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + getFromLocalStorage("token"),
    },
    params: { ...commonQueryParams(), ...params },
    data: data,
  })
    .then((response) => {
      if (success) {
        if (response.data) success(response.data.data);
        else success();
      }
    })
    .catch((err) => {
      console.log("http error", err);
      handleRequestError(err, fail);
    });
}

export function getRequest(path, params, success, fail) {
  axios({
    method: "GET",
    url: generateApiUrl(path),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getFromLocalStorage("token"),
    },
    params: { ...commonQueryParams(), ...params },
  })
    .then((response) => {
      if (success) success(response.data.data);
    })
    .catch((err) => {
      console.log("error in getRequest", err);
      handleRequestError(err, fail);
    });
}

export function putRequest(path, data, success, fail) {
  axios({
    method: "PUT",
    url: generateApiUrl(path),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getFromLocalStorage("token"),
    },
    params: commonQueryParams(),
    data: data,
  })
    .then((response) => {
      if (success) {
        if (response.data) success(response.data.data);
        else success();
      }
    })
    .catch((err) => {
      console.log("http error", err);
      handleRequestError(err, fail);
    });
}

export function deleteRequest(path, params, data, success, fail) {
  axios({
    method: "DELETE",
    url: generateApiUrl(path),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getFromLocalStorage("token"),
    },
    params: { ...commonQueryParams(), ...params },
    data: data,
  })
    .then((response) => {
      if (success) {
        if (response.data) success(response.data.data);
        else success();
      }
    })
    .catch((err) => {
      console.log("http error", err);
      handleRequestError(err, fail);
    });
}
