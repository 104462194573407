import { CircularProgress, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import { socialIconDetails } from "../talenthub/candidate/TalenthubCandidateConstants";
import ScreenoTagBadge from "./ScreenoTagBadge";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  socialIcon: {
    height: "28px",
    marginRight: "2px",
    marginLeft: "2px",
  },
  centeredDiv: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "150px",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  leftGridItemField: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  rightGridItemField: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  leftRightIcon: {
    cursor: "pointer",
    marginTop: "200px",
    margin: theme.spacing(1),
  },
  nameTitleHeading: {
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  stateChange: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  skillContainer: {
    marginTop: theme.spacing(1),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, description, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <>{description}</>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ViewScreenoResumeModal({
  selectedRow,
  viewResumeModalOpen,
  onLeftKeyPress,
  onRightKeyPress,
  handleViewResumeModalClose,
}) {
  const classes = useStyles();
  const [isProfileLoading, setIsProfileLoading] = React.useState(false);

  const processOtherProfile = (direction, from) => {
    setIsProfileLoading(true);
    if (direction === "left") {
      onLeftKeyPress(from);
    } else {
      onRightKeyPress(from);
    }

    setTimeout(() => {
      setIsProfileLoading(false);
    }, 1000);
  };

  return (
    <Dialog
      onKeyDown={(e) => {
        switch (e.key) {
          case "Left": // IE/Edge specific value
          case "ArrowLeft":
            processOtherProfile("left", "key_left");
            break;
          case "Right": // IE/Edge specific value
          case "ArrowRight":
            processOtherProfile("right", "key_right");
            break;
          default:
            return; // Quit when this doesn't handle the key event.
        }
      }}
      maxWidth="xl"
      fullWidth={true}
      onClose={handleViewResumeModalClose}
      open={viewResumeModalOpen}
    >
      <DialogTitle
        onClose={handleViewResumeModalClose}
        className={classes.dialogTitleCenter}
      >
        {selectedRow?.filename}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container className={classes.formGridContainer}>
          <Grid item xs={8} className={classes.leftGridItemField}>
            {isProfileLoading ? (
              <CircularProgress style={{ marginTop: "200px" }} />
            ) : (
              ""
            )}
            {selectedRow && selectedRow.resume_url && viewResumeModalOpen ? (
              <iframe
                title="Candidate resume"
                src={
                  selectedRow.resume_url[0] === "/"
                    ? window.location.origin + "/" + selectedRow.resume_url
                    : selectedRow.resume_url
                }
                style={{ visibility: isProfileLoading ? "hidden" : "visible" }}
                width="100%"
                height="800px"
                frameBorder="0"
                scrolling="auto"
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={4} className={classes.rightGridItemField}>
            <Grid container style={{ width: "80%" }}>
              <Grid item xs={1}>
                <ChevronLeftIcon
                  fontSize="large"
                  className={classes.leftRightIcon}
                  onClick={() => {
                    processOtherProfile("left", "left_button");
                  }}
                />
              </Grid>
              <Grid item xs={10} className={classes.centeredDiv}>
                <Typography variant="h5">{selectedRow?.name}</Typography>
                <Typography style={{ marginTop: "8px" }}>
                  {selectedRow?.email}
                </Typography>
                <Typography>{selectedRow?.phone_number}</Typography>

                <div style={{ marginTop: "8px" }}>
                  {selectedRow?.links
                    ? socialIconDetails.map((iconDetails, index) => {
                        let lowerTitle = iconDetails.value;
                        if (selectedRow?.links[lowerTitle]) {
                          return (
                            <a
                              key={index}
                              rel="noreferrer"
                              href={selectedRow?.links[lowerTitle]}
                              target="_blank"
                            >
                              <img
                                className={classes.socialIcon}
                                src={getStaticResourceUrl(
                                  `/social-icons/${lowerTitle}.${iconDetails.extension}`
                                )}
                                alt={lowerTitle}
                              />
                            </a>
                          );
                        } else {
                          return null;
                        }
                      })
                    : ""}
                </div>

                <div
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {selectedRow?.status === "processing" ? (
                    <Skeleton animation="pulse" width={200} variant="text" />
                  ) : selectedRow?.error ? (
                    <span className={classes.parseErrorContainer}>
                      {selectedRow?.error}
                    </span>
                  ) : selectedRow?.tags.length > 0 ? (
                    selectedRow?.tags.map((tag) => {
                      return <ScreenoTagBadge status={tag} />;
                    })
                  ) : (
                    <ScreenoTagBadge status="No tags" />
                  )}
                </div>
              </Grid>
              <Grid item xs={1}>
                <ChevronRightIcon
                  fontSize="large"
                  className={classes.leftRightIcon}
                  onClick={() => {
                    processOtherProfile("right", "right_button");
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const customComparator = (prevProps, nextProps) => {
  if (nextProps.selectedRow === null && !prevProps.selectedRow === null) {
    return true;
  } else if (nextProps.selectedRow === null || prevProps.selectedRow === null) {
    return false;
  } else {
    return nextProps.selectedRow.id === prevProps.selectedRow.id;
  }
};

export default React.memo(ViewScreenoResumeModal, customComparator);
