import React from "react";

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });
};

export default function GoogleLoginButton(props) {
  const googleButton = React.useRef(null);
  const [scriptLoaded, setScriptLoaded] = React.useState(null);

  React.useEffect(() => {
    const googleSrc = "https://accounts.google.com/gsi/client";
    const googleClientId =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_PROD_GOOGLE_CLIENT_ID
        : process.env.REACT_APP_GOOGLE_CLIENT_ID;

    loadScript(googleSrc)
      .then(() => {
        /*global google*/
        google.accounts.id.initialize({
          client_id: googleClientId,
          callback: props.callback,
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: "standard",
          text: props.login ? "signin_with" : "signup_with",
          size: "large",
          shape: "rectangular",
        });

        setScriptLoaded(true);
        google.accounts.id.prompt();
      })
      .catch((err) => {
        console.debug("Error loading script", err);
        setScriptLoaded(false);
      });

    return () => {
      const scriptTag = document.querySelector(`script[src="${googleSrc}"]`);
      if (scriptTag) {
        document.body.removeChild(scriptTag);
        setScriptLoaded(false);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div ref={googleButton}></div>

      {scriptLoaded === false ? (
        <div>
          If you don't see a Google Sign In button here, please open{" "}
          <b>app.reczee.com</b> on your native Chrome/Safari browser (preferably
          from a non-mobile device).
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
