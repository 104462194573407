import { Button, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CallMadeIcon from "@material-ui/icons/CallMade";
import React from "react";
import { getStaticResourceUrl } from "../../utils/urlHelpers";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  websiteLinkButton: {
    marginRight: theme.spacing(2),
    textDecoration: "none",
    color: theme.palette.secondary.main,
    "&:hover": {
      textDecoration: "none",
      opacity: 0.99,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      fontSize: "0.75rem",
    },
  },
  companyLogoImage: {
    // marginLeft: theme.spacing(4),
    // marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },
  jobsNavbar: {
    backgroundColor: theme.palette.secondary.main,
  },
  emptyNavbarWithLogo: {
    backgroundColor: "white",
    position: "relative",
    zIndex: 1,
    height: "80px",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default function ReczeeCompanyWhiteNavbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.emptyNavbarWithLogo}>
      <Link href={`/${props.companyDetails.slug}`}>
        <img
          src={props.companyDetails.logo_url}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = getStaticResourceUrl("/reczee-dark-logo.svg");
          }}
          alt={props.companyDetails.slug}
          width="160"
          className={classes.companyLogoImage}
        />
      </Link>
      {props.companyDetails.website_url ? (
        <Button
          color="secondary"
          component={Link}
          href={props.companyDetails.website_url}
          target="_blank"
          variant="outlined"
          className={classes.websiteLinkButton}
          endIcon={<CallMadeIcon />}
        >
          Company Website
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}
