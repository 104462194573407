import React from "react";
import { postRequest } from "../../utils/httpHelpers";
import { getRequest, deleteRequest } from "../../utils/httpHelpers";
import { getOauthLocalStorageKey } from "../../utils/authHelpers";
import { useSnackbar } from "notistack";
import { reczeeAlertParams } from "../../utils/commonHelpers";
import Breadcrumbs from "../commons/Breadcrumbs";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { getStaticResourceUrl } from "../../utils/urlHelpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import GreenhouseSetupModal from "./GreenhouseSetupModal";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { UserInfoContext } from "../../contexts/UserInfoContext";
import amplitudeLogger, {
  SCREENO_INTEGRATIONS_PAGE,
} from "../../utils/amplitude";

const useStyles = makeStyles((theme) => ({
  breadcrumbsDiv: {
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  root: {
    width: "100%",
  },
  submitButton: {
    display: "block",
    marginLeft: "auto",
    marginTop: theme.spacing(3),
  },
  internalContent: {
    marginTop: theme.spacing(1),
  },
  integrationConnectedContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  successfullyIntegrated: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    "& .MuiAlert-icon": {
      padding: 0,
    },
    "& .MuiAlert-message": {
      padding: 0,
    },
    padding: "6px 10px",
  },
  comingSoonStatus: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    "& .MuiAlert-icon": {
      padding: 0,
    },
    "& .MuiAlert-message": {
      padding: 0,
    },
    padding: "6px 10px",
  },
  connectButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  extraPadding: {
    padding: theme.spacing(3),
  },
  disconnectButton: {
    color: "#C70202",
    borderColor: "#C70202",
    padding: "0px 5px",
    "& .MuiButton-label": {
      fontWeight: 100,
    },
    "&:hover": {
      backgroundColor: "#C702020A",
    },
  },
  paperContainer: {
    marginTop: theme.spacing(6),
  },
  fullWidthContainer: {
    marginTop: theme.spacing(2),
  },
  integrationCard: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "95%",
    borderRadius: "10px",
    minHeight: "230px",
    marginRight: theme.spacing(2),
  },
  allCenterDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const messageMap = {
  success: "Successfully integrated!",
  email_mismatch: "Please authorize with registered email!",
  jwt_error: "Something went wrong!",
  access_denied: "Please accept the permissions!",
  scope_not_accepted: "Please accept all the scopes!",
  refresh_token_not_present:
    "Please revoke access and try again - myaccount.google.com/permissions",
  authorization_error: "Unable to authorize",
};

function ConfirmationDialogRaw(props) {
  const classes = useStyles();
  const { onClose, onConfirm, provider, open, ...other } = props;

  return (
    <Dialog
      maxWidth="sm"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      style={{ padding: "24px" }}
    >
      <DialogTitle
        id="confirmation-dialog-title"
        className={classes.extraPadding}
      >
        Are you sure you want to disconnect {provider}?
      </DialogTitle>
      <DialogContent style={{ paddingLeft: "24px", paddingRight: "24px" }}>
        Please ensure no background fetch jobs are active. You will not be able
        to undo
      </DialogContent>
      <DialogActions className={classes.extraPadding}>
        <Button
          onClick={onConfirm}
          style={{ color: "white", backgroundColor: "#C70202" }}
        >
          Confirm
        </Button>
        <Button autoFocus onClick={onClose} color="default">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ScreenoIntegrations() {
  const [oauthRedirectInfo, setOauthRedirectInfo] = React.useState(null);
  const [greenHouseConnectedStatus, setGreenHouseConnectedStatus] =
    React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [userInfo] = React.useContext(UserInfoContext);
  const isScreenoApprovedUser =
    userInfo.emailVerified && userInfo.companyApproved;
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [greenhouseModalOpen, setGreenhouseModalOpen] = React.useState(false);
  const [deleteIconOpen, setDeleteIconOpen] = React.useState({
    greenhouse: false,
    lever: false,
  });
  const [refreshIntegrationStatus, setRefreshIntegrationStatus] =
    React.useState(0);

  document.title = "Integrations | Reczee Screeno";

  React.useEffect(() => {
    amplitudeLogger.logEvent("SCREENO_INTEGRATIONS_PAGE_LOADED", {});
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    getRequest(
      `oauth/generate-redirect-url`,
      {
        redirect_url: window.location.href,
        kind: "ats",
        provider: "lever",
      },
      (data) => {
        setOauthRedirectInfo(data);
        setIsLoading(false);
      },
      (errorMessages, statusCode) => {
        setIsLoading(false);
      }
    );
    getRequest(
      `screeno/greenhouse/connection-status`,
      {},
      (data) => {
        if (data["greenhouse_integrated"] === "disabled") {
          setGreenHouseConnectedStatus("disabled");
        } else if (data["greenhouse_integrated"]) {
          setGreenHouseConnectedStatus("connected");
        } else {
          setGreenHouseConnectedStatus(null);
        }
      },
      (errorMessages, statusCode) => {
        enqueueSnackbar(
          "Error while fetching the connection status",
          reczeeAlertParams("error")
        );
      }
    );
  }, [refreshIntegrationStatus]);

  const integrateLever = () => {
    if (!isScreenoApprovedUser) {
      enqueueSnackbar("Please verify your email!", reczeeAlertParams("error"));
      return;
    }

    amplitudeLogger.logEvent("SCREENO_CONNECT_INTEGRATION", {
      provider: "lever",
      page: SCREENO_INTEGRATIONS_PAGE,
    });

    if (oauthRedirectInfo && oauthRedirectInfo.redirect_url) {
      let tab = window.open(oauthRedirectInfo.redirect_url, "_blank");
      let setIntervalId = setInterval(() => {
        let oauthStatus = localStorage.getItem(
          getOauthLocalStorageKey(oauthRedirectInfo.transaction_id)
        );

        if (oauthStatus === null) {
          return;
        }

        clearInterval(setIntervalId);
        localStorage.removeItem(
          getOauthLocalStorageKey(oauthRedirectInfo.transaction_id)
        );

        if (tab) {
          tab.close();
        }
        if (oauthStatus === "success") {
          enqueueSnackbar(
            messageMap[oauthStatus],
            reczeeAlertParams("success")
          );
          setOauthRedirectInfo({
            ...oauthRedirectInfo,
            state: "connected",
          });
        } else {
          enqueueSnackbar(messageMap[oauthStatus], reczeeAlertParams("error"));
          setOauthRedirectInfo({
            ...oauthRedirectInfo,
            state: "errored",
          });
        }
      }, 1000);
    }
  };

  const requestCallback = (kind) => () => {
    amplitudeLogger.logEvent("SCREENO_INTEGRATIONS_REQUEST_CALLBACK", {
      kind: kind,
      page: SCREENO_INTEGRATIONS_PAGE,
    });

    postRequest(
      "screeno/request-callback",
      { kind: kind },
      {},
      (data) => {
        enqueueSnackbar(
          "We will reach out shortly!",
          reczeeAlertParams("success")
        );
      },
      (errorMessages) => {
        enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
      }
    );
  };

  const disconnectIntegrationCloseModal = (provider) => {
    setDeleteIconOpen({
      ...deleteIconOpen,
      [provider]: false,
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.breadcrumbsDiv}>
        <Grid item>
          <Breadcrumbs
            links={[
              {
                label: "Home",
                url: "/screeno",
              },
              {
                label: "Integrations",
                url: "/screeno/integrations",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Typography component="h5" variant="h5" gutterBottom>
        Integrations
      </Typography>
      <Grid container item xs={12} className={classes.paperContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className={classes.allCenterDiv}>
            <Paper className={classes.integrationCard} elevation={3}>
              <img
                src={getStaticResourceUrl("/screeno/greenhouse-logo.png")}
                alt="Greenhouse"
                height="45"
              />
              {greenHouseConnectedStatus &&
              greenHouseConnectedStatus === "connected" ? (
                <div className={classes.integrationConnectedContainer}>
                  <Alert
                    className={classes.successfullyIntegrated}
                    icon={<DoneAllIcon fontSize="inherit" />}
                    severity="success"
                  >
                    <b>Connected</b>
                  </Alert>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.disconnectButton}
                    onClick={(e) => {
                      e.preventDefault();
                      amplitudeLogger.logEvent(
                        "SCREENO_DISCONNECT_INTEGRATION",
                        {
                          provider: "greenhouse",
                          page: SCREENO_INTEGRATIONS_PAGE,
                        }
                      );
                      setDeleteIconOpen({
                        ...deleteIconOpen,
                        greenhouse: true,
                      });
                    }}
                  >
                    Disconnect
                  </Button>
                </div>
              ) : greenHouseConnectedStatus === "disabled" ? (
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.connectButton}
                  onClick={requestCallback("greenhouse")}
                  color="primary"
                  size="medium"
                >
                  REQUEST CALLBACK
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={classes.connectButton}
                  disabled={greenhouseModalOpen}
                  onClick={() => {
                    amplitudeLogger.logEvent("SCREENO_CONNECT_INTEGRATION", {
                      provider: "greenhouse",
                      page: SCREENO_INTEGRATIONS_PAGE,
                    });

                    if (isScreenoApprovedUser) {
                      setGreenhouseModalOpen(true);
                    } else {
                      enqueueSnackbar(
                        "Please verify your email!",
                        reczeeAlertParams("error")
                      );
                    }
                  }}
                >
                  {greenhouseModalOpen ? "PLEASE WAIT..." : "CONNECT"}
                </Button>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} className={classes.allCenterDiv}>
            <Paper className={classes.integrationCard} elevation={3}>
              <img
                src={getStaticResourceUrl("/screeno/lever-logo.png")}
                alt="Lever"
                height="50"
              />
              {oauthRedirectInfo &&
                (oauthRedirectInfo.state === "disabled" ? (
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.connectButton}
                    onClick={requestCallback("lever")}
                    color="primary"
                    size="medium"
                  >
                    REQUEST CALLBACK
                  </Button>
                ) : oauthRedirectInfo.state !== "connected" ? (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="medium"
                    className={classes.connectButton}
                    onClick={integrateLever}
                  >
                    CONNECT
                  </Button>
                ) : (
                  <div className={classes.integrationConnectedContainer}>
                    <Alert
                      className={classes.successfullyIntegrated}
                      icon={<DoneAllIcon fontSize="inherit" />}
                      severity="success"
                    >
                      <b>Connected</b>
                    </Alert>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.disconnectButton}
                      onClick={(e) => {
                        e.preventDefault();
                        amplitudeLogger.logEvent(
                          "SCREENO_DISCONNECT_INTEGRATION",
                          {
                            provider: "lever",
                            page: SCREENO_INTEGRATIONS_PAGE,
                          }
                        );
                        setDeleteIconOpen({
                          ...deleteIconOpen,
                          lever: true,
                        });
                      }}
                    >
                      Disconnect
                    </Button>
                  </div>
                ))}
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} className={classes.allCenterDiv}>
            <Paper className={classes.integrationCard} elevation={3}>
              <img
                src={getStaticResourceUrl("/reczee-ats-dark-logo.svg")}
                alt="Reczee ATS"
                height="45"
              />
              <Button
                type="submit"
                variant="contained"
                className={classes.connectButton}
                onClick={requestCallback("reczee_ats")}
                color="primary"
                size="medium"
              >
                REQUEST CALLBACK
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} className={classes.allCenterDiv}>
            <Paper className={classes.integrationCard} elevation={3}>
              <Typography variant="h5">
                <b>Custom Integrations</b>
              </Typography>
              <Button
                type="submit"
                variant="contained"
                className={classes.connectButton}
                onClick={requestCallback("custom")}
                color="primary"
                size="medium"
              >
                REQUEST CALLBACK
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <GreenhouseSetupModal
        greenhouseModalOpen={greenhouseModalOpen}
        setGreenhouseModalOpen={setGreenhouseModalOpen}
        greenHouseConnectedStatus={greenHouseConnectedStatus}
        setGreenHouseConnectedStatus={setGreenHouseConnectedStatus}
      />
      <ConfirmationDialogRaw
        classes={{
          paper: classes.paper,
        }}
        id="ringtone-menu"
        keepMounted
        provider="Greenhouse"
        open={deleteIconOpen["greenhouse"]}
        onClose={() => {
          disconnectIntegrationCloseModal("greenhouse");
        }}
        onConfirm={() => {
          deleteRequest(
            "screeno/integrations/disconnect",
            { provider: "greenhouse" },
            {},
            (data) => {
              enqueueSnackbar(
                "Successfully disconnected!",
                reczeeAlertParams("success")
              );
              setRefreshIntegrationStatus((prev) => prev + 1);
              disconnectIntegrationCloseModal("greenhouse");
            },
            (errorMessages) => {
              if (typeof errorMessages === "string") {
                enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
              } else {
                enqueueSnackbar(
                  "Something went wrong!",
                  reczeeAlertParams("error")
                );
              }
            }
          );
        }}
      />
      <ConfirmationDialogRaw
        classes={{
          paper: classes.paper,
        }}
        id="ringtone-menu"
        provider="Lever"
        keepMounted
        open={deleteIconOpen["lever"]}
        onClose={() => {
          disconnectIntegrationCloseModal("lever");
        }}
        onConfirm={() => {
          deleteRequest(
            "screeno/integrations/disconnect",
            { provider: "lever" },
            {},
            (data) => {
              enqueueSnackbar(
                "Successfully disconnected!",
                reczeeAlertParams("success")
              );
              setRefreshIntegrationStatus((prev) => prev + 1);
              disconnectIntegrationCloseModal("lever");
            },
            (errorMessages) => {
              if (typeof errorMessages === "string") {
                enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
              } else {
                enqueueSnackbar(
                  "Something went wrong!",
                  reczeeAlertParams("error")
                );
              }
            }
          );
        }}
      />
    </div>
  );
}
