import {
  Button,
  CircularProgress,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import FilterListIcon from "@material-ui/icons/FilterList";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PaymentIcon from "@material-ui/icons/Payment";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import React from "react";
import { Link as CoreLink } from "react-router-dom";
import amplitudeLogger, {
  TALENTHUB_LIST_JOBS_PAGE,
} from "../../../utils/amplitude";
import { isTalentHubCandidateLoggedIn } from "../../../utils/authHelpers";
import { reczeeAlertParams } from "../../../utils/commonHelpers";
import { getRequest, postRequest } from "../../../utils/httpHelpers";
import {
  getQueryParams,
  getStaticResourceUrl,
} from "../../../utils/urlHelpers";
import AddIcon from "@material-ui/icons/Add";
import FilterBar from "./FilterBar";
import { RequirementListContainer } from "./RequirementListContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    textAlign: "center",
  },
  companyLogo: {
    width: "60px",
    padding: "5px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
      marginLeft: theme.spacing(2),
    },
  },
  actionButton: {
    marginTop: theme.spacing(1),
    minWidth: "100px",
    "&:hover": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
  pleaseMatchRequirements: {
    fontSize: "10px",
    color: "#767575",
    textAlign: "center",
  },
  actionButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
  },
  alreadyApplied: {
    "& .MuiAlert-icon": {
      padding: 0,
    },
    "& .MuiAlert-message": {
      padding: 0,
    },
    padding: "6px 10px",
  },
  allJobsList: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  jobTitleH3: {
    marginTop: "4px",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
    },
  },
  requirementsH5: {
    // marginBlockStart: 0,
    color: "grey",
    marginBlockEnd: 0,
    marginTop: "4px",
    fontSize: "14px",
  },
  requirementDetailsContainer: {},
  jobAttributeValue: {
    marginLeft: "4px",
    fontSize: "14px",
  },
  jobAttributesContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(3),
    },
  },
  jobTitleAnchor: {
    textDecoration: "none",
    color: "#454343",
  },
  jobAttributeContainer: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      padding: "4px !important",
    },

    // marginRight: theme.spacing(3)
  },
  companyImageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  jobIcon: {
    fontSize: "14px",
    marginTop: "3px",
    color: "#767575",
    // marginLeft: theme.spacing(2),
  },
  allJobsContainer: {
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-end",
  },
  jobListing: {
    width: "100%",
    padding: "10px",
    textAlign: "left",
    display: "flex",
    marginTop: "4px",
  },
  jobDetailsContainer: {
    marginTop: 0,
    marginBottom: "4px",
  },
  subscribeToUpdates: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  filterRoot: {
    display: "flex",
    justifyContent: "flex-end",
  },
  filterList: {
    width: "fit-content",
    paddingTop: "6px",
    paddingBottom: "6px",
  },
  filterButton: {
    height: "100%",
    minWidth: "100px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  filterIcon: {
    minWidth: "33px",
    paddingRight: "10px",
  },
}));

export default function ListJobs() {
  const classes = useStyles();
  const [jobs, setJobs] = React.useState([]);
  const [filterJobType, setFilterJobType] = React.useState("All");
  const [experience, setExperience] = React.useState(0);
  const [filteredJobs, setFilteredJobs] = React.useState(jobs);
  const [skills, setSkills] = React.useState([]);
  const [filtersOpen, setFiltersOpen] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const queryParams = getQueryParams();

  React.useEffect(() => {
    getRequest(
      `talenthub/jobs`,
      queryParams.test
        ? {
            test: queryParams.test,
          }
        : {},
      (data) => {
        setJobs(data.jobs);
      },
      (errorMessages, statusCode) => {
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );

    amplitudeLogger.logEvent("LIST_JOBS_LOADED");
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setFilteredJobs(jobs);
  }, [jobs]);

  React.useEffect(() => {
    let currentJobsValue = jobs;
    currentJobsValue = filterSkills(skills, currentJobsValue);
    currentJobsValue = filterExp(experience, currentJobsValue);
    currentJobsValue = filterType(filterJobType, currentJobsValue);
    setFilteredJobs(currentJobsValue);
    // eslint-disable-next-line
  }, [skills, experience, filterJobType]);

  const filterType = (curcat, currentJobsValue) => {
    if (curcat === "All") {
      return currentJobsValue;
    }

    return currentJobsValue.filter((newVal) => {
      return newVal.job_type_display === curcat;
    });
  };

  const filterExp = (experienceValue, currentJobsValue) => {
    return currentJobsValue.filter((newVal) => {
      if (experienceValue > 5) {
        return newVal.experience_range[0] >= 5;
      }
      return (
        experienceValue >= newVal.experience_range[0] &&
        experienceValue <= newVal.experience_range[1]
      );
    });
  };

  const filterSkills = (skills, currentJobsValue) => {
    if (skills.length === 0) {
      return currentJobsValue;
    }
    const newJobs = [];
    const skillSlugs = skills.map((skill) => skill.slug);
    for (let i = 0; i < currentJobsValue.length; i++) {
      let jobSkills = currentJobsValue[i].requirement_list
        .filter((requirement) => requirement.type === "skill")
        .map((requirement) => requirement.data.slug);

      let skillsMatch = skillSlugs.every(function (skillSlug) {
        return jobSkills.indexOf(skillSlug) >= 0;
      });

      if (skillsMatch) {
        newJobs.push(currentJobsValue[i]);
      }
    }
    return newJobs;
  };

  if (Object.keys(jobs).length === 0) {
    return <CircularProgress />;
  }

  const handleJobTypeChange = (event) => {
    setFilterJobType(event.target.value);
  };

  const handleExpChange = (_, value) => {
    setExperience(value);
  };

  const handleSkillsChange = (event, value) => {
    setSkills(value);
  };

  let totalAppliedFilterCount = 0;
  if (filterJobType !== "All") {
    totalAppliedFilterCount++;
  }
  if (experience !== 0) {
    totalAppliedFilterCount++;
  }
  if (skills.length !== 0) {
    totalAppliedFilterCount++;
  }

  document.title = "Jobs | Reczee TalentHub";

  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h4">
        Jobs
      </Typography>
      {isTalentHubCandidateLoggedIn() ? (
        <span>
          Note: Phone number, resume and profile completion more than 60% is
          required to apply!
        </span>
      ) : (
        ""
      )}
      <Grid container alignContent="center" justify="center">
        <Grid item xs={12} md={10} className={classes.allJobsContainer}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {isTalentHubCandidateLoggedIn() ? (
                ""
              ) : (
                <div style={{ marginRight: "8px" }}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.actionButton}
                    component={CoreLink}
                    to="/talenthub/post-job"
                    onClick={() => {
                      amplitudeLogger.logEvent("POST_JOB", {
                        page: TALENTHUB_LIST_JOBS_PAGE,
                      });
                    }}
                    startIcon={<AddIcon />}
                  >
                    Post Job
                  </Button>
                </div>
              )}
              <div className={classes.filterRoot}>
                <List className={classes.filterList} aria-label="Job filters">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="filter-menu"
                    aria-label="Filter"
                    className={classes.filterButton}
                    onClick={() => {
                      amplitudeLogger.logEvent("JOB_FILTER_CLICKED", {});
                      setFiltersOpen(!filtersOpen);
                    }}
                  >
                    <ListItemIcon className={classes.filterIcon}>
                      <FilterListIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary="FILTERS"
                      secondary={
                        totalAppliedFilterCount > 0
                          ? `${totalAppliedFilterCount} applied`
                          : "All jobs"
                      }
                    />
                  </ListItem>
                  <FilterBar
                    handleJobTypeChange={handleJobTypeChange}
                    type={filterJobType}
                    handleExpChange={handleExpChange}
                    experience={experience}
                    skills={skills}
                    filtersOpen={filtersOpen}
                    setFiltersOpen={setFiltersOpen}
                    handleSkillsChange={handleSkillsChange}
                  />
                </List>
              </div>
            </div>
            <Link
              target="_blank"
              style={{ cursor: "pointer", marginTop: "6px" }}
              onClick={() => {
                amplitudeLogger.logEvent("JOIN_TELEGRAM", {
                  page: TALENTHUB_LIST_JOBS_PAGE,
                });
                window.open("https://t.me/reczee");
              }}
            >
              Get notified for new jobs
            </Link>
          </div>
          <div className={classes.allJobsList}>
            {filteredJobs.length > 0
              ? filteredJobs.map((job, jobIndex) => {
                  let reqNumDen = [0, 0];
                  if (job.requirement_list) {
                    reqNumDen = [
                      job.requirement_list.filter(
                        (requirement) => requirement.enabled
                      ).length,
                      job.requirement_list.length,
                    ];
                  }

                  return (
                    <Paper key={jobIndex} className={classes.jobListing}>
                      <Grid container alignContent="center" spacing={2}>
                        <Grid
                          item
                          xs={2}
                          md={1}
                          className={classes.companyImageContainer}
                        >
                          {job.company_logo_url ? (
                            <img
                              src={job.company_logo_url}
                              alt={job.talenthub_company.name}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = getStaticResourceUrl(
                                  "/placeholder-company.svg"
                                );
                              }}
                              className={classes.companyLogo}
                            />
                          ) : (
                            <img
                              src={getStaticResourceUrl(
                                "/placeholder-company.svg"
                              )}
                              alt={job.talenthub_company.name}
                              className={classes.companyLogo}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          md={6}
                          className={classes.jobDetailsContainer}
                        >
                          <CoreLink
                            to={`/talenthub/${job.company_slug}/jobs/${job.slug}`}
                            onClick={() => {
                              amplitudeLogger.logEvent("OPEN_JOB_DETAILS", {
                                from: "designation_title",
                                company_slug: job.company_slug,
                                job_slug: job.slug,
                              });
                            }}
                            className={classes.jobTitleAnchor}
                          >
                            <h3 className={classes.jobTitleH3}>
                              {job.designation} - {job.talenthub_company.name}
                            </h3>
                          </CoreLink>
                          <div className={classes.jobAttributesContainer}>
                            <Grid container alignContent="center" spacing={2}>
                              <Grid
                                item
                                xs={6}
                                md={3}
                                className={classes.jobAttributeContainer}
                              >
                                <BusinessCenterIcon
                                  className={classes.jobIcon}
                                  fontSize="small"
                                />
                                <span className={classes.jobAttributeValue}>
                                  {job.job_type_display}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={3}
                                className={classes.jobAttributeContainer}
                              >
                                <PaymentIcon
                                  className={classes.jobIcon}
                                  fontSize="small"
                                />
                                <span className={classes.jobAttributeValue}>
                                  {job.salary_range_display}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={3}
                                className={classes.jobAttributeContainer}
                              >
                                <LocationOnIcon
                                  className={classes.jobIcon}
                                  fontSize="small"
                                />
                                <span className={classes.jobAttributeValue}>
                                  {job.location}
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={3}
                                className={classes.jobAttributeContainer}
                              >
                                <ApartmentIcon
                                  className={classes.jobIcon}
                                  fontSize="small"
                                />
                                <span className={classes.jobAttributeValue}>
                                  {job.experience_range[0]} -{" "}
                                  {job.experience_range[1]} yrs
                                </span>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          md={3}
                          className={classes.requirementDetailsContainer}
                        >
                          <Typography
                            variant="h3"
                            className={classes.requirementsH5}
                          >
                            Requirements ({reqNumDen[0]} match of {reqNumDen[1]}
                            )
                          </Typography>
                          <RequirementListContainer
                            page="list_jobs"
                            requirementList={job.requirement_list}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={5}
                          md={2}
                          className={classes.actionButtonContainer}
                        >
                          {job.applied ? (
                            <Alert
                              className={classes.alreadyApplied}
                              icon={<DoneAllIcon fontSize="inherit" />}
                              severity="success"
                            >
                              Applied!
                            </Alert>
                          ) : (
                            <>
                              <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={
                                  isTalentHubCandidateLoggedIn() &&
                                  reqNumDen[0] !== reqNumDen[1]
                                }
                                startIcon={
                                  job.external_link ? (
                                    <DoneIcon />
                                  ) : (
                                    <DoneAllIcon />
                                  )
                                }
                                className={classes.actionButton}
                                onClick={() => {
                                  if (!isTalentHubCandidateLoggedIn()) {
                                    let currentUrl = window.location.pathname;
                                    window.location.href = `/talenthub/login?returnUrl=${currentUrl}`;
                                    return;
                                  }
                                  postRequest(
                                    `talenthub/${job.company_slug}/jobs/${job.slug}/submit-application`,
                                    {},
                                    {},
                                    (data) => {
                                      if (job.external_link) {
                                        window.open(job.external_link);
                                      } else {
                                        let prevJobs = [...jobs];
                                        prevJobs[jobIndex] = {
                                          ...jobs[jobIndex],
                                          applied: true,
                                        };
                                        setJobs(prevJobs);
                                        enqueueSnackbar(
                                          "Your application is submitted!",
                                          reczeeAlertParams("success")
                                        );
                                      }
                                    },
                                    (errorMessages, statusCode) => {
                                      if (typeof errorMessages === "string") {
                                        enqueueSnackbar(
                                          errorMessages,
                                          reczeeAlertParams("error")
                                        );
                                      } else {
                                        enqueueSnackbar(
                                          "Something went wrong!",
                                          reczeeAlertParams("error")
                                        );
                                      }
                                    }
                                  );
                                }}
                              >
                                {job.external_link ? "Apply" : "Quick Apply"}
                              </Button>
                              {isTalentHubCandidateLoggedIn() &&
                              reqNumDen[0] !== reqNumDen[1] ? (
                                <span
                                  className={classes.pleaseMatchRequirements}
                                >
                                  Please match all requirements to apply
                                </span>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            component={CoreLink}
                            to={`/talenthub/${job.company_slug}/jobs/${job.slug}`}
                            onClick={() => {
                              amplitudeLogger.logEvent("OPEN_JOB_DETAILS", {
                                company_slug: job.company_slug,
                                job_slug: job.slug,
                              });
                            }}
                            className={classes.actionButton}
                          >
                            View details
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  );
                })
              : "No matching jobs!"}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
