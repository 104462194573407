import jwt_decode from "jwt-decode";

const localStorageKeys = [
  "token",
  "email",
  "roles",
  "emailVerified",
  "companyApproved",
  "name",
  "userId",
  "slug",
];
const objectKeys = ["roles"];

export const storeAuthInfoInLocalStorage = (items) => {
  Object.entries(items).forEach(([key, value]) => {
    if (value === null) return;
    if (localStorageKeys.indexOf(key) > -1) {
      const localStorageValue =
        objectKeys.indexOf(key) > -1 ? JSON.stringify(value) : value;
      localStorage.setItem(key, localStorageValue);
    }
  });
};

export const getOauthLocalStorageKey = (transactionId) => {
  return `oauth-status-${transactionId}`;
};

export const clearAuthLocalStorage = () => {
  localStorageKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const isEmailVerified = () => {
  return localStorage.getItem("emailVerified") === "true";
};

export const isTalentHubPage = () => {
  return window.location.pathname.startsWith("/talenthub");
};

export const isScreenoPage = () => {
  return window.location.pathname.startsWith("/screeno");
};

export const loginSuccessPostSteps = (userData, setUserInfo, setReloadApp) => {
  storeAuthInfoInLocalStorage({
    userId: userData.userId,
    token: userData.token,
    email: userData.email,
    name: userData.name,
    roles: userData.roles,
    emailVerified: userData.emailVerified,
    companyApproved: userData.companyApproved,
    slug: userData.slug,
  });
  setUserInfo(userData);
  setReloadApp(1);
};

export const isLoggedIn = () => {
  let authToken = localStorage.getItem("token");
  if (!authToken) return false;

  return isValidToken(authToken);
};

export const isCandidateLoggedIn = () => {
  let roles = getFromLocalStorage("roles");
  return isLoggedIn() && roles && roles.indexOf("candidate") > -1;
};

export const isTalentHubCandidateLoggedIn = () => {
  let roles = getFromLocalStorage("roles");
  return isLoggedIn() && roles && roles.indexOf("talenthub_candidate") > -1;
};

export const isPrivilegedUser = () => {
  let roles = getFromLocalStorage("roles");
  return (
    roles &&
    (roles.indexOf("company_ta") > -1 ||
      roles.indexOf("company_admin") > -1 ||
      roles.indexOf("reczee_admin") > -1)
  );
};

export const getCompanyId = () => {
  // @Todo(Raj): Get company id from domain if present else use in the token
  let token = getFromLocalStorage("token");
  try {
    let jwtData = jwt_decode(token);
    // @Todo(Raj): Add messaging that the token is expired
    return jwtData.company_id;
  } catch (e) {
    console.debug(
      token,
      "Invalid jwt token in local storage to get companyId",
      e
    );
    return null;
  }
};

export const getUserRoles = () => {
  // @Todo(Raj): Get company id from domain if present else use in the token
  let token = getFromLocalStorage("token");
  try {
    let jwtData = jwt_decode(token);
    // @Todo(Raj): Add messaging that the token is expired
    return jwtData.roles;
  } catch (e) {
    console.debug(
      token,
      "Invalid jwt token in local storage to get companyId",
      e
    );
    return null;
  }
};

export const getJWTPayload = (jwtToken) => {
  try {
    return jwt_decode(jwtToken);
  } catch (e) {
    console.debug(jwtToken, "Invalid JWT payload", e);
    return {};
  }
};

export const isValidToken = (authToken) => {
  try {
    let jwtData = jwt_decode(authToken);
    // @Todo(Raj): Add messaging that the token is expired
    return new Date().getTime() / 1000 < jwtData.exp;
  } catch (e) {
    console.debug(
      authToken,
      "Invalid jwt token in local storage for validating token",
      e
    );
    return false;
  }
};

export const isCompanyPrivilegedUser = (roles) => {
  return (
    roles.indexOf("company_ta") > -1 || roles.indexOf("company_admin") > -1
  );
};

export const isCompanyAdmin = (roles) => {
  return roles && roles.indexOf("company_admin") > -1;
};

export const isAgencyUser = (roles) => {
  return roles && roles.indexOf("agency") > -1;
};

export const isHiringManager = (roles) => {
  return roles.indexOf("company_hiring_manager") > -1;
};

export const isAuthorizedPanel = (userInfo, candidateApplicationData) => {
  return (
    userInfo &&
    candidateApplicationData &&
    candidateApplicationData.authorized_panel_ids &&
    candidateApplicationData.authorized_panel_ids.indexOf(
      parseInt(userInfo.userId)
    ) > -1
  );
};

export const canReferCandidates = (roles) => {
  return (
    isAgencyUser(roles) ||
    roles.indexOf("company_employee") > -1 ||
    roles.indexOf("company_panel") > -1
  );
};

export const getFromLocalStorage = (key) => {
  if (localStorageKeys.indexOf(key) === -1) return null;

  const value = localStorage.getItem(key);
  if (!value || value === "undefined") return null;

  return objectKeys.indexOf(key) > -1 ? JSON.parse(value) : value;
};
