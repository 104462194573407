import React from "react";
import "date-fns";
import { Typography } from "@material-ui/core";
import {
  CareersPageInputField,
  CareersPageRadioSeletionInput,
  CareersPageCheckBox,
  CareersPageCheckBoxSelectionInput,
} from "./CareersPageInputField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  ReczeeInputField,
  ReczeeCheckBox,
  ReczeeCheckBoxSelectionInput,
  ReczeeRadioSeletionInput,
} from "../commons/ReczeeInputField";
import { justDateYYYYMMDD } from "../../utils/commonHelpers.js";

const inputFieldMapping = {
  short_answer: {
    applyPage: CareersPageInputField,
    addCandidateApplication: ReczeeInputField,
  },
  long_answer: {
    applyPage: CareersPageInputField,
    addCandidateApplication: ReczeeInputField,
  },
  number: {
    applyPage: CareersPageInputField,
    addCandidateApplication: ReczeeInputField,
  },
  yes_no: {
    applyPage: CareersPageCheckBox,
    addCandidateApplication: ReczeeCheckBox,
  },
  single_choice: {
    applyPage: CareersPageRadioSeletionInput,
    addCandidateApplication: ReczeeRadioSeletionInput,
  },
  multiple_choice: {
    applyPage: CareersPageCheckBoxSelectionInput,
    addCandidateApplication: ReczeeCheckBoxSelectionInput,
  },
};

export default function CareersPageScreeningQuestionInput(props) {
  const handleMultipleChoiceChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      if (props.value) {
        props.onChange(props.id, [...props.value, value]);
      } else {
        props.onChange(props.id, [value]);
      }
    } else {
      props.onChange(
        props.id,
        props.value.filter((item) => item !== value)
      );
    }
  };

  return (
    <>
      {(() => {
        switch (props.fieldType) {
          case "short_answer":
            return React.createElement(
              inputFieldMapping[props.fieldType][props.for],
              {
                fullWidth: true,
                title: props.title,
                size: props.size,
                name: props.id,
                required: props.required,
                value: props.value || "",
                onChange: (e) => props.onChange(props.id, e.target.value),
              }
            );
          case "long_answer":
            return React.createElement(
              inputFieldMapping[props.fieldType][props.for],
              {
                fullWidth: true,
                title: props.title,
                size: props.size,
                name: props.id,
                required: props.required,
                multiline: true,
                rows: 4,
                value: props.value || "",
                onChange: (e) => props.onChange(props.id, e.target.value),
              }
            );
          case "number":
            return React.createElement(
              inputFieldMapping[props.fieldType][props.for],
              {
                fullWidth: true,
                title: props.title,
                size: props.size,
                name: props.id,
                required: props.required,
                type: "number",
                value: props.value,
                onChange: (e) =>
                  props.onChange(props.id, e.target.valueAsNumber),
              }
            );
          case "yes_no":
            return React.createElement(
              inputFieldMapping[props.fieldType][props.for],
              {
                fullWidth: true,
                label: props.title,
                name: props.id,
                checked: props.value || false,
                required: props.required,
                onChange: (e) => props.onChange(props.id, e.target.checked),
              }
            );
          case "single_choice":
            return React.createElement(
              inputFieldMapping[props.fieldType][props.for],
              {
                fullWidth: true,
                title: props.title,
                name: props.id,
                required: props.required,
                options: Object.values(props.options).map((option) =>
                  option.toString()
                ),
                value: props.value || null,
                onChange: (e) => props.onChange(props.id, e.target.value),
              }
            );
          case "multiple_choice":
            return React.createElement(
              inputFieldMapping[props.fieldType][props.for],
              {
                fullWidth: true,
                title: props.title,
                required: props.required,
                options: Object.values(props.options).map((option) =>
                  option.toString()
                ),
                value: props.value || [],
                onChange: handleMultipleChoiceChange,
              }
            );
          case "date":
            return (
              <>
                {props.for === "applyPage" ? (
                  <Typography
                    variant="subtitle1"
                    style={{ fontSize: "14px", color: "#666666" }}
                  >
                    {props.title}
                    {props.required && <span style={{ color: "red" }}>*</span>}
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: "normal" }}
                  >
                    {props.title}
                    {props.required && <span style={{ color: "red" }}>*</span>}
                  </Typography>
                )}
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  style={{ marginTop: "10px" }}
                >
                  {/* @Todo(Raj): This is size is too big and not working as used in other places */}
                  <KeyboardDatePicker
                    size="small"
                    inputVariant="outlined"
                    format="dd-MM-yyyy"
                    style={{ backgroundColor: "white" }}
                    value={props.value ? new Date(props.value) : null}
                    onChange={(date) => {
                      props.onChange(props.id, justDateYYYYMMDD(date));
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    required={props.required}
                  />
                </MuiPickersUtilsProvider>
              </>
            );
          default:
            return <></>;
        }
      })()}
    </>
  );
}
