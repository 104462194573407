import React from "react";
import { RECZEE_REF } from "../constants";
import {
  clearAuthLocalStorage,
  getFromLocalStorage,
  isValidToken,
  storeAuthInfoInLocalStorage
} from "../utils/authHelpers";
import { markReferral } from "../utils/referralTracking";
export const UserInfoContext = React.createContext();

export const UserInfoProvider = (props) => {
  const [userInfo, setUserInfo] = React.useState({
    email: getFromLocalStorage("email"),
    token: getFromLocalStorage("token"),
    userId: getFromLocalStorage("userId"),
    name: getFromLocalStorage("name"),
    // eslint-disable-next-line
    emailVerified: getFromLocalStorage("emailVerified") == "true",
    // eslint-disable-next-line
    companyApproved: getFromLocalStorage("companyApproved") == "true",
    roles: getFromLocalStorage("roles") || [],
    slug: getFromLocalStorage("slug"),
  });

  React.useEffect(() => {
    if (isValidToken(userInfo.token)) {
      storeAuthInfoInLocalStorage({
        userId: userInfo.userId,
        token: userInfo.token,
        email: userInfo.email,
        name: userInfo.name,
        roles: userInfo.roles,
        emailVerified: userInfo.emailVerified,
        companyApproved: userInfo.companyApproved,
        slug: userInfo.slug,
      });

      let reczeeRef = localStorage.getItem(RECZEE_REF) || "[]";
      reczeeRef = JSON.parse(reczeeRef);
      if (
        reczeeRef.length > 0 &&
        userInfo.roles &&
        userInfo.roles.indexOf("talenthub_candidate") > -1
      ) {
        markReferral(reczeeRef);
      }
    } else {
      clearAuthLocalStorage();
    }

    // @Todo(Raj): Ugly way to do this. Some react expertise needed
    // Done to reload the navbar buttons on login based on roles of the new user
    // eslint-disable-next-line
  }, [userInfo]);

  return (
    <UserInfoContext.Provider value={[userInfo, setUserInfo]}>
      {props.children}
    </UserInfoContext.Provider>
  );
};
