import React from "react";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBadge-badge": {
      minWidth: "10px",
      height: "10px",
      padding: 0,
    },
  },
}));

export function SimpleBadgetDot({ iconComponent }) {
  const classes = useStyles();
  return (
    <Badge
      color="secondary"
      // invisible={!props.total_credits}
      badgeContent={""}
      className={classes.root}
    >
      {iconComponent}
    </Badge>
  );
}
