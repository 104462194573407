import { Typography } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useSnackbar } from "notistack";
import React from "react";
import { Menu as ProMenu, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { NotificationContext } from "../../contexts/NotificationContext";
import { UserInfoContext } from "../../contexts/UserInfoContext";
import amplitudeLogger from "../../utils/amplitude";
import {
  isLoggedIn,
  isTalentHubCandidateLoggedIn,
} from "../../utils/authHelpers";
import { reczeeAlertParams, isJobsDomain } from "../../utils/commonHelpers";
import { getNavbarList, NOTIFICATIONS } from "../../utils/navbarConstants";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    height: "100vh",
    width: "82px !important",
    minWidth: "82px !important",
    backgroundColor: theme.palette.primary.main,
    borderRight: "unset !important",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  menuContainer: {
    height: "inherit",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  menuItem: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  iconButton: {
    color: "white",
    margin: "auto",
    paddingBottom: "4px",
    paddingTop: "10px",
    "&:hover": {
      backgroundColor: "initial",
    },
  },
  iconLabel: {
    color: "white",
    fontSize: "11px",
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: "4px",
    paddingTop: "2px",
  },
}));

export default function ReczeeSidebar(props) {
  const classes = useStyles();
  const menuId = "primary-search-account-menu";
  const isTalentHub = window.location.pathname.startsWith("/talenthub");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [userInfo, setUserInfo] = React.useContext(UserInfoContext);
  const [notificationInfo, setNotificationInfo] =
    React.useContext(NotificationContext);
  const isJobsPage = isJobsDomain();
  const { enqueueSnackbar } = useSnackbar();

  const navbarItemClick = (value) => (event) => {
    amplitudeLogger.logEvent(value.eventName, {
      ...value.eventProperties,
      sidebar: true,
    });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (value) => (event) => {
    if (value) {
      amplitudeLogger.logEvent(value.eventName, {
        ...value.eventProperties,
        sidebar: true,
      });
    }
    setAnchorEl(null);
  };

  const handleLogout = () => {
    amplitudeLogger.logEvent(isTalentHub ? "TALENTHUB_LOGOUT" : "LOGOUT", {
      reason: "user_action",
      sidebar: true,
    });
    amplitudeLogger.endSession();
    setUserInfo({});
    setNotificationInfo(null);
    enqueueSnackbar("Successfully logged out!", reczeeAlertParams("success"));
    handleMenuClose();
  };

  const navbarList = getNavbarList("navbar", {
    ...userInfo,
    ...notificationInfo,
  });

  return (
    <div>
      <ClickAwayListener onClickAway={() => { }}>
        <Sidebar
          defaultCollapsed
          backgroundColor="transparent"
          className={classes.sectionDesktop}
        >
          <div className={classes.menuContainer}>
            <ProMenu>
              {navbarList
                .filter((item) => item.type !== NOTIFICATIONS)
                .map((item, idx) => (
                  <MenuItem
                    key={idx}
                    disableGutters
                    to={item.href}
                    component={Link}
                    className={classes.menuItem}
                    onClick={navbarItemClick(item.amplitudeParams)}
                  >
                    <IconButton disableRipple className={classes.iconButton}>
                      {item.image}
                    </IconButton>
                    <Typography className={classes.iconLabel}>
                      {item.labelNode ?? item.label}
                    </Typography>
                  </MenuItem>
                ))}
            </ProMenu>
            <ProMenu>
              {navbarList
                .filter((item) => item.type === NOTIFICATIONS)
                .map((item, idx) => (
                  <MenuItem
                    key={idx}
                    disableGutters
                    to={item.href}
                    component={Link}
                    className={classes.menuItem}
                    onClick={navbarItemClick(item.amplitudeParams)}
                  >
                    <IconButton disableRipple className={classes.iconButton}>
                      {item.image}
                    </IconButton>
                  </MenuItem>
                ))}
              {isJobsPage || (isTalentHub && !isLoggedIn()) ? null : (
                <MenuItem disableGutters onClick={handleProfileMenuOpen}>
                  <IconButton
                    aria-haspopup="true"
                    aria-controls={menuId}
                    className={classes.iconButton}
                    aria-label="account of current user"
                    disableRipple
                  >
                    <AccountCircle />
                  </IconButton>
                </MenuItem>
              )}
            </ProMenu>
          </div>
        </Sidebar>
      </ClickAwayListener>

      <Menu
        id={menuId}
        keepMounted
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose()}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      // transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {getNavbarList("profileMenu", { ...userInfo, ...notificationInfo }).map(
          (item, idx) => (
            <MenuItem
              key={idx}
              to={item.href}
              component={Link}
              style={{ minWidth: "120px" }}
              onClick={handleMenuClose(item.amplitudeParams)}
            >
              {item.label}
            </MenuItem>
          )
        )}
        <Divider />
        <MenuItem
          component={Link}
          to={
            isTalentHub && isTalentHubCandidateLoggedIn()
              ? "/talenthub/login"
              : "/login"
          }
          onClick={handleLogout}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
