import { Button, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserInfoContext } from '../../../contexts/UserInfoContext';
import amplitudeLogger from '../../../utils/amplitude';
import { getJWTPayload, loginSuccessPostSteps } from '../../../utils/authHelpers';
import { reczeeAlertParams } from '../../../utils/commonHelpers';
import { postRequest } from '../../../utils/httpHelpers';
import { authPageAvatar, formSubmitButton } from '../../../utils/styleHelpers';
import { getQueryParams } from '../../../utils/urlHelpers';
import { ReczeeCheckBox, ReczeeInputField } from '../../commons/ReczeeInputField';
import GoogleLoginButton from './GoogleLoginButton';

const useStyles = makeStyles((theme) => ({
  avatar: authPageAvatar(theme),
  submit: formSubmitButton(theme),
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  containerGrid: {
    textAlign: 'center',
    width: '100%'
  },
  googleLoginButton: {
    display: 'flex',
    justifyContent: 'center'
  },
  googleProfilePicture: {
    borderRadius: '50%'
  },
  signupContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    flexDirection: 'column'
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  registerInputField: {
    marginTop: theme.spacing(2)
  },
  termsAcceptLabel: {
    fontSize: '12px'
  },
  extraLinksContainer: {
    marginTop: theme.spacing(1.5)
  }
}));

export default function Register(props) {
  const classes = useStyles();
  const queryParams = getQueryParams();
  const [googleUserData, setGoogleUserData] = React.useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [privacyPoliceRead, setPrivacyPoliceRead] = React.useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [userInfo, setUserInfo] = React.useContext(UserInfoContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const [googleAccessToken, setGoogleAccessToken] = React.useState("");
  const isLogin = props && props.login;

  document.title = `${isLogin ? "TalentHub candidate login" : "Create your TalentHub profile"} | Reczee TalentHub`;

  const handleSubmit = e => {
    e.preventDefault();
    setIsFormSubmitted(true);

    if (!privacyPoliceRead) {
      enqueueSnackbar("Please accept the terms!", reczeeAlertParams("error"));
      setIsFormSubmitted(false);
      return;
    }

    if (Object.keys(googleUserData).length === 0) {
      enqueueSnackbar("Unable to authenticate with Google!", reczeeAlertParams("error"));
      setIsFormSubmitted(false);
      return;
    }

    postRequest("talenthub/candidates",
      {},
      {
        access_token: googleAccessToken,
        name: googleUserData.name,
        email: googleUserData.email,
        picture_url: googleUserData.picture,
        username: e.target.elements.reczee_username.value
      },
      (data) => {
        setIsFormSubmitted(false);
        loginSuccessPostSteps({
          token: data.token,
          userId: data.candidate.id,
          name: data.candidate.name,
          email: data.candidate.email,
          roles: ["talenthub_candidate"]
        }, setUserInfo, props.setReloadApp);
        amplitudeLogger.startSession("talenthub_" + data.candidate.id, "");
        amplitudeLogger.logEvent("TALENTHUB_REGISTER_SUCCESS", {});
      },
      (errorMessages) => {
        setIsFormSubmitted(false);
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      });
  };

  function handleCredentialResponse(response) {
    if (response.credential) {
      let googleResponse = getJWTPayload(response.credential);
      setGoogleAccessToken(response.credential);
      if (Object.keys(googleResponse).length === 0) {
        enqueueSnackbar("Invalid token!", reczeeAlertParams("error"));
      } else {
        setIsLoading(true);
        setGoogleUserData(googleResponse);
        postRequest("talenthub/candidates/login",
          {},
          {
            email: googleResponse.email,
            access_token: response.credential
          },
          (data) => {
            loginSuccessPostSteps({
              token: data.token,
              userId: data.candidate.id,
              name: data.candidate.name,
              email: data.candidate.email,
              roles: ["talenthub_candidate"]
            }, setUserInfo, props.setReloadApp);
            amplitudeLogger.startSession("talenthub_" + data.candidate.id, "");
            amplitudeLogger.logEvent("TALENTHUB_LOGIN_SUCCESS", {});
            setIsLoading(false);
          }, (errorMessages, statusCode) => {
            setIsLoading(false);
            if (statusCode !== 404) {
              amplitudeLogger.logEvent("TALENTHUB_LOGIN_FAILED", { status_code: statusCode, error_messages: errorMessages });
              setGoogleUserData({ error: "Something went wrong!" });
            }
          });
      }
    } else {
      enqueueSnackbar("Unable to authenticate with Google!", reczeeAlertParams("error"));
    }
  }


  const handlePrivacyPolicyChange = (e) => {
    setPrivacyPoliceRead(e.target.checked);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (userInfo && !!userInfo.token) {
    if (queryParams.returnUrl) {
      return (<Redirect push to={queryParams.returnUrl} />);
    } else {
      return (<Redirect push to="/talenthub/dashboard"></Redirect>);
    }
  }

  if (googleUserData.error) {
    return googleUserData.error;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.containerGrid}
      >
        <Grid item xs={10}>
          {googleUserData.name ? <Typography component="h4" variant="h5">{googleUserData.name}, almost done!</Typography> : ""}

          <div className={classes.signupContent}>
            {googleUserData.name ? <>
              <img src={googleUserData.picture} alt="Profile pic" className={classes.googleProfilePicture} />
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.containerGrid}
              >
                <Grid item>
                  <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <ReczeeInputField
                      name="reczee_username"
                      label="Reczee Username"
                      autoFocus={true}
                      fullWidth={true}
                      helperText="Choose that favourite username of yours 😎"
                      className={classes.registerInputField}
                    />
                    <ReczeeCheckBox
                      checked={privacyPoliceRead}
                      onChange={handlePrivacyPolicyChange}
                      name="privacy_policy"
                      label={<span className={classes.termsAcceptLabel}>I have read and accept the <Link variant="body2" className={classes.termsAcceptLabel} target="_blank" href="https://www.reczee.com/privacy-policy">Privacy Policy</Link> and <Link variant="body2" target="_blank" className={classes.termsAcceptLabel} href="https://www.reczee.com/terms-and-conditions">Terms and conditions</Link></span>}
                      className={classes.rememberMeField}
                    />
                    <Grid container
                      direction="column"
                      justify="center"
                      alignItems="center">
                      <Button
                        disabled={isFormSubmitted}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        size={"large"}
                      >
                        {isFormSubmitted ? "Please wait..." : "Register"}
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </> : <div className={classes.googleLoginButton}><GoogleLoginButton login={isLogin} callback={handleCredentialResponse} /></div>}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
