import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link as CoreLink } from 'react-router-dom';
import { reczeeAlertParams } from '../../utils/commonHelpers';
import { postRequest } from '../../utils/httpHelpers';
import { authPageAvatar, formSubmitButton } from '../../utils/styleHelpers';
import { ReczeeInputField } from '../commons/ReczeeInputField';

const useStyles = makeStyles((theme) => ({
  avatar: authPageAvatar(theme),
  submit: formSubmitButton(theme),
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  extraLinksContainer: {
    marginTop: theme.spacing(1.5)
  },
  containerGrid: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  },
  inputField: {
    marginTop: theme.spacing(1)
  },
  authHeader: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  }
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  document.title = "Forgot password | Reczee";

  const handleSubmit = e => {
    e.preventDefault();

    setIsFormSubmitted(true);
    postRequest("users/forgot-password",
      {},
      {
        email: e.target.elements.email.value
      },
      () => {
        setIsFormSubmitted(false);
        enqueueSnackbar("Email sent with reset password instructions", reczeeAlertParams("success"));
      },
      (errorMessages, statusCode) => {
        setIsFormSubmitted(false);
        console.debug("Something went wrong", statusCode);
        enqueueSnackbar("Something went wrong", reczeeAlertParams("error"));
      });
  }

  return (
    <>
      <Typography component="h1" variant="h4" className={classes.authHeader}>
        Forgot password
      </Typography>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.containerGrid}
      >
        <Grid item xs={10}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <ReczeeInputField
              id="email"
              name="email"
              label="Email"
              autoComplete="email"
              className={classes.inputField}
              fullWidth={true}
            />
            <Grid container
              direction="column"
              justify="center"
              alignItems="center">
              <Button
                disabled={isFormSubmitted}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                size="medium"
              >
                {isFormSubmitted ? "Please wait..." : "Submit"}
              </Button>
            </Grid>
          </form>
          <Grid container className={classes.extraLinksContainer}>
            <Grid item xs>
              <Link to="/login" component={CoreLink}>
                Login now
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
