import React from 'react'

import { socialIconDetails } from '../talenthub/candidate/TalenthubCandidateConstants';
import { getStaticResourceUrl } from '../../utils/urlHelpers';

export default function TalenthubSocialIcons(props) {
  const { candidateAttributes, socialIconClassName } = props
  return (
    <>
      {candidateAttributes ? socialIconDetails.map((iconDetails, index) => {
        let lowerTitle = iconDetails.value;
        if (candidateAttributes && candidateAttributes[lowerTitle]) {
          return <a key={index} rel="noreferrer" href={candidateAttributes[lowerTitle]} target="_blank">
            <img className={socialIconClassName} src={getStaticResourceUrl(`/social-icons/${lowerTitle}.${iconDetails.extension}`)} alt={lowerTitle} />
          </a>;
        } else {
          return null;
        }
      }) : ""}
    </>
  )
}