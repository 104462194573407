import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, description, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <>{description}</>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function ViewResumeModal(props) {
  const classes = useStyles();

  return <>
    <Dialog maxWidth="md" fullWidth={true} onClose={props.handleViewResumeModalClose} open={props.viewResumeModalOpen}>
      <DialogTitle onClose={props.handleViewResumeModalClose} className={classes.dialogTitleCenter}>
        Resume
      </DialogTitle>
      <DialogContent dividers>
        {props.resumeUrl && props.viewResumeModalOpen ? <iframe title="Candidate resume" src={window.location.origin + props.resumeUrl} width="100%" height="900px" frameBorder="0" scrolling="auto" /> : ""}
      </DialogContent>
    </Dialog>
  </>;
};