import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Redirect } from 'react-router';
import { useParams, Link as CoreLink } from 'react-router-dom';
import { reczeeAlertParams } from '../../utils/commonHelpers';
import { postRequest } from '../../utils/httpHelpers';
import { authPageAvatar, formSubmitButton } from '../../utils/styleHelpers';
import { ReczeeInputField } from '../commons/ReczeeInputField';

const useStyles = makeStyles((theme) => ({
  avatar: authPageAvatar(theme),
  submit: formSubmitButton(theme),
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  extraLinksContainer: {
    marginTop: theme.spacing(1.5)
  },
  authHeader: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  containerGrid: {
    marginTop: theme.spacing(3),
    textAlign: 'center'
  },
  inputField: {
    marginTop: theme.spacing(1)
  }
}));

export default function ResetPassword() {
  const classes = useStyles();
  const { resetPasswordToken } = useParams();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [resetDone, setResetDone] = React.useState(false);

  document.title = "Reset password | Reczee";

  const handleSubmit = e => {
    e.preventDefault();

    if (e.target.elements.password.value !== e.target.elements.confirm_password.value) {
      enqueueSnackbar("Passwords should match", reczeeAlertParams("error"));
      return;
    }

    setIsFormSubmitted(true);
    postRequest(`users/reset-password`,
      {},
      {
        password: e.target.elements.password.value,
        reset_password_token: resetPasswordToken
      },
      () => {
        setIsFormSubmitted(false);
        enqueueSnackbar("Password set successfully!", reczeeAlertParams("success"));
        setResetDone(true);
      },
      (errorMessages, statusCode) => {
        setIsFormSubmitted(false);
        console.debug("Something went wrong", statusCode);
        enqueueSnackbar("Something went wrong", reczeeAlertParams("error"));
      });
  }

  if (resetDone) {
    return (<Redirect push to="/login" />);
  }

  return <>
    <Typography component="h1" variant="h4" className={classes.authHeader}>
      Reset password
    </Typography>
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.containerGrid}
    >
      <Grid item xs={10}>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <ReczeeInputField
            id="password"
            name="password"
            label="Password"
            type="password"
            className={classes.inputField}
            fullWidth={true}
          />
          <ReczeeInputField
            id="confirm-password"
            name="confirm_password"
            label="Confirm password"
            type="password"
            className={classes.inputField}
            fullWidth={true}
          />
          <Grid container
            direction="column"
            justify="center"
            alignItems="center">
            <Button
              disabled={isFormSubmitted}
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              size="medium"
            >
              {isFormSubmitted ? "Please wait..." : "Submit"}
            </Button>
          </Grid>
        </form>
        <Grid container className={classes.extraLinksContainer}>
          <Grid item xs>
            <Link to="/login" component={CoreLink}>
              Login now
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>;
}
