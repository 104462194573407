import { useEffect, useRef } from "react";

// Function to load script and append in DOM tree.
const loadScript = (src) =>
  new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      console.debug("razorpay loaded successfully");
      resolve(true);
    };
    script.onerror = () => {
      console.debug("error in loading razorpay");
      resolve(false);
    };
    document.body.appendChild(script);
  });

const RenderRazorpay = ({
  reczeePaymentOrderId,
  orderId,
  keyId,
  keySecret,
  currency,
  amount,
  customerDetails,
  paymentHandler,
}) => {
  const paymentId = useRef(null);
  const paymentMethod = useRef(null);

  // To load razorpay checkout modal script.
  const displayRazorpay = async (options) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.debug("Razorpay SDK failed to load. Are you online?");
      return;
    }
    // All information is loaded in options which we will discuss later.
    const rzp1 = new window.Razorpay(options);

    // If you want to retreive the chosen payment method.
    rzp1.on("payment.submit", (response) => {
      paymentMethod.current = response.method;
    });

    // To get payment id in case of failed transaction.
    rzp1.on("payment.failed", (response) => {
      paymentId.current = response.error.metadata.payment_id;
    });

    // to open razorpay checkout modal.
    rzp1.open();
  };

  const options = {
    key: keyId, // key id from props
    amount: amount, // Amount in lowest denomination from props
    currency: "INR", // Currency from props.
    name: "Reczee Screeno", // Title for your organization to display in checkout modal
    // image, // custom logo  url
    order_id: orderId, // order id from props
    // This handler menthod is always executed in case of succeeded payment
    handler: (response) => {
      console.debug("succeeded", response);
      paymentId.current = response.razorpay_payment_id;

      // Most important step to capture and authorize the payment. This can be done of Backend server.
      // If successfully authorized. Then we can consider the payment as successful.
      paymentHandler(
        reczeePaymentOrderId,
        "success",
        {
          razorpay_order_id: orderId,
          razorpay_payment_id: paymentId.current,
          razorpay_signature: response.razorpay_signature,
        },
        {}
      );
    },
    modal: {
      animation: true,
      confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
      // This function is executed when checkout modal is closed
      // There can be 3 reasons when this modal is closed.
      ondismiss: async (reason) => {
        const {
          reason: paymentReason,
          field,
          step,
          code,
        } = reason && reason.error ? reason.error : {};
        // Reason 1 - when payment is cancelled. It can happend when we click cross icon or cancel any payment explicitly.
        if (reason === undefined) {
          paymentHandler(reczeePaymentOrderId, "cancelled", {}, {});
        }
        // Reason 2 - When modal is auto closed because of time out
        else if (reason === "timeout") {
          paymentHandler(reczeePaymentOrderId, "timedout", {}, {});
        }
        // Reason 3 - When payment gets failed.
        else {
          paymentHandler(
            reczeePaymentOrderId,
            "failed",
            {},
            {
              paymentReason,
              field,
              step,
              code,
            }
          );
        }
      },
    },
    config: {
      display: {
        hide: [
          {
            method: "wallet",
          },
          {
            method: "upi",
          },
          {
            method: "emi",
          },
          {
            method: "cardless_emi",
          },
          {
            method: "paylater",
          },
        ],
        preferences: {
          show_default_blocks: true,
        },
      },
      // display: {
      //   blocks: {
      //     most_used: {
      //       name: "Most Used Methods",
      //       instruments: [
      //         {
      //           method: "upi",
      //         },
      //         {
      //           method: "card",
      //         },
      //       ],
      //     },
      //     other: {
      //       name: "Other Payment Methods",
      //       instruments: [
      //         {
      //           method: "netbanking",
      //         },
      //       ],
      //     },
      //   },
      //   sequence: ["block.most_used", "block.other"],
      //   preferences: {
      //     show_default_blocks: false,
      //   },
      //   hide: [
      //     {
      //       method: "wallet",
      //     },
      //     {
      //       method: "emi",
      //     },
      //   ],
      //   // sequence: ["block.utib", "block.other"],
      // },
    },
    // This property allows to enble/disable retries.
    // This is enabled true by default.
    retry: {
      enabled: true,
    },
    timeout: 900, // Time limit in Seconds
    theme: {
      backdrop_color: "#000000cc",
      // color: "#0b9a0b", // Custom color for your checkout modal.
    },
    prefill: {
      //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
      name: customerDetails.name,
      email: customerDetails.email,
      // contact: "9000090000", //Provide the customer's phone number for better conversion rates
    },
  };

  useEffect(() => {
    displayRazorpay(options);
  }, []);

  return null;
};

export default RenderRazorpay;
