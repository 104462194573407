import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PaymentIcon from "@material-ui/icons/Payment";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import React from "react";
import { useParams } from "react-router-dom";
import amplitudeLogger, {
  TALENTHUB_SPECIFIC_JOB_PAGE,
} from "../../../utils/amplitude";
import { isTalentHubCandidateLoggedIn } from "../../../utils/authHelpers";
import { reczeeAlertParams } from "../../../utils/commonHelpers";
import { getRequest, postRequest } from "../../../utils/httpHelpers";
import { RequirementListContainer } from "./RequirementListContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    textAlign: "left",
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  jobPageTitle: {
    // marginBlockStart: 0,
    color: "grey",
    marginBlockEnd: 0,
    fontWeight: "600",
    fontSize: "15px",
    marginTop: theme.spacing(1),
  },
  pleaseMatchRequirements: {
    fontSize: "10px",
    textAlign: "center",
    color: "#767575",
  },
  noAcceptingApplications: {
    fontSize: "12px",
    textAlign: "center",
    marginTop: "4px",
    color: "#df0b0b",
  },
  companyLogoGrid: {
    display: "flex",
    padding: theme.spacing(1),
    justifyContent: "center",
  },
  telegramButton: {
    marginTop: theme.spacing(1),
  },
  alreadyApplied: {
    "& .MuiAlert-icon": {
      padding: 0,
    },
    "& .MuiAlert-message": {
      padding: 0,
    },
    padding: "6px 10px",
  },
  jobTitle: {
    fontSize: "24px",
  },
  companyLogo: {
    width: "60px",
    height: "60px",
  },
  jobIcon: {
    color: "#767575",
  },
  jobDescriptionGrid: {
    padding: theme.spacing(1),
  },
  disabledTickIcon: {
    color: "#ced0d7",
    marginRight: theme.spacing(1),
  },
  enabledTickIcon: {
    color: "green",
    marginRight: theme.spacing(1),
  },
  requirementListItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
    fontSize: "14px",
    padding: "0px !important",
  },
  basicDetailsContainer: {
    marginTop: "4px",
  },
  actionButtonContainer: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  jobAttributesContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(3),
    },
  },
  jobAttributeContainer: {
    display: "flex",
    alignItems: "center",
    padding: "4px !important",
    [theme.breakpoints.down("md")]: {
      padding: "4px !important",
    },
  },
  jobDescriptionContainer: {
    marginTop: theme.spacing(2),
  },
  jobDescription: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  jobAttributeValue: {
    marginLeft: "4px",
    padding: theme.spacing(1),
    fontSize: "14px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      padding: "2px",
    },
  },
  jobDetailsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  requirementList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function TalenthubJobPage() {
  const classes = useStyles();
  const [jobData, setJobData] = React.useState({});
  const { companySlug, jobSlug } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    getRequest(
      `talenthub/${companySlug}/jobs/${jobSlug}`,
      {},
      (data) => {
        setJobData(data.job);
      },
      (errorMessages, statusCode) => {
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );

    amplitudeLogger.logEvent("JOB_PAGE_LOADED", {
      companySlug: companySlug,
      jobSlug: jobSlug,
    });
    // eslint-disable-next-line
  }, []);

  if (Object.keys(jobData).length === 0) {
    return <CircularProgress />;
  }

  const submitTalenthubApplication = () => {
    if (!isTalentHubCandidateLoggedIn()) {
      window.location.href = `/talenthub/login`;
      return;
    }

    postRequest(
      `talenthub/${companySlug}/jobs/${jobSlug}/submit-application`,
      {},
      {},
      (data) => {
        if (jobData.external_link) {
          window.open(jobData.external_link);
        } else {
          setJobData({ ...jobData, applied: true });
          enqueueSnackbar(
            "Your application is submitted!",
            reczeeAlertParams("success")
          );
        }
      },
      (errorMessages, statusCode) => {
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        } else {
          enqueueSnackbar("Something went wrong!", reczeeAlertParams("error"));
        }
      }
    );
  };

  let reqNumDen = [0, 0];
  if (jobData.requirement_list) {
    reqNumDen = [
      jobData.requirement_list.filter((requirement) => requirement.enabled)
        .length,
      jobData.requirement_list.length,
    ];
  }

  document.title = `${jobData.designation} at ${jobData.talenthub_company.name} | Reczee TalentHub`;

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={3} md={1} className={classes.companyLogoGrid}>
          {jobData.company_logo_url ? (
            <img
              src={jobData.company_logo_url}
              alt={jobData.talenthub_company.name}
              className={classes.companyLogo}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={9} md={8} className={classes.jobDescriptionGrid}>
          <Typography component="h1" variant="h5" className={classes.jobTitle}>
            {jobData.designation}
          </Typography>
          <Typography>
            at <b>{jobData.talenthub_company.name}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.actionButtonContainer}>
          {jobData.applied ? (
            <Alert
              className={classes.alreadyApplied}
              icon={<DoneAllIcon fontSize="inherit" />}
              severity="success"
            >
              Applied!
            </Alert>
          ) : (
            <>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                disabled={
                  isTalentHubCandidateLoggedIn() &&
                  (reqNumDen[0] !== reqNumDen[1] || jobData.status === "closed")
                }
                startIcon={
                  jobData.external_link ? <DoneIcon /> : <DoneAllIcon />
                }
                className={classes.actionButton}
                onClick={submitTalenthubApplication}
              >
                {jobData.external_link ? "Apply" : "Quick Apply"}
              </Button>
              {isTalentHubCandidateLoggedIn() &&
              reqNumDen[0] !== reqNumDen[1] ? (
                <span className={classes.pleaseMatchRequirements}>
                  Please match all requirements to apply
                </span>
              ) : (
                ""
              )}
              {jobData && jobData.status === "closed" ? (
                <span className={classes.noAcceptingApplications}>
                  Not accepting applications right now!
                </span>
              ) : (
                ""
              )}
              <Link
                target="_blank"
                style={{ cursor: "pointer", marginTop: "6px" }}
                onClick={() => {
                  amplitudeLogger.logEvent("JOIN_TELEGRAM", {
                    page: TALENTHUB_SPECIFIC_JOB_PAGE,
                    companySlug: companySlug,
                  });
                  window.open("https://t.me/reczee");
                }}
              >
                Get notified for new jobs
              </Link>
            </>
          )}
        </Grid>
      </Grid>
      <Divider style={{ marginTop: "24px" }} />
      <Grid
        container
        alignContent="center"
        spacing={2}
        className={classes.jobDetailsContainer}
      >
        <Grid item alignContent="center" xs={12} md={6}>
          <Typography variant="h3" className={classes.jobPageTitle}>
            Basic details
          </Typography>
          <Grid
            container
            alignContent="center"
            spacing={2}
            className={classes.basicDetailsContainer}
          >
            <Grid item xs={6} className={classes.jobAttributeContainer}>
              <BusinessCenterIcon
                className={classes.jobIcon}
                fontSize="small"
              />
              <span className={classes.jobAttributeValue}>
                {jobData.job_type_display}
              </span>
            </Grid>
            <Grid item xs={6} className={classes.jobAttributeContainer}>
              <PaymentIcon className={classes.jobIcon} fontSize="small" />
              <span className={classes.jobAttributeValue}>
                {jobData.salary_range_display}
              </span>
            </Grid>
            <Grid item xs={6} className={classes.jobAttributeContainer}>
              <LocationOnIcon className={classes.jobIcon} fontSize="small" />
              <span className={classes.jobAttributeValue}>
                {jobData.location}
              </span>
            </Grid>
            <Grid item xs={6} className={classes.jobAttributeContainer}>
              <ApartmentIcon className={classes.jobIcon} fontSize="small" />
              <span className={classes.jobAttributeValue}>
                {jobData.experience_range[0]} - {jobData.experience_range[1]}{" "}
                yrs
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          alignContent="center"
          xs={12}
          md={6}
          className={classes.requirementDetailsContainer}
        >
          <Typography variant="h3" className={classes.jobPageTitle}>
            Requirements ({reqNumDen[0]} match of {reqNumDen[1]})
          </Typography>
          <Grid
            container
            alignContent="center"
            spacing={2}
            className={classes.requirementList}
          >
            <RequirementListContainer
              page={"job_page"}
              requirementList={jobData.requirement_list}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <div className={classes.jobDescriptionContainer}>
        <Typography variant="h3" className={classes.jobPageTitle}>
          Description
        </Typography>
        <div
          className={classes.jobDescription}
          dangerouslySetInnerHTML={{ __html: jobData.job_description }}
        />
      </div>
    </Paper>
  );
}
