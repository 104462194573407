import green from "@material-ui/core/colors/green";

import pink from "@material-ui/core/colors/pink";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import React, { Suspense } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Switch } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import SelfSchedule from "./components/candidate/SelfSchedule";
import ScreenoIntegrations from "./components/screeno/ScreenoIntegrations";
import AcceptInvitePage from "./components/auth/AcceptInvitePage";
import CandidateLogin from "./components/auth/CandidateLogin";
import EmailVerify from "./components/auth/EmailVerify";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import NavbarPrivateRoute from "./components/auth/NavbarPrivateRoute";
import NavbarRoute from "./components/auth/NavbarRoute";
import NoNavbarRoute from "./components/auth/NoNavbarRoute";
import OauthRedirectCallback from "./components/auth/OauthRedirectCallback";
import Register from "./components/auth/Register";
import ResetPassword from "./components/auth/ResetPassword";
import ScreenoDashboard from "./components/screeno/ScreenoDashboard";
import ScreenoJobs from "./components/screeno/ScreenoJobs";
import ScreenoJobPage from "./components/screeno/ScreenoJobPage";
import MainPage from "./components/commons/MainPage";
import { RECZEE_REF } from "./constants";
import { NotificationProvider } from "./contexts/NotificationContext";
import { UserInfoProvider } from "./contexts/UserInfoContext";
import "./stylesheets/application.scss";
import amplitudeLogger, { VIEW_ANALYTICS_CHART_PAGE } from "./utils/amplitude";
import { getQueryParams } from "./utils/urlHelpers";
import TalenthubPublicProfile from "./components/talenthub/candidate/TalenthubPublicProfile";
import ListJobs from "./components/talenthub/jobs/ListJobs";
import TalenthubJobPage from "./components/talenthub/jobs/TalenthubJobPage";
import ScreenoCreditsPage from "./components/screeno/ScreenoCreditsPage";

const AnalyticsMetricsExplorer = React.lazy(() =>
  import("./components/analytics/metrics/v2/AnalyticsMetricsExplorer")
);

const Candidate = React.lazy(() => import("./components/candidate/Candidate"));
const AssessmentGetStarted = React.lazy(() =>
  import("./components/talenthub/assessments/AssessmentGetStarted")
);
const AssessmentPage = React.lazy(() =>
  import("./components/talenthub/assessments/AssessmentPage")
);
const SubmitAssessmentQuestion = React.lazy(() =>
  import("./components/talenthub/assessments/SubmitAssessmentQuestion")
);
const CandidateDashboard = React.lazy(() =>
  import("./components/talenthub/candidate/CandidateDashboard")
);
const TalenthubEmailPreferences = React.lazy(() =>
  import("./components/talenthub/candidate/TalenthubEmailPreferences")
);
const TalenthubMyReferrals = React.lazy(() =>
  import("./components/talenthub/candidate/TalenthubMyReferrals")
);
const TalenthubVisibilitySettings = React.lazy(() =>
  import("./components/talenthub/candidate/TalenthubVisibilitySettings")
);
const CertificationPage = React.lazy(() =>
  import("./components/talenthub/certifications/CertificationPage")
);
const TalenthubVerifyCertificate = React.lazy(() =>
  import("./components/talenthub/certifications/TalenthubVerifyCertificate")
);
const UpdateCertificationDetails = React.lazy(() =>
  import("./components/talenthub/certifications/UpdateCertificationDetails")
);
const CreditListingPage = React.lazy(() =>
  import("./components/talenthub/credit/CreditListingPage")
);
const AnalyticsDashboard = React.lazy(() =>
  import("./components/analytics/metrics/v2/AnalyticsDashboard")
);

const ViewChart = React.lazy(() =>
  import("./components/analytics/metrics/v2/ViewChart")
);

const AddEvaluation = React.lazy(() =>
  import("./components/candidate/evaluation/AddEvaluation")
);
const CompanySettings = React.lazy(() =>
  import("./components/company/CompanySettings")
);
const Dashboard = React.lazy(() => import("./components/dashboard/Dashboard"));

const AdminPostJob = React.lazy(() =>
  import("./components/talenthub/jobs/AdminPostJob")
);
const TalenthubPostJob = React.lazy(() =>
  import("./components/talenthub/jobs/TalenthubPostJob")
);
const AdminLeads = React.lazy(() =>
  import("./components/talenthub/leads/AdminLeads")
);

const AdminTalenthubOperations = React.lazy(() =>
  import("./components/admin/talenthub/AdminTalenthubOperations")
);

const AdminPendingCredits = React.lazy(() =>
  import("./components/talenthub/credit/AdminPendingCredits")
);

const AdminReviewTaggings = React.lazy(() =>
  import("./components/talenthub/credit/AdminReviewTaggings")
);
const SkillDashboard = React.lazy(() =>
  import("./components/talenthub/skills/SkillDashboard")
);
const AuthUiPlaceholder = React.lazy(() =>
  import("./components/auth/AuthUiPlaceholder")
);
const CandidateSpreadsheetView = React.lazy(() =>
  import("./components/candidate/CandidateSpreadsheetView")
);
const TalenthubDashboard = React.lazy(() =>
  import("./components/talenthub/Dashboard")
);
const TalenthubCompanyDashboard = React.lazy(() =>
  import("./components/talenthub/company/TalenthubCompanyDashboard")
);
const NotificationsPage = React.lazy(() =>
  import("./components/notifications/NotificationsPage")
);
const RequisitionBulkImport = React.lazy(() =>
  import("./components/requisitions/RequisitionBulkImport")
);
const QuestionPage = React.lazy(() =>
  import("./components/setup/QuestionPage")
);
const UserProfile = React.lazy(() => import("./components/user/UserProfile"));
const SubmitInterviewEvaluation = React.lazy(() =>
  import("./components/talenthub/certifications/SubmitInterviewEvaluation")
);
const RecruitmentSetup = React.lazy(() =>
  import("./components/setup/RecruitmentSetup")
);

const ProfileReviewPage = React.lazy(() =>
  import("./components/talenthub/credit/ProfileReviewPage")
);
const RequisitionPage = React.lazy(() =>
  import("./components/requisitions/RequisitionPage")
);
const RequisitionManagement = React.lazy(() =>
  import("./components/requisitions/RequisitionManagement")
);
const AddRequisition = React.lazy(() =>
  import("./components/requisitions/AddRequisition")
);
const EditRequisition = React.lazy(() =>
  import("./components/requisitions/EditRequisition")
);
const ResumeSmartSearch = React.lazy(() =>
  import("./components/candidate/ResumeSmartSearch")
);
const CSVBuilder = React.lazy(() => import("./components/csv/CSVBuilder"));
const LayoffLandingPage = React.lazy(() =>
  import("./components/layoff/LayoffLandingPage")
);

const PRIMARY_MAIN = "#0f5dbc";
const PRIMARY_BLACK = "#212121";
const SECONDARY_MAIN = pink[500];
const SUCCESS_GREEN = "#0b9a0b"; //"#008000";
const BLACK_2 = "#616161";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN,
    },
    secondary: {
      main: SECONDARY_MAIN,
    },
    success: {
      main: SUCCESS_GREEN,
    },
  },
  overrides: {
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "$labelcolor",
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "25px",
      },
    },
    MuiMenu: {
      paper: {
        maxHeight: "300px",
      },
    },
    MuiTabs: {
      root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      },
    },
    MuiTab: {
      root: {
        border: 0,
        paddingTop: "10px",
        fontWeight: "bold",
      },
      wrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        textTransform: "capitalize",
      },
      labelIcon: {
        minHeight: "50px",
      },
    },
    MuiTableCell: {
      head: {
        color: BLACK_2,
        fontWeight: "600",
      },
      sizeSmall: {
        padding: "6px 16px 6px 16px",
      },
    },
    MuiCard: {
      root: {
        borderRadius: "10px",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
      },
    },
    MuiCardContent: {
      root: {
        padding: "12px",
      },
    },
    MuiCardActions: {
      root: {
        padding: "4px",
      },
    },
    MuiSlider: {
      markLabel: {},
      thumb: {
        color: "green",
      },
      track: {
        color: green[800],
      },
      rail: {
        color: "black",
      },
      markLabelActive: {
        fontWeight: "bolder",
      },
      // @Todo(Raj): This is used for css for the steps which are active
      // markActive: {
      //   backgroundColor: 'red',
      //   width: '12px',
      //   height: '12px',
      //   borderRadius: '50%'
      // }
    },
    MuiButton: {
      label: {
        textTransform: "none",
        fontWeight: "600",
      },
      startIcon: {
        marginRight: "6px",
      },
      textSecondary: {},
    },
    MuiTypography: {
      h5: {
        fontWeight: "bold",
      },
      h6: {
        fontWeight: "bold",
      },
      subtitle1: {
        fontWeight: "bold",
      },
    },
    MuiStepLabel: {
      label: {
        // fontSize: "0.875rem",
        color: PRIMARY_BLACK,
      },
      active: {
        color: `${PRIMARY_MAIN} !important`,
        fontWeight: "bold !important",
      },
    },
    MuiStepIcon: {
      active: {
        backgroundColor: `${PRIMARY_MAIN} !important`,
      },
      completed: {
        color: `${SUCCESS_GREEN} !important`,
      },
    },
    MuiAccordion: {
      root: {
        marginBottom: "10px",
        borderRadius: "5px !important",
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: "0px !important",
      },
      content: {
        margin: "0px !important",
        textTransform: "capitalize",
      },
    },
    MuiAccordionDetails: {
      root: {
        paddingTop: 0,
      },
    },
  },
});

ReactGA.initialize("G-HDF9ZW7BPB");

const App = () => {
  // Using this state variable for reloading sidebar and navbar
  const [reloadApp, setReloadApp] = React.useState(0);
  // eslint-disable-next-line
  amplitudeLogger.init();

  React.useEffect(() => {
    if (window.location.hostname !== "localhost") {
      ReactGA.send({ hitType: "pageview" });
    }
    const params = getQueryParams();
    let reczeeRef = localStorage.getItem(RECZEE_REF) || "[]";
    reczeeRef = JSON.parse(reczeeRef);
    if (params._ref) {
      let setRef = new Set(reczeeRef);
      setRef.add(params._ref);
      localStorage.setItem(RECZEE_REF, JSON.stringify(Array.from(setRef)));
    }
  }, []);

  return (
    <BrowserRouter>
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Suspense
            fallback={
              <div
                style={{
                  position: "fixed",
                  left: "48%",
                  top: "48%",
                  width: "100%",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            }
          >
            <UserInfoProvider>
              <NotificationProvider
                reloadApp={reloadApp}
                setReloadApp={setReloadApp}
              >
                <SnackbarProvider maxSnack={3}>
                  <Switch>
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/visibility-settings"
                      component={TalenthubVisibilitySettings}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/submit-assessment-question"
                      component={SubmitAssessmentQuestion}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/skill-dashboard"
                      component={SkillDashboard}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/interview-evaluation"
                      component={SubmitInterviewEvaluation}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/update-certification"
                      component={UpdateCertificationDetails}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/admin/add-job"
                      component={AdminPostJob}
                    />
                    <NavbarPrivateRoute
                      path="/talenthub/admin/get-leads"
                      component={AdminLeads}
                    />
                    <NavbarPrivateRoute
                      path="/talenthub/admin/talenthub-operations"
                      component={AdminTalenthubOperations}
                    />
                    <NavbarPrivateRoute
                      path="/talenthub/admin/pending-credits"
                      component={AdminPendingCredits}
                    />
                    <NavbarPrivateRoute
                      path="/talenthub/admin/review-taggings/:talenthubCandidateId"
                      component={AdminReviewTaggings}
                    />
                    <NavbarRoute
                      path="/talenthub/:companySlug/jobs/:jobSlug"
                      component={TalenthubJobPage}
                    />
                    <NavbarRoute
                      path="/talenthub/certificate/:certificateSlug/verify"
                      component={TalenthubVerifyCertificate}
                    />
                    <NavbarRoute
                      path="/talenthub/review-candidates"
                      component={ProfileReviewPage}
                    />
                    <NavbarRoute
                      path="/talenthub/certifications/:skillSlug/get-started"
                      component={CertificationPage}
                    />
                    <NavbarRoute path="/talenthub/jobs" component={ListJobs} />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/credits"
                      component={CreditListingPage}
                      fullWidth={true}
                    />
                    <NavbarPrivateRoute
                      path="/talenthub/referrals"
                      component={TalenthubMyReferrals}
                      fullWidth={true}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/assessments/:skillSlug/get-started"
                      component={AssessmentGetStarted}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/assessments/:assessmentId"
                      component={AssessmentPage}
                    />
                    <NoNavbarRoute
                      path="/candidate-self-schedule/:candidateApplicationSlug"
                      component={SelfSchedule}
                      fullWidth={true}
                    />
                    <NoNavbarRoute
                      path="/talenthub/login"
                      component={() => (
                        <AuthUiPlaceholder
                          component={() => (
                            <Login
                              talenthubCandidate={true}
                              setReloadApp={setReloadApp}
                            />
                          )}
                        />
                      )}
                    />
                    <NavbarRoute
                      path="/talenthub/email-preferences/:unsubscribeToken"
                      component={TalenthubEmailPreferences}
                    />
                    <NavbarRoute
                      path="/talenthub/email-preferences"
                      component={TalenthubEmailPreferences}
                    />
                    <NoNavbarRoute
                      path="/talenthub/register"
                      component={() => (
                        <AuthUiPlaceholder
                          component={() => (
                            <Register
                              talenthubCandidate={true}
                              setReloadApp={setReloadApp}
                            />
                          )}
                        />
                      )}
                    />
                    <NoNavbarRoute
                      path="/screeno/register"
                      component={() => (
                        <AuthUiPlaceholder
                          component={() => (
                            <Register
                              is_screeno={true}
                              setReloadApp={setReloadApp}
                            />
                          )}
                        />
                      )}
                    />
                    <NavbarRoute
                      path="/talenthub/post-job"
                      component={TalenthubPostJob}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/company/dashboard"
                      component={TalenthubCompanyDashboard}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/candidate/dashboard"
                      component={CandidateDashboard}
                    />
                    <NavbarPrivateRoute
                      talenthub={true}
                      path="/talenthub/dashboard"
                      component={TalenthubDashboard}
                    />
                    <NavbarRoute
                      path="/talenthub/:username"
                      component={TalenthubPublicProfile}
                    />

                    <NoNavbarRoute
                      path="/layoffs"
                      component={LayoffLandingPage}
                    />

                    <NoNavbarRoute
                      path="/login"
                      component={() => (
                        <AuthUiPlaceholder
                          component={() => (
                            <Login setReloadApp={setReloadApp} />
                          )}
                        />
                      )}
                    />
                    <NoNavbarRoute
                      path="/forgot-password"
                      component={() => (
                        <AuthUiPlaceholder component={ForgotPassword} />
                      )}
                    />
                    <NoNavbarRoute
                      path="/reset-password/:resetPasswordToken"
                      component={() => (
                        <AuthUiPlaceholder component={ResetPassword} />
                      )}
                    />
                    <NoNavbarRoute
                      path="/email-verify/:verifyToken"
                      component={() => (
                        <AuthUiPlaceholder component={EmailVerify} />
                      )}
                    />
                    <NoNavbarRoute
                      path="/candidate-login"
                      component={() => (
                        <AuthUiPlaceholder
                          component={() => (
                            <CandidateLogin setReloadApp={setReloadApp} />
                          )}
                        />
                      )}
                    />
                    <NavbarPrivateRoute
                      path="/all-candidates-view"
                      component={CandidateSpreadsheetView}
                    />
                    <NavbarPrivateRoute
                      path="/candidates"
                      component={CandidateSpreadsheetView}
                    />
                    <NoNavbarRoute
                      path="/register"
                      component={() => (
                        <AuthUiPlaceholder
                          component={() => (
                            <Register setReloadApp={setReloadApp} />
                          )}
                        />
                      )}
                    />

                    <NavbarPrivateRoute
                      path="/dashboard"
                      component={Dashboard}
                    />

                    <NavbarPrivateRoute
                      path="/requisitions/:requisitionId/bulk-import/:currentJobId?"
                      component={RequisitionBulkImport}
                    />
                    <NavbarPrivateRoute
                      path="/jobs/new"
                      component={AddRequisition}
                    />
                    <NavbarPrivateRoute
                      path="/jobs/:requisitionId/edit"
                      component={EditRequisition}
                    />
                    <NavbarPrivateRoute
                      path="/jobs/:requisitionId/bulk-import/:currentJobId?"
                      component={RequisitionBulkImport}
                    />
                    <NavbarPrivateRoute
                      path="/requisitions/:requisitionId/:selectedTab?"
                      component={RequisitionPage}
                    />
                    <NavbarPrivateRoute
                      path="/jobs/:requisitionId/:selectedTab?"
                      component={RequisitionPage}
                    />
                    <NavbarPrivateRoute
                      path="/requisitions"
                      component={RequisitionManagement}
                    />
                    <NavbarPrivateRoute
                      path="/jobs"
                      component={RequisitionManagement}
                    />

                    <NavbarPrivateRoute
                      path="/users/:userId"
                      component={UserProfile}
                    />

                    <NavbarPrivateRoute
                      path="/screeno/jobs/:screenoJobId"
                      component={ScreenoJobPage}
                    />

                    <NavbarPrivateRoute
                      path="/screeno/jobs"
                      component={ScreenoJobs}
                    />

                    <NavbarPrivateRoute
                      path="/screeno/integrations"
                      component={ScreenoIntegrations}
                    />

                    <NavbarPrivateRoute
                      path="/screeno/credits"
                      component={ScreenoCreditsPage}
                      fullWidth={true}
                    />

                    <NavbarPrivateRoute
                      path="/screeno"
                      component={ScreenoDashboard}
                    />

                    <NavbarPrivateRoute
                      path="/questions/:questionId"
                      component={QuestionPage}
                    />

                    <NavbarPrivateRoute
                      path="/company-settings/:section?"
                      component={CompanySettings}
                    />

                    {/* <NavbarPrivateRoute
                      path="/talent-pool"
                      component={TalentPoolManagement}
                    /> */}

                    <NavbarPrivateRoute
                      path="/smart-search"
                      component={ResumeSmartSearch}
                    />
                    <NavbarRoute
                      path="/candidate-application-status/:candidateApplicationSlug"
                      allowCandidate={true}
                      component={Candidate}
                    />
                    {/* <NavbarRoute
                      path="/candidate-archive"
                      component={CandidateApplicationArchive}
                    /> */}
                    <NavbarPrivateRoute
                      path="/candidate-timeline/:candidateApplicationSlug/:section?"
                      component={Candidate}
                    />
                    <NavbarPrivateRoute
                      path="/evaluation/:candidateApplicationSlug/round/:interviewRoundId"
                      component={AddEvaluation}
                    />

                    {/* <NavbarPrivateRoute
                      path="/create-drive"
                      component={CreateDrive}
                    /> */}
                    {/* <NavbarPrivateRoute
                      path="/drive/:driveId"
                      component={DrivePage}
                    /> */}

                    <NavbarPrivateRoute
                      path="/recruitment-setup/:section?"
                      component={RecruitmentSetup}
                    />

                    <NavbarPrivateRoute
                      path="/csv-builder"
                      component={CSVBuilder}
                    />

                    <NavbarPrivateRoute
                      path="/oauth-redirect-callback"
                      component={OauthRedirectCallback}
                    />

                    <NavbarPrivateRoute
                      path="/notifications"
                      component={NotificationsPage}
                    />

                    {/* @Todo(Raj): Restrict page by roles as well */}
                    {/* <NavbarPrivateRoute
                      path="/analytics/:metricId"
                      component={MetricPage}
                    />
                    <NavbarPrivateRoute
                      path="/analyticsold"
                      component={AnalyticsOld}
                   /> */}

                    <NavbarPrivateRoute
                      path="/analytics/charts"
                      component={() => (
                        <ViewChart
                          standalonePage={true}
                          amplitudePage={VIEW_ANALYTICS_CHART_PAGE}
                          visibleAttributes={[
                            "tableView",
                            "editInMetricsExplorer",
                          ]}
                        />
                      )}
                    />

                    <NavbarPrivateRoute
                      path="/analytics/metrics-explorer/edit"
                      component={AnalyticsMetricsExplorer}
                    />

                    <NavbarPrivateRoute
                      path="/analytics/metrics-explorer"
                      component={AnalyticsMetricsExplorer}
                    />

                    <NavbarPrivateRoute
                      path="/analytics"
                      component={AnalyticsDashboard}
                    />

                    <NoNavbarRoute
                      path="/accept-invite/:inviteToken"
                      component={() => (
                        <AuthUiPlaceholder component={AcceptInvitePage} />
                      )}
                    />

                    <NavbarRoute path="/" component={MainPage} />
                  </Switch>
                </SnackbarProvider>
              </NotificationProvider>
            </UserInfoProvider>
          </Suspense>
        </ThemeProvider>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default App;
