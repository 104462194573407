import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactGA from "react-ga4";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PageNotFound from "./components/commons/errors/PageNotFound";
import ApplyPage from "./components/jobs/ApplyPage";
import CareersPage from "./components/jobs/CareersPage";
import "./stylesheets/application.scss";
import amplitudeLogger from "./utils/amplitude";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
  },
}));

function generateThemeJson() {
  return {
    palette: {
      primary: {
        main: "#0f5dbc",
      },
      secondary: {
        main: pink[500],
      },
    },
    overrides: {
      MuiFormHelperText: {
        contained: {
          marginLeft: 0,
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "$labelcolor",
          },
        },
      },
      MuiButton: {
        root: {
          fontWeight: "bolder !important",
          fontSize: "14px",
          textTransform: "none",
          padding: "8px 12px",
          borderRadius: "8px",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: "8px",
        },
        input: {
          borderRadius: "8px",
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: "25px",
        },
      },
      MuiMenu: {
        paper: {
          maxHeight: "300px",
        },
      },
      MuiTab: {
        root: {
          "&$selected": {
            fontWeight: "bold",
          },
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: "bolder",
        },
        sizeSmall: {
          padding: "6px 16px 6px 16px",
        },
      },
      MuiCardContent: {
        root: {
          padding: "12px",
        },
      },
      MuiCardActions: {
        root: {
          padding: "4px",
        },
      },
      MuiSlider: {
        markLabel: {},
        thumb: {
          color: "green",
        },
        track: {
          color: green[800],
        },
        rail: {
          color: "black",
        },
        markLabelActive: {
          fontWeight: "bolder",
        },
        // @Todo(Raj): This is used for css for the steps which are active
        // markActive: {
        //   backgroundColor: 'red',
        //   width: '12px',
        //   height: '12px',
        //   borderRadius: '50%'
        // }
      },
    },
  };
}

ReactGA.initialize("G-HDF9ZW7BPB");

export default function JobsApp() {
  const classes = useStyles();
  amplitudeLogger.init();

  React.useEffect(() => {
    if (window.location.hostname !== "localhost") {
      ReactGA.send({ hitType: "pageview" });
    }
  }, []);

  let theme = createMuiTheme(generateThemeJson());

  return (
    <BrowserRouter>
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <div className={classes.paper}>
              <Switch>
                <Route
                  path="/:companySlug/:requisitionSlug/apply"
                  component={ApplyPage}
                />
                {/* <Route
                  path="/:companySlug/demo-wordpress"
                  component={() => <CareersPage demoAzira={true} />}
                /> */}
                <Route
                  path="/:companySlug/job-embed"
                  component={() => <CareersPage embed={true} />}
                />
                <Route exact path="/:companySlug" component={CareersPage} />
                <Route path="/" component={PageNotFound} />
              </Switch>
            </div>
          </SnackbarProvider>
        </ThemeProvider>
      </React.Fragment>
    </BrowserRouter>
  );
}
