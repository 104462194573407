import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  careersPageSection: {
    minHeight: '500px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main + '09'
  },
  coreValueItemContainer: {
    padding: theme.spacing(3),
  },
  benefitsItemContainer: {
    padding: '10px 40px !important',
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  benefitTextContainer: {
    marginLeft: theme.spacing(2),
    fontSize: '24px',
    textAlign: 'left'
  },
  benefitNumber: {
    fontSize: '65px',
    color: '#858585'
  },
  itemDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '70%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

function DefaultBenefits() {
  const classes = useStyles();
  return <div className={classes.careersPageSection}>
    <Typography component="h3" variant="h4" style={{ padding: '16px' }}>
      Benefits & perks
    </Typography>
    Coming soon!
  </div>;
}

function StopStalkBenefits(props) {
  const classes = useStyles();
  return <div className={classes.careersPageSection}>
    <Typography component="h3" variant="h4" style={{ padding: '16px' }}>
      Benefits & perks
    </Typography>
    <Grid container style={{ width: '90%', justifyContent: 'center', marginTop: '8px', marginBottom: '8px' }}>
      <Grid item xs={12} sm={3} className={classes.benefitsItemContainer}>
        <span className={classes.benefitNumber}>01</span>
        <div className={classes.benefitTextContainer}>
          Competitive salary
        </div>
      </Grid>
      <Grid item xs={12} sm={3} className={classes.benefitsItemContainer}>
        <span className={classes.benefitNumber}>02</span>
        <div className={classes.benefitTextContainer}>
          Medical insurance
        </div>
      </Grid>
      <Grid item xs={12} sm={3} className={classes.benefitsItemContainer}>
        <span className={classes.benefitNumber}>03</span>
        <div className={classes.benefitTextContainer}>
          Employee referral
        </div>
      </Grid>
      <Grid item xs={12} sm={3} className={classes.benefitsItemContainer}>
        <span className={classes.benefitNumber}>04</span>
        <div className={classes.benefitTextContainer}>
          Learning program
        </div>
      </Grid>
      <Grid item xs={12} sm={3} className={classes.benefitsItemContainer}>
        <span className={classes.benefitNumber}>05</span>
        <div className={classes.benefitTextContainer}>
          Remote first
        </div>
      </Grid>
      <Grid item xs={12} sm={3} className={classes.benefitsItemContainer}>
        <span className={classes.benefitNumber}>06</span>
        <div className={classes.benefitTextContainer}>
          Generous leave program
        </div>
      </Grid>
    </Grid>
  </div>;
}

export default function BenefitsAndPerks(props) {
  if (!props || !props.companyDetails) {
    return <DefaultBenefits />
  }

  switch (props.companyDetails.slug) {
    case "company75":
    // case "sapient":
    // eslint-disable-next-line
    case "stopstalk":
      return <StopStalkBenefits {...props} />
    default:
      return "";
  }
}