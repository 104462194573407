import React from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  link: {
    color: "inherit",
    textDecoration: "none",
  },
}));

export default function Breadcrumbs({ links = [] }) {
  const classes = useStyles();

  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNext fontSize="small" />}
    >
      {links.map((link, index) =>
        index === links.length - 1 ? (
          <Typography key={index} color="textSecondary" variant="subtitle1">
            {link.label}
          </Typography>
        ) : (
          <Link key={index} to={link.url} className={classes.link}>
            {link.label}
          </Link>
        )
      )}
    </MuiBreadcrumbs>
  );
}
