import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { UserInfoContext } from '../../contexts/UserInfoContext';
import { postRequest } from '../../utils/httpHelpers';
import { authPageAvatar, formSubmitButton } from '../../utils/styleHelpers';

const useStyles = makeStyles((theme) => ({
  avatar: authPageAvatar(theme),
  submit: formSubmitButton(theme),
  form: {
    width: '100%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  authHeader: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  containerGrid: {
    marginTop: theme.spacing(12),
    textAlign: 'center'
  },
  inputField: {
    marginTop: theme.spacing(1)
  }
}));

export default function EmailVerify() {
  const classes = useStyles();
  const { verifyToken } = useParams();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isFetching, setIsFetching] = React.useState(true);
  const [userInfo, setUserInfo] = React.useContext(UserInfoContext);

  document.title = "Email Verify | Reczee";

  React.useEffect(() => {
    postRequest(`users/verify-email`,
      {},
      {
        verify_token: verifyToken
      },
      (data) => {
        localStorage.setItem("emailVerified", data["email_verified"]);
        localStorage.setItem("companyApproved", data["company_approved"]);

        setIsFetching(false);
        setErrorMessage("Success");
        setUserInfo({ ...userInfo, emailVerified: data["email_verified"], companyApproved: data["company_approved"] });
      },
      (errorMessages, statusCode) => {
        setIsFetching(false);
        if (typeof errorMessages === "string") {
          setErrorMessage(errorMessages);
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  if (errorMessage === "Success") {
    return (<Redirect push to="/dashboard" />);
  }

  return (
    <>
      <Typography component="h1" variant="h4" className={classes.authHeader}>
        Email verification
      </Typography>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.containerGrid}
      >
        <Grid item xs={10}>
          {isFetching ? <CircularProgress /> : errorMessage}
        </Grid>
      </Grid>
    </>
  );
};
