import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";
import GitHubIcon from "@material-ui/icons/GitHub";
import PublicIcon from "@material-ui/icons/Public";
import { useSnackbar } from "notistack";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Link, useParams } from "react-router-dom";
import { getMonthLabel, reczeeAlertParams } from "../../../utils/commonHelpers";
import { getRequest } from "../../../utils/httpHelpers";
import { getStaticResourceUrl } from "../../../utils/urlHelpers";
import ViewResumeModal from "../../candidate/ViewResumeModal";
import { ProfileNotFound } from "../ProfileNotFound";
import { SkillBadge } from "../skills/SkillBadge";
import SkillsHowItWorksModal from "../skills/SkillsHowItWorksModal";
import TalenthubSocialIcons from "../../commons/TalenthubSocialIcons";

const useStyles = makeStyles((theme) => ({
  pictureContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(8),
    },
  },
  reactMarkdownContainer: {
    "& pre": {
      wordWrap: "break-word",
      whiteSpace: "break-spaces",
    },
  },
  candidateName: {
    marginTop: theme.spacing(1),
    textAlign: "center",
  },
  justTitleContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  howThisWorks: {
    marginLeft: theme.spacing(1),
    fontSize: "10px",
    color: "grey",
    "&:hover": {
      cursor: "pointer",
    },
  },
  socialIcon: {
    height: "28px",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      height: "24px",
      marginRight: "4px",
      marginLeft: "4px",
    },
  },
  projectLinkActionIcon: {
    width: "18px",
    height: "18px",
  },
  githubLinkActionIcon: {
    color: "black",
    width: "14px",
    height: "14px",
  },
  bioContainer: {
    fontSize: "16px",
  },
  skillSectionTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // marginBottom: theme.spacing(2),
  },
  skillSectionText: {
    marginLeft: "6px",
  },
  reczeeVerifiedIcon: {
    height: "20px",
  },
  emailLink: {
    color: theme.palette.primary.main,
  },
  profileSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "100%",
    },
  },
  candidateInformationContainer: {
    // marginTop: theme.spacing(2)
  },
  skillContainer: {
    marginBottom: theme.spacing(2),
    marginTop: "10px",
  },
  companyDetails: {
    fontSize: "1rem",
  },
  socialIconContainer: {
    marginTop: theme.spacing(1),
    textAlign: "center",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  bioMarkdown: {
    whiteSpace: "pre-line",
  },
  contactDetails: {
    marginTop: "6px",
    fontSize: "16px",
  },
  viewResumeButton: {
    marginTop: theme.spacing(1),
  },
  candidateAvatar: {
    borderRadius: "50%",
    height: "150px",
    width: "150px",
  },
  projectRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  inputFieldGrid: {
    alignContent: "flex-start",
  },
  inputFieldSection: {
    marginBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  projectTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  projectTitle: {
    fontSize: "1rem",
    fontWeight: "600",
    marginRight: theme.spacing(1),
  },
  projectLink: {
    color: theme.palette.primary.main,
    display: "inline-flex",
    marginLeft: "3px",
    marginRight: "3px",
    marginTop: "2px",
  },
  sectionDivider: {
    width: "100%",
    height: "3px",
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
}));

const experienceDifference = (start, end) => {
  if (!start || !end) return "-";
  if (end === "Present") end = new Date();
  start = new Date(start);
  end = new Date(end);

  const pluralize = (value, timeString) => {
    return `${value} ${value > 1 ? timeString + "s" : timeString}`;
  };

  var months = (end.getFullYear() - start.getFullYear()) * 12;
  months -= start.getMonth();
  months += end.getMonth();
  months++;

  var year = parseInt(months / 12);
  if (year > 0) {
    return pluralize(year, "year") + " " + pluralize(months % 12, "month");
  } else {
    return pluralize(months % 12, "month");
  }
};

export default function TalenthubPublicProfile(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [candidateData, setCandidateData] = React.useState({});
  const [pageError, setPageError] = React.useState("");
  const [isFetching, setIsFetching] = React.useState(false);
  const [resumeModalOpen, setResumeModalOpen] = React.useState(false);

  let { username } = useParams();

  if (props.username) {
    username = props.username;
  }

  const [howItWorksModalOpen, setHowItWorksModalOpen] = React.useState(false);
  let candidateAttributes = candidateData.visible_attributes;

  React.useEffect(() => {
    setIsFetching(true);
    getRequest(
      `talenthub/candidate/profile/${username}`,
      {},
      (data) => {
        setIsFetching(false);
        setCandidateData(data.candidate);
      },
      (errorMessages, statusCode) => {
        setIsFetching(false);
        if (statusCode === 404) {
          setPageError(404);
        }
        if (typeof errorMessages === "string") {
          enqueueSnackbar(errorMessages, reczeeAlertParams("error"));
        }
      }
    );
    // eslint-disable-next-line
  }, []);

  if (isFetching) {
    return <CircularProgress />;
  }

  if (pageError === 404) {
    return <ProfileNotFound />;
  }

  if (Object.keys(candidateData).length === 0) {
    return <CircularProgress />;
  }

  let contactDetails = [];
  if (candidateAttributes && candidateAttributes.email) {
    contactDetails.push(candidateAttributes.email);
  }

  if (candidateAttributes && candidateAttributes.phone) {
    contactDetails.push(candidateAttributes.phone);
  }

  if (candidateAttributes && candidateAttributes.candidate_location) {
    contactDetails.push(candidateAttributes.candidate_location.city);
  }

  if (!props.username) {
    if (candidateAttributes.name) {
      document.title = `${candidateAttributes.name} (${candidateData.username}) | Reczee TalentHub`;
    } else {
      document.title = `${candidateData.username} | Reczee TalentHub`;
    }
  }

  let verifiedSkills = [];
  let unverifiedSkills = [];

  if (candidateAttributes.skills) {
    candidateAttributes.skills.forEach((skill) => {
      if (skill.proficiency > 0) {
        verifiedSkills.push(skill);
      } else {
        unverifiedSkills.push(skill);
      }
    });
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        className={classes.candidateInformationContainer}
      >
        <Grid
          container
          item
          sm={12}
          md={4}
          spacing={3}
          className={classes.pictureContainer}
        >
          <img
            src={`${candidateAttributes.avatar_url}`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = getStaticResourceUrl("/default-avatar.svg");
            }}
            alt="Avatar"
            className={classes.candidateAvatar}
          />
          <Typography
            className={classes.candidateName}
            component="h1"
            variant="h4"
          >
            {candidateAttributes.name}
          </Typography>
          <Typography variant="subtitle1">@{candidateData.username}</Typography>
          {candidateAttributes.resume_url ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                size={"medium"}
                className={classes.viewResumeButton}
                onClick={() => {
                  setResumeModalOpen(true);
                }}
              >
                View Resume
              </Button>
              <ViewResumeModal
                resumeUrl={candidateAttributes.resume_url}
                viewResumeModalOpen={resumeModalOpen}
                handleViewResumeModalClose={() => {
                  setResumeModalOpen(false);
                }}
              />
            </>
          ) : (
            ""
          )}

          {candidateAttributes && candidateAttributes.current_company ? (
            <span className={classes.contactDetails}>
              {candidateAttributes.current_company &&
              candidateAttributes.current_company.name ? (
                <>
                  Works at: <b>{candidateAttributes.current_company.name}</b>
                </>
              ) : (
                ""
              )}
            </span>
          ) : (
            ""
          )}
          {candidateAttributes.career_start ? (
            <span className={classes.contactDetails}>
              Total experience: <b>{candidateAttributes.career_start}</b>
            </span>
          ) : (
            ""
          )}
          <span className={classes.contactDetails}>
            {contactDetails.join(" • ")}
          </span>
          <div className={classes.socialIconContainer}>
            <TalenthubSocialIcons
              candidateAttributes={candidateAttributes.links}
              socialIconClassName={classes.socialIcon}
            />
          </div>
        </Grid>
        <Grid container item sm={12} md={7} className={classes.inputFieldGrid}>
          {candidateAttributes.bio ? (
            <>
              <div className={classes.profileSection}>
                <div className={classes.justTitleContainer}>
                  <Typography
                    variant="h5"
                    className={classes.firstInputFieldSection}
                  >
                    Bio
                  </Typography>
                  <Divider className={classes.sectionDivider} flexItem />
                </div>
              </div>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs className={classes.bioContainer}>
                  {candidateAttributes ? (
                    <ReactMarkdown
                      className={`${classes.bioMarkdown} ${classes.reactMarkdownContainer}`}
                    >
                      {candidateAttributes.bio}
                    </ReactMarkdown>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
          {candidateAttributes.skills ? (
            <>
              <div className={classes.profileSection}>
                <div className={classes.justTitleContainer}>
                  <Typography
                    variant="h5"
                    className={classes.firstInputFieldSection}
                  >
                    Skills
                  </Typography>
                  <Divider className={classes.sectionDivider} flexItem />
                </div>
              </div>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs>
                  <div className={classes.skillSectionTitle}>
                    <img
                      src={getStaticResourceUrl("/verified-tick.svg")}
                      alt="✓"
                      className={classes.reczeeVerifiedIcon}
                    />
                    <b className={classes.skillSectionText}>
                      Reczee verified skills
                    </b>{" "}
                    <u
                      className={classes.howThisWorks}
                      onClick={() => {
                        setHowItWorksModalOpen(true);
                      }}
                    >
                      How it works?
                    </u>
                  </div>
                  <SkillsHowItWorksModal
                    howItWorksModalOpen={howItWorksModalOpen}
                    handleHowItWorksModalClose={() => {
                      setHowItWorksModalOpen(false);
                    }}
                  />
                  <div className={classes.skillContainer}>
                    {verifiedSkills.length > 0
                      ? verifiedSkills.map((skill, index) => {
                          return skill.proficiency > 1 &&
                            skill.last_passed_certification_slug ? (
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/talenthub/certificate/${skill.last_passed_certification_slug}/verify`}
                              target="_blank"
                            >
                              <SkillBadge
                                hoverable={true}
                                key={index}
                                name={skill.name}
                                proficiency={skill.proficiency}
                              />
                            </Link>
                          ) : (
                            <SkillBadge
                              key={index}
                              name={skill.name}
                              proficiency={skill.proficiency}
                            />
                          );
                        })
                      : "No verified skills!"}
                  </div>
                  <div className={classes.skillSectionTitle}>
                    <img
                      src={getStaticResourceUrl("/unverified-tick.svg")}
                      alt="✓"
                      className={classes.reczeeVerifiedIcon}
                    />
                    <b className={classes.skillSectionText}>Other skills</b>
                  </div>
                  <div className={classes.skillContainer}>
                    {unverifiedSkills.length > 0
                      ? unverifiedSkills.map((skill, index) => {
                          return (
                            <SkillBadge
                              key={index}
                              name={skill.name}
                              proficiency={0}
                            />
                          );
                        })
                      : "Not enough data!"}
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
          {candidateAttributes.experiences &&
          candidateAttributes.experiences.length > 0 ? (
            <>
              <div className={classes.profileSection}>
                <div className={classes.justTitleContainer}>
                  <Typography
                    variant="h5"
                    className={classes.firstInputFieldSection}
                  >
                    Experiences
                  </Typography>
                  <Divider className={classes.sectionDivider} flexItem />
                </div>
              </div>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs>
                  {candidateAttributes.experiences.map(
                    (experience, experienceIndex) => {
                      if (!experience.company) return null;
                      return (
                        <div
                          key={experienceIndex}
                          className={classes.projectRow}
                        >
                          <Typography
                            variant="h6"
                            className={classes.projectTitle}
                          >
                            {experience.designation}, {experience.company}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className={classes.companyDetails}
                          >
                            {getMonthLabel(experience.start)} -{" "}
                            {getMonthLabel(experience.end)} •{" "}
                            {experienceDifference(
                              experience.start,
                              experience.end
                            )}
                          </Typography>
                          <ReactMarkdown
                            className={classes.reactMarkdownContainer}
                          >
                            {experience.description}
                          </ReactMarkdown>
                        </div>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
          {candidateAttributes.projects &&
          candidateAttributes.projects.length > 0 ? (
            <>
              <div className={classes.profileSection}>
                <div className={classes.justTitleContainer}>
                  <Typography
                    variant="h5"
                    className={classes.firstInputFieldSection}
                  >
                    Projects
                  </Typography>
                  <Divider className={classes.sectionDivider} flexItem />
                </div>
              </div>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs>
                  {candidateAttributes.projects.map((project, projectIndex) => {
                    if (project.title === "") return null;
                    return (
                      <div key={projectIndex} className={classes.projectRow}>
                        <div className={classes.projectTitleContainer}>
                          <Typography
                            variant="h6"
                            className={classes.projectTitle}
                          >
                            {project.title}
                          </Typography>
                          {project.source_code_link && (
                            <a
                              className={classes.projectLink}
                              href={project.source_code_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {project.source_code_link.indexOf("github.com/") >
                              -1 ? (
                                <GitHubIcon
                                  className={`${classes.projectLinkActionIcon} ${classes.githubLinkActionIcon}`}
                                />
                              ) : (
                                <CodeIcon
                                  className={classes.projectLinkActionIcon}
                                />
                              )}
                            </a>
                          )}
                          {project.website_link && (
                            <a
                              className={classes.projectLink}
                              href={project.website_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <PublicIcon
                                className={classes.projectLinkActionIcon}
                              />
                            </a>
                          )}
                        </div>
                        <ReactMarkdown
                          className={classes.reactMarkdownContainer}
                        >
                          {project.description}
                        </ReactMarkdown>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
          {candidateAttributes.educations &&
          candidateAttributes.educations.length > 0 ? (
            <>
              <div className={classes.profileSection}>
                <div className={classes.justTitleContainer}>
                  <Typography
                    variant="h5"
                    className={classes.firstInputFieldSection}
                  >
                    Education
                  </Typography>
                  <Divider className={classes.sectionDivider} flexItem />
                </div>
              </div>
              <Grid container alignItems="center" spacing={3}>
                <Grid item xs>
                  {candidateAttributes.educations.map(
                    (education, educationIndex) => {
                      if (!education.institute) return null;
                      return (
                        <div
                          key={educationIndex}
                          className={classes.projectRow}
                        >
                          <Typography
                            variant="h6"
                            className={classes.projectTitle}
                          >
                            {education.degree}, {education.major}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            className={classes.companyDetails}
                          >
                            {education.start} - {education.end} •{" "}
                            {education.institute}
                          </Typography>
                        </div>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
}
